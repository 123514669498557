import React from 'react';
import { withRouter } from '../../hoc/withRouter';
import { format } from "date-fns";
import Share from '../share';
import { RenderBlock } from '../render';
import './styles.scss';

const RenderAuthor = (props) => {
    if (!props._embedded) return null;
    if (!props._embedded.author) return null;

    const author = props._embedded.author[0];

    return (
        <>
            <img src={author.avatar_urls['96']} alt={author.name} />
            <span className="post__meta-author">Av {author.name}</span>
        </>
    );
};

const RenderImage = (props) => {

    if (!props.image) return null;

    if (!props.image.src) return null;

    if (props.image.src === 'undefined') return null;

    if (props.image.src === 'null') return null;

    if (props.image.src.indexOf('/null') > -1) return null;

    return (
        <div className="post__image single-blog-image">
            <img src={props.image.src} alt={props.image.alt} />
        </div>
    );
};

const RenderTags = (props) => {
    if (!props._embedded) return null;
    if (!props._embedded['wp:term']) return null;

    const tags = [];
    for (let taxonomies of props._embedded['wp:term']) {
        for (let tag of taxonomies) {
            if (tag.taxonomy === 'post_tag') {
                tags.push(<a key={tag.id} href={`/blog/tag/${tag.slug}`} title={tag.name}>{tag.name}</a>);
            }
        }
    }

    if (tags.length === 0) return null;

    return (
        <div className="post__tags">
            <i className="post__tags-icon"></i>
            <div className="post__tags-list">
                {tags}
            </div>
        </div> 
    );
};


const Post = (props) => {  
    return (
        <section className="post">
            {props.toolbar}
            <div className="post__wrapper content-padding">
             
                <RenderImage {...props} />

                <div className="post__content single-blog-content">
                    <h1>{props.heading}</h1>
                    {props.preambleBlock && props.preambleBlock.map((block, i) => <RenderBlock key={i} {...block} />)}
                    {props.date !== '0001-01-01T00:00:00' &&
                        <div className="post__meta">
                            <RenderAuthor {...props} />
                            <span className="post__meta-calendar">{format(new Date(props.date),'yyyy-MM-dd')}</span>
                        </div>
                    }
                    
                    {props.postBlocks &&
                        <div className="post__content-content">
                            {props.postBlocks.map((block, i) => <RenderBlock key={i} {...block} />)}
                        </div>
                    }
                    
                    <RenderTags {...props} />

                    {props.share &&
                        <Share {...props} />
                    }
                    
                </div>
            </div>
        </section>
    );
};

export default withRouter(Post);