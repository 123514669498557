export const mapArticleToSearchResult = (articleList, logo) => {
    return articleList && articleList.map(article => {        
        return {
            GTIN: article.Gtin,
            ArtikelBenanmning: article.Artikelbenamning,
            ArtikelKategori: article.Artikelkategori,
            Produktbild: article.Produktbild,
            ProduktbildMedium: article.ProduktbildMedium,
            TillverkarensNr: article.Tillverkarensartnr,
            Varumarke: article.Varumarke,
            UppgiftslamnareBild: logo,
            VGIDENT: article.VGIDENT
        };
    });
};

export const getFacets = (facets) => {
    const filteredFacets = facets && facets.filter(f => f.Key === 'varugruppbenamning');

    if (filteredFacets && filteredFacets.length > 0) {
        const facetArr = [];
        filteredFacets.forEach(vb => {
            vb.FacetValues.forEach(item => item.Label !== '' && facetArr.push(item));
        });
        
        return facetArr.sort((a, b) => a.LabelCount.localeCompare(b.LabelCount));;
    }

    return [];
};