import React from 'react';
import './styles.scss';

const Newsbanner = (props) => {

    return (
        <section className="news-banner">
          <a href={props.url}
             title={props.text}>
                 <span className="news-banner__label">{props.byline ? props.byline : 'Nyhet'}</span> {props.text}
          </a>
        </section>
    );
} 

export default Newsbanner;