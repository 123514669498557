import React from 'react';

const Ingredienser = (product) => {
    if (!product.Ingrediensforteckning) return null;

    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Ingredienser</h2>
                <p>{product.Ingrediensforteckning}</p>
            </div>
        </section>
    );
};

export default Ingredienser;