import React from 'react';
import dabasLogoBW from '../../images/dabas-logo-black-text.png';
import './footer.scss';

const Footer = (props) => {
    return (
        <footer className="newsroom-footer">
            <img src={dabasLogoBW} alt="Dabas logo" />
            <p>
                Ett newsroom från <a href="/" title="Dabas - Alla livsmedel på marknaden">Dabas</a>
            </p>
        </footer>
    );
};

export default Footer;