import React from 'react';
const images = require.context('../../images/markningar', true);
//<img src={images('./UHM.jpg')} alt="UHM" />

const MarkningIkoner = (product ) => {
    if (product.Markningar || (product.MSRKritierier && product.MSRKritierier.length > 0)) {
        return (
            <section className="cert-icons">
                {product.Markningar && product.Markningar.map((markning, i) => {
                    let src = null;
                    try {
                        src = images(`./${markning.Typkod}.jpg`);
                    } catch {
                        src = null;
                    }
                    
                    if (!src) return null;

                    return (
                        <div className="cert-icons__container" key={`markning${i}`}>
                            <div className="cert-icons__icon">
                                <img src={src} alt={markning.Typ} className={markning.Typkod.toLowerCase()} />
                            </div>
                            <div className="cert-icons__popup">
                                {markning.Typ}
                            </div>
                        </div>
                    );
                })}
                
                {product.MSRKritierier.length > 0 &&
                    <div className="cert-icons__container">
                        <div className="cert-icons__icon">
                            <img src={images('./UHM.jpg')} alt="UHM" />
                        </div>
                        <div className="cert-icons__popup">
                            UHM
                        </div>
                    </div>
                }
            </section>
        );
    }

    return null;  
};

export default MarkningIkoner