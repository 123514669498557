import React from 'react';
import './styles.scss';
function Image(props) {
    return (
        <div className="image">
            <div className="image-right">
                <div className="image__wrapper">
                    <img className="image__image" src={props.src} alt={props.alt} />
                    <div className="image__shadow"></div>
                </div>
            </div>
        </div>
    );
};

export default Image;

/*
<div className="marketers-info-item-image image-right image">
            <div className="dot-shadow shadow-bottom-right image__shadow">
                <div class="image__shadow-inner">
                    <img src={props.src} alt={props.alt} />
                </div>
            </div>
        </div>*/

        /*

         <div className="image__front">
                <div class="image__shadow-inner">Text2</div>
            </div>
            <div className="image__shadow">
                <div class="image__shadow-inner">Text</div>
            </div>*/