import React, { Component } from 'react';
import qs from 'query-string';
import classNames from 'classnames';
import { withRouter } from '../../hoc/withRouter';
import ProductCards from '../../components/productcards';
import Breadcrumb from '../../components/breadcrumb';
import CompareBar from '../../components/comparebar';
import Spinner from '../../components/spinner';
import Auth from '../../hoc/auth';
import { DEFAULT_PAGINATION_SIZE, DASHBOARD_URL, URL } from '../../config';
import './changed.scss';

class Changed extends Component {
    
    state = {
        isComparable: false,
        trackings: null,
        results: {
            TotalRecords: 0,
            SearchResults: []
        },
        page: 1,
        isLoading: true
    };

    productCardComponents = [];

    async fetchSearchResults(gtins) {

        const query = qs.parse(this.props.location.search);

        const queryString = qs.stringify({
            FromRange: query.page ? (query.page-1) * DEFAULT_PAGINATION_SIZE : 0,
            SearchText: gtins.join(' ')
        });

        const resResult = await fetch(`/api/v1/search${queryString.length > 0 ? '?' + queryString : ''}`, {
            method: 'GET'
        });

        //Emit search results
        return await resResult.json();
    }

    async getTracking(trackId) {
        try {
            const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/GetTracking/${trackId}`, {
                method: 'GET',
                credentials: 'include'
            });
            
            //Emit search results
            return await resResult.json();
        } catch(error) {
            console.log(error);
        }
    }


    async componentDidMount() {

        const { params } = this.props;

        if (params && params.trackid) {
            const trackings = await this.getTracking(params.trackid);
            if (trackings && trackings.Notification && trackings.Notification.NewlyAddedGTINValues) {
                
                const search = await this.fetchSearchResults(trackings.Notification.NewlyAddedGTINValues);
                
                this.setState({
                    trackings,
                    search,
                    isLoading: false
                });

            } else {
                this.setState({
                    isLoading: false
                });    
            }
        }
        
    }
    
    handleCompareChange() {
        let comparableProducts = 0;
        let isComparable = false
        this.productCardComponents.forEach((product) => {
            if (product.state.isChecked) {
                comparableProducts++;
                
            }
        });

        if (comparableProducts > 1) {
            isComparable = true;
        }

        if (isComparable !== this.state.isComparable) {
            this.setState({
                isComparable
            });
        }
    }

    addProductcardComponent(component) {
        this.productCardComponents.push(component);
    }

    render() {
        return (
            <section className={classNames({
                'changed': true,
                'changed--is-loading': this.state.isLoading
            })}>
                <Breadcrumb url="/myfeed" link_text="Mina bevakningar" />
                {this.state.trackings &&
                    <div className="changed__wrapper">
                        <div className="changed__wrapper-inner">
                            <div className="changed__heading">
                                <h1>{this.state.trackings.SearchTracker.TrackingName}</h1>
                                <div className="changed__heading-controls">
                                    <a className="changed__edit-search edit-icon" href={`/search?${this.state.trackings.SearchTracker.TrackingQuery}&trackid=${this.state.trackings.SearchTracker.TrackerIdentifier}`} title="Redigera"><span>Ändra bevakning</span></a>
                                    <a className="changed__view-search edit-icon" href={`/search?${this.state.trackings.SearchTracker.TrackingQuery}&trackid=${this.state.trackings.SearchTracker.TrackerIdentifier}`} title="Redigera"><span>Visa sökning</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <form method="get" action="/search/compare">
                    <ProductCards 
                        compare={true}
                        useSlider={false}
                        search={this.state.search}
                        isGridView={true} 
                        isLoading={this.state.isLoading}
                        isLoadingPage={this.state.isLoading}
                        addProductcardComponent={(component) => this.addProductcardComponent(component)} 
                        handleCompareChange={() => this.handleCompareChange()} 
                        isNew={true}
                        modifiers={{
                            'product-cards--search-page': true,
                            'product-cards--gray-background': true,
                            'product-cards--is-loading': this.state.isLoading,
                            'product-cards--is-firstfetch': this.state.isLoading

                        }} />
                    <CompareBar isComparable={this.state.isComparable} search={this.state.search} />
                </form>
                <Spinner />
            </section>
        );
    }
};

export default withRouter(Auth(Changed, `${URL}/myfeed`));