import React from 'react';

const Nettoinnehall = ({Property, ...props}) => {
   
    if ((props.Nettoinnehall && props.Nettoinnehall.length > 0) || props.T4305_BeskrivningNettoinnehall) {
        return (
            <div className="product-sheet-box__tablecontent">
                <h3 className="product-sheet-box__tableheading">Nettoinnehåll</h3>
                <table className="product-sheet__table product-sheet__table--striped">
                    <tbody>
                        {props.Nettoinnehall.map((netto, i) => {
                            return (
                                <React.Fragment key={`Nettoinnehall${i}`}>
                                    <Property prop={netto.Mängd && `${netto.Mängd_Formatted} ${netto.Typ}`} text="Nettoinnehåll värde:" />
                                </React.Fragment>
                            );
                        })}
                        <Property prop={props.T4305_BeskrivningNettoinnehall} text="Beskrivning av nettoinnehåll:" />
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
};

export default Nettoinnehall;