import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import classNames from 'classnames';
import './multipleselect.scss';

class MultipleSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.getValueFromQuery()
        };
    }

    getValueFromQuery() {
        const query = qs.parse(this.props.location.search); 
    
        if (query && typeof query[this.props.name] === 'string') {
            return [query[this.props.name]];
        } else {
            return query && query[this.props.name] ? query[this.props.name] : [];
        }
    }

    isValSelected(val) {
        let checked = false;
        for (let i = 0; i < this.state.value.length; i++) {
            if (val === this.state.value[i]) {
                checked = true;
            }
        }
        return checked;
    }

    handleChange(e) {
        e.persist();
        if (e.target.checked) {
            this.setState({
                value: [...this.state.value, e.target.value]
            }, () => this.props.onChange(e));
        } else {
            const value= this.state.value.filter(o => o !== e.target.value);
            this.setState({
                value
            }, () => this.props.onChange(e));
        }
        

        //this.props.onChange(e);
    }

    componentDidMount() {
        this.props.addControlledElements && this.props.addControlledElements(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setState({
                value: this.getValueFromQuery()
            });
        }
    }


    render() {
        const props = this.props;

        return (
            <div className={classNames({
                'multipleselect': true,
                ...props.modifiers
            })}>
                {props.filters && props.filters.FacetValues && props.filters.FacetValues.map((filter, i) => {
                    return (
                        <div key={`${props.name}${i}`} className="multipleselect__checkbox">
                            <input name={props.name} 
                                   type="checkbox" 
                                   onChange={(e) => this.handleChange(e)} 
                                   value={filter.LabelValue} 
                                   disabled={this.props.disabled}
                                   className={classNames({
                                        'multipleselect__checkboxctrl': true,
                                        'multipleselect__checkboxctrl--checked': this.isValSelected(filter.LabelValue)
                                   })} 
                                   checked={this.isValSelected(filter.LabelValue)} 
                                   id={`${props.name}${i}`} /><label htmlFor={`${props.name}${i}`}>{filter.Label}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
};

export default withRouter(MultipleSelect);