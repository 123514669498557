import React from 'react';

const Markning = (product) => {
    if (product.OvrigRegulatoriskMarkning || (product.Markningar && product.Markningar.length > 0) || (product.MSRKritierier && product.MSRKritierier.length > 0 )) {
        
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Märkning</h2>
                    {product.Markningar && product.Markningar.length > 0 &&
                        <>
                        <table className="product-sheet__table">
                            <tbody>
                                <tr>
                                    <th>Typ av märkning:</th>
                                    <td>{product.Markningar.map((markning, i) => <React.Fragment key={`markningar${i}`}>{markning.Typ}<br /></React.Fragment>)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    }
                    {product.OvrigRegulatoriskMarkning &&
                        <>
                            <table className="product-sheet__table product-sheet__table--align-right">
                                <tbody>
                                    <tr>
                                        <th>Övrig regulatorisk märkningsinformation:</th>
                                        <td>{product.OvrigRegulatoriskMarkning}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                    
                    {product.MSRKritierier && product.MSRKritierier.length > 0 &&
                        <>
                            <h3 className="product-sheet-box__subheading">Upphandlingsmyndighetens hållbarhetskriterier:</h3>
                            <ul className="product-sheet-box__list">
                                {product.MSRKritierier.map((kriterie, i) => <li key={`kriterie${i}`}>{kriterie.Beskrivning}</li>)}
                            </ul>
                            <div className="product-sheet-box__footer">
                                <a href={`/productsheet/uhm/${product.GTIN}`}>Visa leverantörsförsäkran</a>
                            </div>
                        </>
                    }
                </div>
            </section>
        );
    }
    
    return null;
};

export default Markning;