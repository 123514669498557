import React from 'react';

const Tillagningsinformation = (product) => {

    if ((product.Tillagningsinformation &&  product.Tillagningsinformation.length > 0) ||
        (product.Serveringsforslag && product.Serveringsforslag.length > 0 && product.Serveringsforslag[0].Forslag) ||
        (product.Tillagningsmetoder && product.Tillagningsmetoder.length > 0)) {
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Tillagning</h2>
                    <table className="product-sheet__table product-sheet__table--align-right">
                        <tbody>
                            { 
                                product.Tillagningsinformation && 
                                product.Tillagningsinformation.length > 0 &&
                                product.Tillagningsinformation.map((tillagning, i) => {
                                    return (
                                        <React.Fragment key={`tillagning${i}`}>
                                            <tr>
                                                <th>Tillagningssätt:</th>
                                                <td>{tillagning.Satt}</td>                                            
                                            </tr>
                                            <tr>
                                                <th>Anvisningar:</th>
                                                <td>{tillagning.Anvisning}</td>
                                            </tr>
                                            {tillagning.OvreOptimalFortaringstemperatur > 0 && 
                                                <tr>
                                                    <th>Övre optimal förtäringstemperatur:</th>
                                                    <td>{tillagning.OvreOptimalFortaringstemperatur_Formatted}</td>
                                                </tr>
                                            }
                                            {tillagning.NedreOptimalFortäringstemperatur > 0 && 
                                                <tr>
                                                    <th>Nedre optimal förtäringstemperatur:</th>
                                                    <td>{tillagning.NedreOptimalFortäringstemperatur_Formatted}</td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    );
                            })}
                
                            <Serveringsforslag {...product} />

                            <Tillagningsmetoder {...product} />                        
                        </tbody>
                    </table>
                    <RecipeLinks {...product} />
                </div>
            </section>
        );
    } 

    return null;
    
};


const Serveringsforslag = (product) => {
    if (product.Serveringsforslag && product.Serveringsforslag.length > 0) {
        return (
            <>
                {
                    product.Serveringsforslag.map((servering, i) => 
                        servering.Forslag && <tr key={`servering${i}`}><th>Serveringsförslag:</th><td>{servering.Forslag}</td></tr>)
                }
            </>
        );
    }

    return null;
};

const Tillagningsmetoder = (product) => {
    if (product.Tillagningsmetoder && product.Tillagningsmetoder.length > 0) {
        return (
            <tr>
                <th>Tillagningsmetod:</th>
                <td>
                    <ul className="product-sheet__table-list">
                        {product.Tillagningsmetoder.map((tillagning, i) => <li key={`tillagningsmetod${i}`}>{tillagning.Tillagningsmetod}</li>)}
                    </ul>
                </td>
            </tr>
        );
    }
        
    return null;
};

const RecipeLinks = product => {
    
    if (!product.Receptlinks || product.Receptlinks.length === 0) return null;
    
    return (
        <>
            <h3 className="product-sheet-box__subheading">Receptlänk{product.Receptlinks.length > 1 && 'ar'}:</h3>
            <ul className="product-sheet-box__list">
                {product.Receptlinks.map((recept, i) => {
                    return (
                        <li key={`recipelinks${i}`}>
                            <a href={recept.ReceptURL}>{recept.Receptnamn}</a>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default Tillagningsinformation;