import React from 'react';
import './styles.scss';

const BusinessList = (props) => {

    if (!props.list) return null;

    return (
        <section className="business-list">
             {Object.keys(props.list).sort().map((group, x) => {
                return (
                    <div key={`group${x}`} className="business-list__wrapper" id={group}>
                        <div className="business-list__content content-padding-no-bottom">
                            <h2>{group}</h2>
                            <div className="businesses">
                                {props.list[group].map((item, i) => {

                                    return (
                                        <div key={`business${i}`} className="branch-news-item">
                                            <div className="business-link">
                                                {item.NEWSROOMAKTIV &&
                                                    <a href={`/${item.MediaPath}`} title={item.CompanyName}>{item.CompanyName}</a>
                                                }

                                                {!item.NEWSROOMAKTIV &&
                                                    <a href={`/search?uppgiftslamnare=${encodeURIComponent(item.CompanyName)}`} title={item.CompanyName}>{item.CompanyName}</a>
                                                }
                                                
                                                <div className="branch-news-date">{item.City}</div>
                                            </div>
                                            <div className="business-articles-link">
                                                {item.NEWSROOMAKTIV &&
                                                    <a href={`/${item.MediaPath}`} title={`${item.NumberOfArticles} artiklar fån ${item.CompanyName}`}>{item.NumberOfArticles} {item.NumberOfArticles === 0 && <span>publika artiklar</span>}{item.NumberOfArticles === 1 && <span>artiklel</span>}{item.NumberOfArticles > 1 && <span>artiklar</span>}</a>
                                                }

                                                {!item.NEWSROOMAKTIV &&
                                                    <a href={`/search?uppgiftslamnare=${encodeURIComponent(item.CompanyName)}`} title={`${item.NumberOfArticles} artiklar fån ${item.CompanyName}`}>{item.NumberOfArticles} {item.NumberOfArticles === 0 && <span>publika artiklar</span>}{item.NumberOfArticles === 1 && <span>artiklel</span>}{item.NumberOfArticles > 1 && <span>artiklar</span>}</a>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );        
            })};
        </section>
    );
};

export default BusinessList;