import React, { Component } from 'react';
import classNames from 'classnames';
import Spinner from '../../components/spinner';
class Download extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isOpen: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        //Outside click
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    }

    async handleDownloadPdf(type = '') {
        this.setState({
            isLoading: true,
            isOpen: false
        });

        try {

            const version = type === 'short' ? '?short=true' : '';
            const res = await fetch(`${this.props.pdfUrl}${version}`);
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const { GTIN } = this.props;
            const blob = await res.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${GTIN}.pdf`;
            link.click();
            this.setState({
                isLoading: false
            });
            //return res.json();
        } catch (err) {
            console.log(err);
            this.setState({
                isLoading: false
            });
            return {};
        }
    }

    async toggleSelector() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div className="download" ref={this.setWrapperRef}>
                <button className={classNames({
                    'download__btn': true,
                    'download__btn--is-loading': this.state.isLoading,
                    ...this.props.modifiers
                })} 
                onClick={() => this.toggleSelector()}
                type="button">
                    <span className="download__btn-text">Ladda ned PDF</span>
                    <Spinner />                    
                </button>
                <div className={classNames({
                    'download__selector': true,
                    'download__selector--is-open': this.state.isOpen
                })}>
                    <button className="download__selector-btn" onClick={() => this.handleDownloadPdf()} type="button">Full version</button>
                    <button className="download__selector-btn" onClick={() => this.handleDownloadPdf('short')} type="button">Kort version</button>      
                </div>
            </div>
        );   
    }
};

export default Download;