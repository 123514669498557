import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';

class SelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.getValueFromQuery()
        };
    }

    getValueFromQuery() {
        const query = qs.parse(this.props.location.search); 
        
        if (this.props.multiple) {
            if (query && typeof query[this.props.name] === 'string') {
                return [query[this.props.name]];
            } else {
                return query && query[this.props.name] ? query[this.props.name] : [];
            }
            
        } else {
            return query && query[this.props.name] ? query[this.props.name] : '';
        }
    }

    componentDidMount() {
        this.props.addControlledElements && this.props.addControlledElements(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setState({
                value: this.getValueFromQuery()
            });
        }
    }

    handleChange(e) {
        if (this.props.multiple) {
            this.setState({
                value: [...e.target.selectedOptions].map(o => o.value)
            });
        } else {
            this.setState({
                value: e.target.value
            });
        }

        this.props.onChange(e);
    }

    render() {
        const props = this.props;

        return (
            <select onChange={(e) => this.handleChange(e)} 
                    name={props.name} 
                    className={props.className}
                    id={props.id}
                    disabled={this.props.disabled}
                    value={this.state.value}
                    multiple={props.multiple}>
                <option value="">{props.defaultText ? props.defaultText : 'Välj'}</option>
                {props.filters && props.filters.FacetValues && props.filters.FacetValues.map((filter, i) => {
                    return <option value={filter.LabelValue} key={`${props.name}${i}`}>{filter.Label}</option>
                })}
            </select>
        );
    }

}

export default withRouter(SelectBox);