import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import classNames from 'classnames';
import Footer from '../../components/footer';
import Posts from '../../components/posts';
import { WP_API_URL, EXCLUDE_TAG_ID, URL } from '../../config';

class Blog extends Component {
    constructor(props) {
        super(props);
        
        const { location } = this.props;
        const query = qs.parse(location.search);

        this.state = {
            page: query.page ? parseInt(query.page) : 1,
            total_pages: parseInt(props.initialData.total_pages),
            posts: [...props.initialData.posts],
            isLoading: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const query = qs.parse(location.search);

        let page = query.page ? parseInt(query.page) : 1;
        if (page === this.state.page || this.state.page >= this.state.total_pages) return;

        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            });

            Blog.requestInitialDataClient(null, null, query)
                .then(res => {
                    this.setState({
                        page,
                        posts: page > this.state.page ? [...this.state.posts, ...res.posts] : [...res.posts, ...this.state.posts],
                        isLoading: false
                    });
                });
        }        
    }

    static async requestsTags(route) {
        if (!route) return;

        if (!route.params || !route.params.tag) return;

        const res = await fetch(`${URL}/api/v1/blog/tags?slug=${route.params.tag}`);
        if(!res.ok) return;

        const tag = await res.json();
        if (tag && tag.length > 0) {
            return tag;
        } else {
            const err404 = new Error('Not found 1');
            err404.status = 404;
            throw err404;
        }
    }

    static async dataFetcher(route, req, query = {}) {
        const tag = await Blog.requestsTags(route);
        
        if (tag && tag.length > 0) {
            query.tags = tag[0].id;
        }
        //${WP_API_URL}
        const res = await fetch(`${URL}/api/v1/blog/posts?_embed&per_page=12&${qs.stringify(query)}`); 
        //console.log(`${WP_API_URL}posts?_embed&per_page=12&${qs.stringify(query)}&tags_exclude=${EXCLUDE_TAG_ID}`);
        if(!res.ok) return { total_pages: null, posts: [] };
        
        const total_pages = res.headers.get('x-wp-totalpages');
        const data = await res.json();
        
        return { 
            total_pages: total_pages,
            posts: data
        };
    }

    static async requestInitialDataClient(route, req, query) {
        return await Blog.dataFetcher(route, req, query);
    }

    static async requestInitialData(route, req, res, cache) {
        let data = cache && cache.get(req.originalUrl);
        
        if (!data) {
            data = await Blog.dataFetcher(route, req, req.query);
            if (cache) {
                cache.set(req.originalUrl, data);
            }
        }

        return data;
    }

    render() {
        const { location } = this.props;

        return (
            <>
                <section className={classNames({
                    'blog-items': true,
                    'blog-items--is-loading': this.state.isLoading
                })}>
                    <div className="blog-items__wrapper">
                        <h1>Blogg</h1>
                        <Posts posts={this.state.posts} />
                        {!(this.state.page >= this.state.total_pages) &&
                            <Link className="blog-items__load-more"
                                to={{
                                    pathname: location.pathname,
                                    search: `?page=${parseInt(this.state.page) + 1}`
                                }}>Visa fler</Link>
                        }
                    </div>
                </section>
                <Footer {...this.props} />
            </>
        );
    }
    
};
export default withRouter(Blog);