import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { read, utils, writeFileXLSX } from 'xlsx';
import ExcelTable from './exceltable';

class ExcelExport extends Component {

    generateDocument() {
        let document = ReactDOMServer.renderToStaticMarkup(<ExcelTable {...this.props} />);
        var wb = read(document, { type: "string" });
        var ws = wb.Sheets[wb.SheetNames[0]];
        utils.book_append_sheet(wb, ws, 'Export');

        const date = new Date();
        const dateText = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

        writeFileXLSX(wb, 'Artiklar_' + dateText + '.xlsx');
    }

    /*handleClick = () => {
        import('./generate-doc')
          .then(({ generateDocument }) => {
            // Use moduleA
            console.log(generateDocument);
          })
          .catch(err => {
            // Handle failure
          });
    };*/

    render() {
        return (
            <>
                <button onClick={() => this.generateDocument()}
                    type="button"
                    className="compare-bar__link compare-bar__link--outline button-outline button-small"
                    title="Exportera till Excel">Exportera till Excel</button>
            </>
        );
    }
}

export default ExcelExport;