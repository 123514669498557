import React from 'react';
import './header.scss';

const Header = (props) => {
    if (props.isEditing) return null;

    return (
        <header className="single-business">
            <div className="single-business__content">
                <a href="/" title="Dabas - Alla livsmedel på marknaden">Tillbaka till Dabas startsida</a>
            </div>
        </header>
    );
}

export default Header;