import React, { Component } from 'react';
import classNames from 'classnames';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            value: '',
            isDirty: false,
            isValid: !props.required
        }
    }

    clear() {
        this.setState({
            value: '',
            isDirty: false,
            isValid: !this.props.required
        });
    }

    componentDidMount() {
        this.props.addControl(this);
    }

    validate() {

        //Validate required
        if (this.props.required) {
            if (this.state.value === '') {
                this.setState({
                    isDirty: true,
                    isValid: false
                });
                return false;
            } else {
                this.setState({
                    isDirty: true,
                    isValid: true
                });
                return true;
            }
        }
    }

    render() {
        return (
            <>
                <select id={this.props.id}
                    name={this.props.name} 
                    value={this.state.value} 
                    onChange={(e) => {
                        this.setState({
                        value: e.target.value
                    })}}
                    onBlur={() => this.validate()}
                    className={classNames(this.props.className, {
                        'form__selct': true,
                        'form__select--is-error': this.state.isDirty && !this.state.isValid,
                    })}>
                        {this.props.children}
                </select>
                {this.props.errorMsg && this.state.isDirty && !this.state.isValid &&
                    <span className="form__input-errormsg">{this.props.errorMsg}</span>
                }
            </>
        );
    }
};

export default Textarea;