import React, { Component} from 'react';
import classNames from 'classnames';
import imageMissing from '../../images/image-missing.png';
import './productcard.scss';

const Wrapper = props => {
    if (props.isGridView !== false) {
        return (
            <div className="product-card__content-bottom">
                {props.children}
            </div>
        );
    }

    return props.children;
};

class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false
        }
    }

    componentDidMount() {
        if (this.props.addProductcardComponent) {
            this.props.addProductcardComponent(this);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({
                isChecked: false
            });
        }

        if (prevState.isChecked !== this.state.isChecked) {
            this.props.handleCompareChange();
        }
    }

    handleChange() {
        this.setState({
            isChecked: !this.state.isChecked
        });
    }

    aligner() {

    }

    insertAt(str, sub, pos) {
        return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
    }
    
    render() {
        const props = this.props;
        
        const heading = props.ArtikelBenanmning && props.ArtikelBenanmning !== '' ?  props.ArtikelBenanmning : props.ArtikelKategori;
        const subHeading = props.ArtikelBenanmning && props.ArtikelBenanmning !== '' ?  props.ArtikelKategori : '';

        return (
            <div className="product-card">
                {props.isNew &&
                    <div className="product-card__new">Ny</div>
                }
                {props.compare &&
                    <div className="product-card__checkbox">
                        <input type="checkbox"
                               name="gtin"
                               id={props.id} 
                               value={props.GTIN}
                               checked={this.state.isChecked}
                               onChange={() => this.handleChange()} />
    
                        <label htmlFor={props.id}>
                            <span className="hide">Välj {props.ArtikelKategori}</span>
                        </label>
                    </div>
                }
                <div className="product-card__placeholder">
                </div>
                {props.GTIN &&
                    <div className="product-card__container">
                        <a className="product-card__product-link" href={`/productsheet/${props.GTIN}`} target="_blank" title={props.ArtikelKategori}>
                            {subHeading &&
                                <h3 className={classNames({
                                    'product-card__subheading': true,
                                    'product-card__subheading--no-margin': !props.compare
                                })}>{subHeading}</h3>
                            }
                            <h3>{heading}</h3>
                            <span className="product-card__name">{props.ArtikelBenanmning}</span>
                            <span className="product-card__manufacturer">{props.Varumarke}</span>

                            <Wrapper isGridView={props.isGridView}>
                                <div className={classNames({
                                    'product-card__product-image': true,
                                    'product-card__product-image--is-missing': props.Produktbild === '/files/images/image-missing.png'
                                })}>
                                    <img src={props.Produktbild === '/files/images/image-missing.png' ? imageMissing : props.ProduktbildMedium } 
                                        alt={props.ArtikelKategori} />
                                </div>

                               
                                <div className="product-card__info">
                                    <div>
                                        <div className="product-card__gtin-header">Förp.storl.</div>
                                        <div className="product-card__size">{props.Storlek}</div>
                                    </div>
                                    <div>
                                        <div className="product-card__art-nr-header">ART. NR.</div>
                                        <div className="product-card__art-nr">{props.TillverkarensNr}</div>
                                    </div>
                                    <div>
                                        <div className="product-card__gtin-header">GTIN</div>
                                        <div className="product-card__gtin">{props.GTIN}</div>
                                    </div>
                                </div>
                            
                                {props.UppgiftslamnareBild &&
                                    <div className="product-card__manufacturer-logo" target="_blank" title={props.Varumarke}>
                                        <img src={props.UppgiftslamnareBild} alt={props.Varumarke} />
                                    </div>
                                }  
                            </Wrapper>
                        </a>
                    </div>
                }
            </div>
        );
    }
}; 

export default ProductCard;