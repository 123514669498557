import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Spinner from '../spinner';
import './styles.scss';

const Paginate = props => {
    return (
        <Link className={classNames({
            'paginate': true,
            'paginate--is-loading': props.isLoading,
            'button': true,
            'button-large': true,
            'button-minwidth': true
        })} to={props.to}>
            <Spinner />
            <span className="paginate__load-more-text">+ Visa fler</span>
            <span className="paginate__load-more-text-loading">Laddar fler</span>
        </Link>
    );
}

export default Paginate;