import React from 'react';
import FilterTooltip from '../../components/searchfilter/filtertooltip';

const Allergener = (product) => {
    const Niva = ({ allergen }) => {
        switch(allergen.Nivakod) {
            case 'CONTAINS': 
                return (
                    <div className="allergen__indicator allergen__indicator--contains">
                        {allergen.NivakodText}
                        <FilterTooltip heading="Innehåller">
                            <p>
                                Produkten innehåller det angivna allergenet.
                            </p>
                        </FilterTooltip>
                    </div>);
            case 'MAY_CONTAIN':
                return (
                    <div className="allergen__indicator allergen__indicator--may-contain">
                        {allergen.NivakodText}
                        <FilterTooltip heading="Kan innehålla">
                            <p>
                                Produkten innehåller inte avsiktligt det angivna allergenet, men på grund av att produktionsmedlen delas med andra produkter, eller av andra skäl, kan produkten innehålla allergenet.
                            </p>
                        </FilterTooltip>
                    </div>);
            case 'FREE_FROM':
                return (
                    <div className="allergen__indicator allergen__indicator--freefrom">
                        {allergen.NivakodText}
                        <FilterTooltip heading="Fri från">
                            <p>
                                Produkten är fri från det angivna allergenet.
                            </p>
                        </FilterTooltip>
                    </div>);
            default:
                return <div className="allergen__indicator">{allergen.NivakodText}</div>;
        }
    };

    if ((product.Allergener && product.Allergener.length > 0) || product.Allergenpastaende) {
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Allergener</h2>
                    <table className="product-sheet__table product-sheet__table--striped">
                        <tbody>
                            {product.Allergener && product.Allergener.map((allergen, i) => {
                                return (
                                    <tr key={`allergen${i}`}>
                                        <th>{allergen.Allergen}</th>
                                        <td>
                                            <Niva allergen={allergen} />
                                        </td>
                                    </tr>
                                );
                            })}
        
                            {product.Allergenpastaende &&
                                <tr>
                                    <th>Allergenpåstående:</th>
                                    <td>{product.Allergenpastaende}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    
                    <p>
                        <strong>Observera</strong> Den som lagar/serverar mat till en allergiker måste alltid noga läs igenom ingrediensförteckningen på förpackningen där eventuella allergener ska framgå.
                    </p>
                </div>
            </section>
        );
    }

    return null;
    
};

export default Allergener;