import React from 'react';
import Page from '../../hoc/page';
import RenderBlocks from '../../blocks';
import Signup from '../../components/signup';
import Footer from '../../components/footer';

const OnBoard = (props) => {
    return (
        <div>
            <RenderBlocks blocks={props.initialData.blocks} />
            <Signup />
            <Footer />
        </div>
    );
};

export default Page(OnBoard);