import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import classNames from 'classnames';
import withAutocomplete from '../../hoc/withAutocomplete';
import Spinner from '../spinner';
import Autocompleter from '../autocompleter';
import "./styles.scss";

class SearchBar extends Component {
 
    render() {
        
       const { suggestions } = this.props;

        return (
            <div className={classNames({
                'search-bar': true,
                ...this.props.modifiers
            })}>
                <div className="search-bar__shadow">
                    <label htmlFor="search-dabas" className="hide">Sök efter livsmedel på t.ex. produkt, varumärke, artikelnummer, företag
                        eller GTIN</label>
                    <input
                        type="text" 
                        autoComplete="off" 
                        className="search-bar__q"
                        id="search-dabas"
                        name="SearchText" 
                        onKeyDown={(e) => this.props.handleKeyDown(e)}
                        onChange={(e) => this.props.handleChange(e)}
                        value={suggestions.value}
                        aria-label="Sök efter produkter"
                        placeholder="Sök t.ex. produkt, varumärke, artikelnummer, företag eller GTIN" />
    
                    <button type="submit" className="search-bar__button button">
                        <i className="search-bar__icon"></i>
                        <span className="search-bar__button-text">Sök bland <span>{this.props.totalRecordsInIndex}</span> artiklar</span>
                        <Spinner />
                    </button>
                    <Autocompleter {...this.props} />
                </div>
            </div>
        );
    }
    
}

export default withRouter(withAutocomplete(SearchBar));