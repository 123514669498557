import React, { Component } from 'react';
import classNames from 'classnames';
import { DASHBOARD_URL } from '../../config';
import './styles.scss';

class DropDownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
          //Outside click
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
          this.setState({ isOpen: false });
        }
      }

    handleClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {

        const props = this.props;

        return (
            <div ref={this.setWrapperRef} className={classNames({
                'drop-down-menu': true,
                'drop-down-menu--is-open': this.state.isOpen,
            })}>
                <button className="drop-down-menu__button" type="button" onClick={() => this.handleClick()}><span className="drop-down-menu__button-text">{props.text}</span></button>
                <div className="drop-down-menu__menu">
                    <ul className="drop-down-menu__menu-list">
                        {props.children}
                    </ul>

                    <ul className="drop-down-menu__menu-list drop-down-menu__menu-list--submenu drop-down-menu__menu-list--bottom">
                        <li className="drop-down-menu__menu-list-item drop-down-menu__menu-list-item--logout">
                            <a href={`${DASHBOARD_URL}/Authentication/Logout`}>Logga ut</a>
                        </li>
                    </ul>
                    
                </div>
            </div>
        );
    }
};

export default DropDownMenu