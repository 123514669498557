import React from 'react';
import classNames from 'classnames';
import Highlighted from '../highlighted';
import './styles.scss';

const Autocompleter = (props) => {
    const { suggestions } = props;
    const suggest = suggestions.hits && suggestions.hits.map((term, index) => {
        const itemClass = classNames({
          'autocompleter__item': true,
          'autocompleter__item--is-selected': suggestions.index === index,
        });

        return (
          <li
            role="option"
            className={itemClass}
            key={index}
            onClick={() => props.handleOnSelect(term)}>
            <Highlighted search={suggestions.defaultValue   }>{term.value}</Highlighted>
          </li>);
    });
    
    if (!suggestions.isVisible) return null;

    return <ul role="listbox"
               aria-expanded={suggestions.isVisible}
               id="listbox"
               className="autocompleter">{suggest}</ul>;
    
};

export default Autocompleter;