import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import Overlay from '../overlay';
import Spinner from '../spinner';
import { DASHBOARD_URL } from '../../config';
import './styles.scss';

class Watcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tracking: {
                TrackingName: this.props.defaultName ? this.props.defaultName : '',
                TrackingFrequency: 4,
                TrackerIdentifier: -1
            },
            isLoading: false
        };
    }

    async fetchTracking() {
        const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/GetTracking/${this.props.trackId}`, {
            method: 'GET',
            credentials: 'include'
        });
        
        return await resResult.json();
    }
    
    async componentDidUpdate(prevProps) {        
        if (this.props.step === prevProps.step) return;

        switch (this.props.step) {
            case 'settings':
                this.setState({
                    isLoading: true
                });

                //Fetch current tracking
                const settings = await this.fetchTracking();
                this.setState({
                    tracking: settings.SearchTracker,
                    isLoading: false
                });
                break;
            case 'update':
                this.setState({
                    isLoading: true
                });

                //Fetch current tracking
                const tracking = await this.fetchTracking();
                this.setState({
                    tracking: tracking.SearchTracker,
                }, () => this.props.toggleStep('save'));
                break;
            case 'new':
                this.setState({
                    tracking: {
                        TrackingName: this.props.defaultName ? this.props.defaultName : '',
                        TrackingFrequency: 4,
                        TrackerIdentifier: -1
                    }
                });
                break;
            case 'create': 
                const createRes = await this.create();
                this.setState({
                    isLoading: false,
                    isError: !createRes.Status
                });
                break;
            case 'save': 
                const updateRes = await this.update();
                this.setState({
                    isLoading: false,
                    isError: !updateRes.Status
                });
                break;
        }
    }

    getFormData() {
        const { tracking } = this.state;
        const formData = new FormData();
        formData.append('TrackingName', tracking.TrackingName);
        formData.append('TrackerIdentifier', tracking.TrackerIdentifier);        
        formData.append('TrackingQuery', this.props.defaultTrackingQuery ? this.props.defaultTrackingQuery : this.props.location.search);
        formData.append('TrackingFrequency', tracking.TrackingFrequency);
        formData.append('LastUpdated', -1);

        return formData;
    }

    async update() {
        const formData = this.getFormData();

        const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/UpdateTracking`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });

        return await resResult.json();
    }

    async create() {
        const formData = this.getFormData();

        const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/CreateTracking`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });

        return await resResult.json();
    }
    
    handleNameChange(e) {
        const trackingName = e.currentTarget.value;
        this.setState({
            tracking: {
                ...this.state.tracking,
                TrackingName: trackingName
            }
        })
    }

    handleFrequencyChange(frequency) {
        this.setState({
            tracking: {
                ...this.state.tracking,
                TrackingFrequency: frequency
            }
        })
    }

    updateTracking(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        });
        this.props.toggleStep('save');
    }

    createTracking(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        });

        this.props.toggleStep('create');
    }

    render() {
        let heading = 'Inställningar';

        if (this.props.step === 'new') {
            heading = 'Skapa ny bevakning';
        }

        if (this.props.step === 'save' || this.props.step === 'create') {
            heading = '✓ Sparat!';
        }

        if (this.state.isError) {
            heading = 'Något gick snett';
        }

        const { tracking } = this.state;

        return (
            <>
                <Overlay heading={heading}
                        isOpen={this.props.isWatchDialogOpen}
                        id="watch"
                        toggleOverlay={() => this.props.toggleWatchDialog()}
                        modifiers={{
                            'watch-overlay': true,
                            'overlay--is-loading': this.state.isLoading 
                        }}>
                        
                        {this.state.isLoading &&
                            <Spinner />
                        }

                        {this.state.isError &&
                            <p>Något gick tyvärr fel. Pröva gärna igen.</p>
                        }

                        {!this.state.isLoading && (this.props.step === 'save' || this.props.step === 'create') &&
                            <>
                                <a href="/myfeed" className="button button-large watch-overlay__submit">Till mina bevakningar</a>
                                <button onClick={() => this.props.toggleWatchDialog()}  className="watch-overlay__cancel">Stäng</button>
                            </>
                        }

                        {!this.state.isLoading && (this.props.step === 'new' || this.props.step === 'settings') &&
                            <div>
                                <label htmlFor="watch-name">Namn</label>
                                <input className="watch-overlay__input" 
                                       type="text" 
                                       name="trackingname"
                                       value={tracking.TrackingName} 
                                       onChange={(e) => this.handleNameChange(e)} />
                                <h4 className="watch-overlay__subheading">Frekevens på bevakningsmejl</h4>
                                
                                <input type="radio"
                                    id="every-week"
                                    name="frequency"
                                    value="4"
                                    checked={tracking.TrackingFrequency === 4}
                                    onChange={(e) => this.handleFrequencyChange(4)} />
                                <label className="watch-overlay__radio" htmlFor="every-week">Varje vecka</label>
                                <input type="radio"
                                    id="every-other-week"
                                    name="frequency"
                                    value="5" 
                                    checked={tracking.TrackingFrequency === 5}
                                    onChange={(e) => this.handleFrequencyChange(5)} />
                                <label className="watch-overlay__radio" htmlFor="every-other-week">Varannan vecka</label>
                                <input type="radio"
                                    id="every-month"
                                    name="frequency"
                                    value="6" 
                                    checked={tracking.TrackingFrequency === 6}
                                    onChange={(e) => this.handleFrequencyChange(6)} />
                                <label className="watch-overlay__radio" htmlFor="every-month">Varje månad</label>
                                <button className="button button-large watch-overlay__submit" 
                                        onClick={(e) => this.props.step === 'new' ? this.createTracking(e) : this.updateTracking(e)}
                                        type="submit">Spara inställningar
                                </button>
                            </div>
                        }
                </Overlay>
            </>
        );
    }
    
};

export default withRouter(Watcher);