import React from 'react';

const Temp = (product) => {

    const storageTempValues = product.Temperaturinformation && product.Temperaturinformation.find(f => f.T3822_Kod === 'STORAGE_HANDLING');

    if (storageTempValues) {
        return (
            <tr>
                <th>Temperatur (°C): </th>
                <td>
                    <ul className="product-sheet__table-list">
                        {storageTempValues.T3797_MinTemperatur_Varde !== null && 
                            <li>Min: {storageTempValues.T3797_MinTemperatur_Varde} °C</li>
                        } 

                        {storageTempValues.T3796_MaxTemperatur_Varde !== null && 
                            <li>Max: {storageTempValues.T3796_MaxTemperatur_Varde} °C</li>
                        }
                    </ul>
                </td>
            </tr>
        );
    }
    return null;
};

const Fukt = (product) => {

    if (product.RelativLuftfuktighetMin || product.RelativLuftfuktighetMax) {
        return (
            <tr>
                <th>Rel. luftfuktighet i %:</th>
                <td>
                    <ul className="product-sheet__table-list">
                        {product.RelativLuftfuktighetMin !== null && 
                            <li>Min: {product.RelativLuftfuktighetMin}%</li>
                        } 

                        {product.RelativLuftfuktighetMax !== null && 
                            <li>Max: {product.RelativLuftfuktighetMax}%</li>
                        }
                    </ul>
                </td>
            </tr>
        );
    }
    
    return null;
}

const Property = ({prop, text}) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    ); 
}

const isVisible = (product) => {
    if ((product.TemperaturMin || product.TemperaturMax) ||
        (product.RelativLuftfuktighetMin || product.RelativLuftfuktighetMax) ||
        product.TotalHallbarhetAntalDagar || 
        product.HallbarhetEfterOppning || 
        product.OpenJar || 
        product.Forvaringsinstruktion ||
        product.Anvandningsinstruktioner) return true;

    return false;
};

const Forvaring = (product) => {
    if (!isVisible(product)) return null;
    
    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Förvaring</h2>
                <table className="product-sheet__table product-sheet__table--align-right">
                    <tbody>
                        <Temp {...product} />
                        <Fukt {...product} />
                        <Property prop={product.TotalHallbarhetAntalDagar} text="Total hållbarhet (dagar):" />
                        <Property prop={product.HallbarhetEfterOppning} text="Hållbarhet efter öppning:" />
                        <Property prop={product.OpenJar_Formatted} text="Open Jar:" />
                        <Property prop={product.Forvaringsinstruktion} text="Förvaringsinstruktioner:" />
                        <Property prop={product.Anvandningsinstruktioner} text="Användningsinstruktioner:" />
                        <Property prop={product.T4242_Temperaturstatus} text="Temperaturstatus:" />
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default Forvaring;