import React from 'react';
import { URL } from '../../config';

const ExcelTable = props => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Artikelkategori</th>
                    <th>Produktnamn</th>
                    <th>Tillverkarens art.nr.</th>
                    <th>Varumärke</th>
                    <th>GTIN</th>
                    <th>Nivå</th>
                    <th>Produktblad</th>
                </tr>
            </thead>
            <tbody>
                {props.search.SearchResults.map((item, i) => {
                    return (
                        <tr key={`export${i}`}>
                            <td>{item.ArtikelKategori}</td>
                            <td>{item.ArtikelBenanmning}</td>
                            <td>{item.TillverkarensNr}</td>
                            <td>{item.Varumarke}</td>
                            <td className="textmode">{item.GTIN}</td>
                            <td>{item.Forpackning}</td>
                            <td>{`${URL}/productsheet/${item.GTIN}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ExcelTable;