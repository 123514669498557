import React, { Component } from 'react';
import classNames from 'classnames';
import SearchBar from '../searchbar';
import './styles.scss';

class Hero extends Component{
    
    constructor(props) {
        super(props);
        this.heroRef = React.createRef();
    }

    handleScroll() {
        //let lastScrollY = window.scrollY;
        //window.requestAnimationFrame(() => {
        //    const node = this.heroRef.current;
            //node.style.transform = `translate3d(0px, ${Math.round(lastScrollY*0.5)}px, 0px)`;
        //});
    }

    componentDidMount() {
        //if (window) window.addEventListener('scroll', () => this.handleScroll(), true);
    }
    
    componentWillUnmount() {
        //if (window) window.removeEventListener('scroll', () => this.handleScroll(), true);
    }

    render() {
        return (
            <section className={classNames({
                'hero': true,
                ...this.props.modifiers
            })}>
                <div className="hero__bg-wrapper">
                    {this.props.gradient &&
                        <div className="hero__background" style={{backgroundImage:'linear-gradient(rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%), url(' + this.props.img.full.url + ')' }}></div>
                    }
                    {!this.props.gradient &&
                        <div className="hero__background" style={{backgroundImage:'url(' + this.props.img.full.url + ')' }}></div>
                    }
                </div>
                <div className="hero__content-wrapper">
                    <div className="hero__content">
                        {this.props.byline &&
                            <span className="hero__byline">{this.props.byline}</span>
                        }
                        
                        <h1 className="hero__heading">{this.props.heading}</h1> 
                        {this.props.subheading &&
                            <span className="hero__subheading">{this.props.subheading}</span>
                        }

                        {this.props.hasSearch &&
                            <form action="/search" method="get">
                                <SearchBar totalRecordsInIndex="alla" />
                            </form>
                        }
                    </div>
                </div>
            </section>
        );
    }
};

export default Hero;