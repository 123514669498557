import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';
import routes from './routes';
/*import Preload from './preload';*/
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { renderToString } from 'react-dom/server';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { parseCookie } from './helpers/cookie';

global.renderApp = (context, req, res) => {
    return renderToString(
      <StaticRouter context={context} location={req.url}>
        <App staticContext={context} cookie={res.locals.cookie} />
      </StaticRouter>
    );
};

global.routes = routes;

if (typeof window !== 'undefined') {
  //Parse client cookies
  const cookie = parseCookie(document.cookie);
  ReactDOM.hydrateRoot(
    document.getElementById('root'),
    <BrowserRouter>
        <App cookie={cookie} />
    </BrowserRouter>
  );
}