import React from 'react';
import blocks from './blocks';
const RenderBlocks = (props) => {
    if (!props.blocks) return null;

    return props.blocks.map(({ attrs, ...block }, i) => {
        if (blocks[block.blockName]) {
            const Component = blocks[block.blockName].component;
            if (props.render) {
                return props.render(Component, { key: i, ...block, ...attrs, parent: props });
            }

            return <Component key={i} {...block} {...attrs} parent={props} />;
            
        }

        return null;
    });
};

export default RenderBlocks;