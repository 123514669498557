import React from 'react';

const Page500 = (props) => {
    return (
        <>
            <h1 className="error-page__heading">Ajdå, ett fel inträffade!</h1>
            <p>Vänligen prova igen eller kontakta Dabas support.</p>
        </>
    );
};

export default Page500;