import React from 'react';
import Image from '../image';
import AnchorLinks from '../anchorlinks';
import './styles.scss';

function MarketersIntro(props) {
    return (
        <section className="marketers-intro fit-content">
            <div className="content-padding">
                <div className="text-content">
                    <h1>{props.heading}</h1>
                    <p className="ingress">{props.preamble}</p>
                    {props.listheading &&
                        <AnchorLinks heading={props.listheading} 
                                     blocks={props.parent.blocks} />
                    }
                    {props.children}
                </div>
                <Image src={props.img.full.url} alt={props.alt} />
            </div>
        </section>
    );
};

export default MarketersIntro;