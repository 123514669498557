import React, { Component } from 'react';
import classNames from 'classnames';
import Spinner from '../../components/spinner';
import Auth from '../../hoc/auth';
import editWatchItem from '../../images/edit-watched-item.svg';
import deleteWatchItem from '../../images/delete-watched-item.svg';
import { URL, DASHBOARD_URL } from '../../config';
import './styles.scss';

class Watches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trackings: [],
            isLoading: true
        };
    }

    async fetchTrackings() {
        try {
            const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/List`, {
                method: 'GET',
                credentials: 'include'
            });
            
            //Emit search results
            return await resResult.json();
        } catch(error) {
            console.log(error);
        }
        
    }
    
    async componentDidMount() {
        const trackings = await this.fetchTrackings();
        this.setState({
            trackings,
            isLoading: false
        });
    }

    async deleteItem(id) {
        const formData = new FormData();
        formData.append('Id', id);
        this.setState({
            isLoading: true
        });
        const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/DeleteTracking`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        });

        const resDeleted =  await resResult.json();
        if (resDeleted.Status) {
            const trackings =  await this.fetchTrackings();

            this.setState({
                trackings,
                isLoading: false
            });
        }
    }

    render() {

        return (
            <section className="my-watched-items force-full-height gray-background">
                <div className="fit-content">
                    <div className="content-padding">
                        <div className="title-wrapper">
                            <h1>Mina bevakningar</h1>
                            <a href={`${DASHBOARD_URL}/Authentication/Logout`} className="logout-link" title="Logga ut">Logga ut</a>
                        </div>
                        <div className={classNames({
                            'options': true,
                            'options--is-loading': this.state.isLoading
                        })}>
                            <Spinner />
                            <div className="options-inner">
                                {this.state.trackings && this.state.trackings.map((track, i) => {  
                                    return (
                                        <div key={`track${i}`} className="option">
                                            <div className="option-info">
                                                <div className={classNames({
                                                    'new-items': track.Notification.NewResultCount > 0,
                                                    'no-new-items': track.Notification.NewResultCount === 0
                                                })}>{track.Notification.NewResultCount} nya</div>
                                                <a href={`/myfeed/${track.SearchTracker.TrackerIdentifier}`} className="option-title" title={track.SearchTracker.TrackingName}>{track.SearchTracker.TrackingName}</a>
                                            </div>
                                            <div className="option-edit">
                                                <a href={`/search?${track.SearchTracker.TrackingQuery}&trackid=${track.SearchTracker.TrackerIdentifier}`} className="edit-icon" title="Redigera"><img src={editWatchItem} alt="Redigera"/></a>
                                                <button type="button"  onClick={() => this.deleteItem(track.SearchTracker.TrackerIdentifier)} className="delete-icon" title="Radera"><img src={deleteWatchItem} alt="Radera"/></button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
        
                        <a href="/search" className="watch-new-item button button-large">
                            + Ny bevakning
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Auth(Watches, `${URL}/myfeed`);