import React from 'react';
import './styles.scss';

function PartnerExamples(props) {
    return (
        <section className="partner-examples fit-content">
            <div className="partner-examples__wrapper-outer">
                <div className="partner-examples__wrapper partner-examples-wrapper">
                    {props.images && props.images.map((img, i) => {
                        return <img key={i} src={img.sizes.full.url} alt="Axfood logo svartvit" />;
                    })}
                </div>
                {props.linktext && props.url &&
                    <a href={props.url} className="partner-examples__read-more-link" title={props.linktext}>{props.linktext} {props.sub_linktext && <span>{props.sub_linktext}</span>}</a>
                }
            </div>
        </section>
    );
};

export default PartnerExamples;