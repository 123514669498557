import React, { Component } from 'react';
import classNames from 'classnames';
import noImage from '../../images/no-product-image.jpeg';

class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasImage: this.hasProductImageLarge(this.props.Bilder),
            currentImageSrc: this.hasProductImageLarge(this.props.Bilder) ? this.getFirstProductImageLinkLarge(this.props.Bilder).Lank : noImage,
            currentImage: this.hasProductImageLarge(this.props.Bilder) ? this.getFirstProductImageLinkLarge(this.props.Bilder) : null
        }
    }

    hasProductImage(images) {
        if (images && images.length > 0) {
            const productImages = images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE');
            return productImages && productImages.length > 0;
        }

        return false;
    }

    hasProductImageLarge(images) {
        if (images && images.length > 0) {
            const productImages = images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE_LARGE');

            if (productImages && productImages.length > 0) {
                return true;
            } else {
                return this.hasProductImage(images);
            }
        }

        return false;
    }


    getProductImages(images) {
        return images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE');
    }

    getProductImagesThumb(images) {
        const thumbs = images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE_LARGE');

        if (thumbs.length > 0) {
            return thumbs;
        }

        return this.getProductImages(images);
    }

    getFirstProductImageLink(images) {
        if (images && images.length > 0) {
            const productImages = images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE');
            if (productImages && productImages.length > 0) {
                return productImages[0];
            }
        }

        return null;
    }

    getFirstProductImageLinkLarge(images) {
        if (images && images.length > 0) {
            const productImages = images.filter(image => image.Informationstyp === 'PRODUCT_IMAGE_LARGE');
            if (productImages && productImages.length > 0) {
                return productImages[0];
            } else {
                return this.getFirstProductImageLink(images);
            }
        }

        return null;
    }

    insertAt(str, sub, pos) {
        return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
    }

    handleImageClick(image) {
        this.setState({
            currentImageSrc: image.Lank,
            currentImage: image
        });
    }

    getDownloadImage(currentImageSrc) {
        return currentImageSrc && currentImageSrc.replace('_large', '');
    }

    async download(e) {
        e.preventDefault();

        const highResImages = this.getProductImages(this.props.Bilder);
        const highResImage = highResImages.find(f => f.Filnamn === this.state.currentImage.Filnamn.replace('_large', ''));

        try {
            const res = await fetch(highResImage.Lank, {
                method: 'GET',
                headers: {}
            });
            
            if (res.ok) {
                const buffer = await res.arrayBuffer();
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${highResImage.Filnamn}${highResImage.Filformat}`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        }
        catch(err) {
            console.log(err);
        };
      };
    
    render() {
        const product = this.props;
        
        if (!product.Bilder) return null;

        const imageBig = <img src={this.state.currentImageSrc} alt="" />;
    
        return (
            <div className={classNames({
                    'product-sheet__image': true,
                    'product-sheet__image--no-image': !this.state.hasImage
                })}>
                <div className="product-sheet__image-big">
                    <div className="product-sheet__image-big-container">
                        <span>
                            {imageBig}
                            {this.state.currentImage &&
                                <a className="product-sheet__image-download" href={this.getDownloadImage(this.state.currentImageSrc)} onClick={(e) => this.download(e)} targe="_blank" download title="Ladda ner bild"><span>Ladda ner bild</span></a>
                            }
                        </span>
                    </div>
                </div>
                {product.Bilder.length > 1 &&
                    <div className="product-sheet__image-gallery">
                        {this.getProductImagesThumb(product.Bilder).map((image, i) => {
                            const imageMedium = <img src={image.Lank} alt="" />;
                            return (
                                <div key={`image${i}`} 
                                    className="product-sheet__image-gallery-image"
                                    onClick={() => this.handleImageClick(image)}>
                                    {imageMedium}
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
};

export default Image;