import React, { Component } from 'react';
import { DABAS_ARTICLE_API } from '../config';
import { flattenProduct, getArtNr } from '../helpers/product';
import { withRouter } from '../hoc/withRouter';

export default (WrappedComponent) => {
    class Product extends Component {
        
        static includes = [];
    
        appendKompletterandeArtikelId(article) {
            let artId = '';
            if (article && article.KompletterandeArtikelId && article.KompletterandeArtikelId.length > 0) {
                article.KompletterandeArtikelId.forEach(articleId => {
                    if (articleId.Kod === 'SUPPLIER_ASSIGNED') {
                        artId = artId + ' ' + articleId.Id + ';';
                    }
                });
            }
    
            return artId;
        }
    
        static async requestInitialData(routes, req, res, cache, agent) {
            
            const { params } = routes;

            //Get data from DABAS API
            try {
                //Get productsheet
                const res = await fetch(DABAS_ARTICLE_API(params.slug), {
                    agent: agent
                });
                
                if (res.status > 203) {
                    const error = new Error(res.statusText);
                    error.status = res.status;
                    throw error;
                }

                const productHierarchy = await res.json();
                return {
                    productContainer: flattenProduct(productHierarchy, params)
                };
            } catch (err) {
                throw err
            }
        }
    
        render() {
            const { productContainer } = this.props.initialData;

            const base = productContainer.base && productContainer.base[0] && productContainer.base[0];
            const product = base && base.KomplettArtikel;
            product.artNr = getArtNr(productContainer);

            productContainer.product = product;
            productContainer.root = base;
            return (
                <WrappedComponent {...this.props} {...productContainer} />
            );
        }
    }
    return withRouter(Product);
};