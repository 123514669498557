import React, { Component } from 'react';
import classNames from 'classnames';
import DropDownMenu from '../dropdownmenu';
import { isUserInRole } from '../../helpers';
import { DASHBOARD_URL } from '../../config';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }
    
    toggleLogin() {
        this.setState({
            isOpen:!this.state.isOpen
        });
    }

    render() {

        const { user } = this.props;

        return (
            <div>
                {user.isAuthenticated &&
                    <DropDownMenu text="Mitt Dabas">
                        {isUserInRole(user.roles, "User") &&
                            <>
                                <li className="drop-down-menu__menu-list-item">
                                    <a target="_blank" href={`${DASHBOARD_URL}/MyPage/Index`}>Mina sidor</a>
                                </li>
                                <li className="drop-down-menu__menu-list-item">
                                    <a href="/myfeed">Mina bevakningar</a>
                                </li>
                            </>
                        }

                        {isUserInRole(user.roles, "LightUser") &&
                            <>
                                <li className="drop-down-menu__menu-list-item">
                                    <a href="/myfeed">Mina bevakningar</a>
                                </li>
                            </>
                        }

                    </DropDownMenu>
                }            
              
                {!user.isAuthenticated &&
                    <a className={classNames({
                        'login__link login-link': true,
                        ...this.props.modifiers
                        })} href={`${DASHBOARD_URL}/Login.aspx`}>Logga in</a>
                }
            </div>
        );
    }
    
};

export default Login;