import React, { Component } from 'react';
import classNames from 'classnames';
import { format } from "date-fns";

class HistoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div className={classNames({
                'history__item': true,
                'history__item--is-open': this.state.isOpen
            })}>
                 <div className="history__item-badge">
                    <svg height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true"><path fillRule="evenodd" d="M10.5 7.75a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm1.43.75a4.002 4.002 0 01-7.86 0H.75a.75.75 0 110-1.5h3.32a4.001 4.001 0 017.86 0h3.32a.75.75 0 110 1.5h-3.32z"></path></svg>
                 </div>
                <button className="history__item-date"
                        type="button"
                        onClick={(e) => this.handleToggle(e)}>Ändrad: {format(new Date(this.props.date),'yyyy-MM-dd HH:MM')} • <span>Visa ändringar</span></button>
                <div className="history__item-content">
                    {this.props.children}
                </div>
            </div>
        );
    }
};

export default HistoryItem;