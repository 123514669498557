import React, { Component } from 'react';
import classNames from 'classnames';
import { URL } from '../../config';
import Breadcrumb from '../../components/breadcrumb';
import './styles.scss';

class CategoryLevel2 extends Component {
    static async getFacets(category) {
        try {
          const res = await fetch(`${URL}/api/v1/search/categories/${category}`, {
          method: 'GET'
        });
    
          return res.json();
        } catch (err) {
            console.log(err);
            const err500 = new Error(err);
            err500.status = 500;
            throw err500;
        }
      }
    
      static async requestInitialData(routes, req, res, cache) {

        //Get search
        let facets = cache && cache.get(`SEARCH_${req.originalUrl}`);
        
        if (!facets) {
          const { params } = routes;
          facets = await CategoryLevel2.getFacets(params.category1);

          if (cache) {
            cache.set(`SEARCH_${req.originalUrl}`, facets, 7200);
          }
          
        }

        const topLevel = facets.Facets && facets.Facets.filter(f => f.Key === 'varuomradebenamning')[0].FacetValues[0]; 
        
        return {
          search: {
            ...facets,
            title: topLevel.Label
          }
        };    
    }

    render () {
        const { search } = this.props.initialData;
        const facets = search.Facets && search.Facets.filter(f => f.Key === 'huvudgruppbenamning')[0].FacetValues;

        if (!facets) return null;

        const topLevel = search.Facets && search.Facets.filter(f => f.Key === 'varuomradebenamning')[0].FacetValues[0]; 

        const { location } = this.props;

        const upOneLevel = location.pathname.replace(/\/?$/, '/');

        return (
            <>
                <Breadcrumb modifiers={{ 'breadcrumb--gray': true }}>
                    <ul className="breadcrumb__list">
                       <li><a className="breadcrumb__listitem" href={`${upOneLevel}../`}><span>Kategorier</span></a></li> 
                       <li><a className="breadcrumb__listitem breadcrumb__listitem--selected" href="."><span>{topLevel.Label}</span></a></li> 
                    </ul>
                </Breadcrumb>
                
                <section className="explore-products sub-page force-full-height gray-background">
                    <div className="explore-products__fit-content">
                        <div className="explore-products__content-padding">
                            <div className="title-with-icon">
                                <i className={classNames({
                                    'category-icon': true,
                                    'frozen-food': topLevel.Label === 'Djupfryst',
                                    'fresh-food': topLevel.Label === 'Färskvaror/Kylvaror',
                                    'colonial-food': topLevel.Label === 'Kolonial/Speceri',
                                    'non-food': topLevel.Label === 'Non food'
                                })}></i>
                                <div className="title-wrapper">
                                    <h1>{topLevel.Label}</h1>
                                    <span className="product-count">{topLevel.Count} produkter</span>
                                </div>
                            </div>
                            <div className="options">
                                {facets.map((facet, i) => {
                                    return (
                                        <a key={`facet${i}`}
                                        href={`/categories${facet.FriendlyUrl}/`}
                                        title={`Utforska kategorin  ${facet.Label}`}
                                        className="option">
                                            <div className="category-wrapper">
                                                <div className="category">
                                                    {facet.Label}
                                                </div>
                                                <div className={classNames({
                                                    'category-product-count': true,
                                                    'frozen-food': topLevel.Label === 'Djupfryst',
                                                    'fresh-food': topLevel.Label === 'Färskvaror/Kylvaror',
                                                    'colonial-food': topLevel.Label === 'Kolonial/Speceri',
                                                    'non-food': topLevel.Label === 'Non food'
                                                })}>
                                                    {facet.Count} produkter
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })}
                                
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    
};

export default CategoryLevel2;