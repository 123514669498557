import React from 'react';

const Share = (props) => {
    const { location } = props;
    return (
        <footer className="post__share">
            <div className="post__share__content">
                <div className="post__share__content-inner">
                    <a className="post__share__icon post__share__icon--twitter" href={`https://twitter.com/share?url=https://www.dabas.com${location.pathname}&text=${props.heading}`}></a>
                    <a className="post__share__icon social post__share__icon--facebook" href={`http://www.facebook.com/sharer.php?u=https://www.dabas.com${location.pathname}`}></a>
                    <a className="post__share__icon social post__share__icon--linkedin" href={`https://linkedin.com/share?url=https://www.dabas.com${location.pathname}`}></a>
                    <a className="post__share__icon social post__share__icon--mail" href={`mailto:?SUBJECT=Kom och kolla på den här nyheten!&BODY=${location.pathname}`}></a>
                </div>
            </div>
        </footer>
    );
}

export default Share;