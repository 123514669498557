import React from 'react';

const Page404 = props => {
    return (
        <>
            <h1 className="error-page__heading">Hoppsan, sidan du söker kunde inte hittas!</h1>
            <p>Vänligen prova igen eller kontakta Dabas support.</p>
        </>
    );
};

export default Page404;