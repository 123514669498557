import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './recipes.scss';

const Recipes = props => {

    const [ogTags, setOgTags] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const resOgResult = await fetch(`/api/v1/productsheet/recipes/dabas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    recipes: [{
                        ReceptURL: props.RecipeLink
                    }]
                })
            });

            let recipesOG = [];
            if (resOgResult.ok) {
                recipesOG = await resOgResult.json();

                if (recipesOG.length === 0) {
                    setOgTags([{
                        Name: 'Recept',
                        RecipeDescription: props.RecipeDescription,
                        Url: props.RecipeLink,
                        RecipeImageLink: null
                    }]);
                } else {
                    setOgTags(recipesOG);
                }
                
            }
        };

        fetchData();
    }, [props.RecipeLink]);

    const containsRecipeImageLink = (url) => {
        if (!url) return false;

        if (url.indexOf('http') === -1) return false;

        return true;
    }

    return (
        <>
            {props.RecipeLink && ogTags &&
                <section className="recipesdb">
                    <div className="recipesdb__container">
                        <div className="recipesdb__container-inner">
                            {ogTags.map((recipe, i) => {
                                return (
                                    <div key={`recipe${i}`} className="recipesdb__cols">
                                        {containsRecipeImageLink(recipe.RecipeImageLink) &&
                                            <div className="recipesdb__col">
                                                <a href={recipe.Url}>
                                                    <div className="recipesdb__image-container">
                                                        <div className="recipesdb__image" style={{ backgroundImage: `url('${recipe.RecipeImageLink}')`}}></div>
                                                    </div>
                                                </a>
                                            
                                            </div>
                                        }
                                        <div className={classNames({
                                            'recipesdb__col': true,
                                            'recipesdb__col--no-image': !containsRecipeImageLink(recipe.RecipeImageLink)
                                        })}>
                                            <h2>{recipe.Name ? recipe.Name : 'Recept'}</h2>
                                            <p>{props.RecipeDescription ? props.RecipeDescription : recipe.Origin}</p>
                                            <a href={recipe.Url} className="news-list-container__read-more-link" target="_blank" rel="noopener noreferrer">Alla recept</a>
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                </section>
            }
        </>
        
    );
};

export default Recipes;