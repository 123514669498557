import React, { Component, useState} from 'react';
import classNames from 'classnames';
import { Input, Textarea } from '../forms';
import SendButton from '../sendbutton';
import './styles.scss';

const Option = props => {
    const [isOpen, toggleIsOpen] = useState(false);

    return (
        <div className={classNames({
            'signup__option': true,
            'signup__option--is-open': isOpen
        })}>
            <h3><button onClick={() => toggleIsOpen(!isOpen)} className="signup__option-toggle" type="button">{props.heading}</button></h3>
            <div className="signup__option-content">
                {props.children}
            </div>
        </div>
    );

}

class Form extends Component {
    constructor(props) {
        super(props);
        this.sendButton = null;
        this.state = {
            isValid: true
        }
    }

    async startFetch(cb) {
        let body = this.props.getFormElements().map(input => {
            return {
                label: input.state.label,
                value: input.state.value
            };
        });

        const res = await fetch('/api/v1/mail/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([{
                label: this.props.type,
                value: this.props.subject
            }, ...body]) 
        });

        if (res.status === 200) {
            setTimeout(() => {
                cb();
            }, 10000);
        }
    }


    completed() {
        this.props.sendHandler(false);

        for (let input of this.props.getFormElements()) {
            input.clear();
        }
    }

    addButton(component) {
        this.sendButton = component;
    }

    async handleSubmit(e) {
        e.preventDefault();
       
        let valid = true;
        //Validatte form
        for (let input of this.props.getFormElements()) {
            if (input.validate() === false) {
                valid = false;
            }
        }

        if (valid) {
            this.props.sendHandler(true);
            this.setState({
                isValid: true
            });
    
            this.sendButton.animate();    
        } else {
            this.setState({
                isValid: false
            });
        }

        setTimeout(() => {
            this.setState({
                isValid: true
            });
        }, 1000);
    }

    render() {
        return (
            <form method="post" className="signup__form" action="/" onSubmit={(e) => this.handleSubmit(e)}>
                {this.props.children}
                <div className="signup__form-submit">
                    <label htmlFor="signup-submit" className="hide">Skicka</label>
                    <SendButton init={(ctrl) => this.addButton(ctrl)} 
                                isSending={this.props.isSending} 
                                startFetch={(cb) => this.startFetch(cb)} 
                                completed={() => this.completed()} 
                                text="Skicka" 
                                success="Tack för din anmälan! Vi hör av oss till dig inom kort." 
                                id="signup-submit" 
                                modifiers={{
                                    'active--dark': true,
                                    'form__submit--is-error': !this.state.isValid
                                }}/>
                </div>
            </form>
        );
    }
}

class Signup extends Component {
    constructor(props) {
        super(props);
        this.formElmCustomer = [];
        this.formApiControl = [];
        this.formContactControl = [];
        this.state = {
            isSending: false
        };
    }

    sendHandler(isSending) {
        this.setState({
            isSending
        });
    }

    addControl(component) {
        this.formElmCustomer.push(component);
    }

    addApiFormControl(component) {
        this.formApiControl.push(component);
    }

    addContactFormControl(component) {
        this.formContactControl.push(component);
    }

    render() {
        return (
            <section className={classNames({
               'signup': true,
               'signup--is-loading': this.state.isSending
            })}>
                <div className="signup__wrapper">
                    <div className="signup__wrapper-inner">
                        <h2>Vad vill du göra?</h2>
                        <div className="signup__options">
                            <div className="signup__option">
                                <Option heading="Jag är leverantör/producent och vill bli kund">
                                    <Form 
                                        isSending={this.state.isSending}
                                        type="Typ" 
                                        sendHandler={(isSending) => this.sendHandler(isSending)}
                                        subject="Ny uppgiftslämnare"
                                        getFormElements={() => {
                                        return this.formElmCustomer;
                                    }}>
                                        <fieldset>
                                            <p className="signup__option-text">Registrera ditt företag som uppgiftslämnare för att börja hantera, kvalitetssäkra och publicera din artikelinformation.</p>
                                            <p className="signup__mandatory">
                                                * Obligatorisk uppgift<br />
                                                ** Obligatorisk uppgift för uppgiftslämnare
                                            </p>
                                            <div className="signup__form-body">
                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-company">Företag *</label>
                                                    <Input id="signup-company" 
                                                        label="Företag" 
                                                        type="text" 
                                                        name="company" 
                                                        required={true} 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-email">E-post (generell) *</label>
                                                    <Input id="signup-email" 
                                                        label="E-post (generell)" 
                                                        type="email" 
                                                        name="signup_email" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-phone">Telefon</label>
                                                    <Input id="signup-phone" 
                                                        label="Telefon (växel)" 
                                                        type="tel" 
                                                        name="phone" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-address">Adress</label>
                                                    <Input id="signup-address" 
                                                        label="Adress" 
                                                        type="text" 
                                                        name="address" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-zipcode">Postnummer</label>
                                                    <Input id="signup-zipcode" 
                                                        type="text" 
                                                        name="zipcode" 
                                                        label="Postnummer" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-city">Postort</label>
                                                    <Input id="signup-city" 
                                                        type="text" 
                                                        name="city" 
                                                        label="Postort" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-company-number">Organisationsnummer **</label>
                                                    <Input id="signup-company-number" 
                                                        type="text" 
                                                        name="company_number" 
                                                        label="Organisationsnummer" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-gln-number">GLN-nummer **</label>
                                                    <Input id="signup-gln-number" 
                                                        type="number" 
                                                        name="gln" 
                                                        label="GLN-nummer" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-contact-person">Kontaktperson *</label>
                                                    <Input id="signup-contact-person" 
                                                        type="text" 
                                                        name="person" 
                                                        label="Kontaktperson" 
                                                        required={true} 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="signup-contact-email">E-post (till kontaktperson)</label>
                                                    <Input id="signup-contact-email" 
                                                        type="email" 
                                                        name="email" 
                                                        label="E-post (till kontaktperson)" 
                                                        addControl={(control) => this.addControl(control)} />
                                                </div>

                                                <div className="signup__form-terms">
                                                    <div className="signup__form-terms-inner">
                                                        Genom att skicka formuläret godkänner jag <a href="https://dabas.blob.core.windows.net/dabas-public/Dabas-allmanna-villkor.pdf" target="_blank" title="Dabas användningsvillkor">användningsvillkoren</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </Form>
                                </Option>
                            </div>
                            <div className="signup__option">
                                <Option heading="Jag vill bli användare av Dabas Webservice API">
                                    <Form 
                                        isSending={this.state.isSending}
                                        sendHandler={(isSending) => this.sendHandler(isSending)}
                                        type="Typ" 
                                        subject="Dabas Webservice API"
                                        getFormElements={() => {
                                        return this.formApiControl;
                                    }}>
                                        <fieldset>
                                            <p className="signup__option-text">Dabas Webservice ger er möjlighet att koppla samman och konsumera artikelinformationen från Dabas i era egna system. Tjänsten är ett Webservice API för Dabas hela artikeldatabas. Att använda och ansluta sig till tjänsten är gratis.</p>
                                            <p className="signup__mandatory">
                                                * Obligatorisk uppgift
                                            </p>
                                            <div className="signup__form-body">
                                                <div className="signup__form-item">
                                                    <label htmlFor="api-company">Företag *</label>
                                                    <Input id="api-company" 
                                                        label="Företag" 
                                                        type="text" 
                                                        name="apicompany" 
                                                        required={true} 
                                                        addControl={(control) => this.addApiFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="api-email">E-post *</label>
                                                    <Input id="api-email" 
                                                        label="E-post" 
                                                        type="email" 
                                                        name="apiemail" 
                                                        required={true}
                                                        addControl={(control) => this.addApiFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="api-phone">Telefon *</label>
                                                    <Input id="api-phone" 
                                                        label="Telefon" 
                                                        type="tel" 
                                                        name="apiphone" 
                                                        required={true}
                                                        addControl={(control) => this.addApiFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="api-contact">Kontaktperson *</label>
                                                    <Input id="api-contact" 
                                                        label="Kontaktperson" 
                                                        type="text" 
                                                        name="apicontact" 
                                                        required={true}
                                                        addControl={(control) => this.addApiFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item signup__form-item--wide">
                                                    <label htmlFor="api-usage">Jag skall använda Dabas Webservice till *</label>
                                                    <Textarea id="api-usage" 
                                                        name="apiusage" 
                                                        className="form__textarea"
                                                        required={true}
                                                        label="Jag skall använda Dabas Webservice till" 
                                                        addControl={(control) => this.addApiFormControl(control)} />
                                                </div>

                                                <div className="signup__form-terms">
                                                    <div className="signup__form-terms-inner">
                                                        Genom att skicka formuläret godkänner jag <a href="https://dabas.blob.core.windows.net/dabas-public/Allma%CC%88nna%20villkor%20DABAS%20-%20Dabas%20webservice%20API.pdf" target="_blank" title="Dabas API användningsvillkor">användningsvillkoren</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </Form>
                                </Option>
                            </div>
                            <div className="signup__option">
                                <Option heading="Jag vill bli kontaktad">
                                    <Form 
                                        isSending={this.state.isSending}
                                        sendHandler={(isSending) => this.sendHandler(isSending)}
                                        type="Typ av formulär" 
                                        subject="Jag vill bli kontaktad"
                                        getFormElements={() => {
                                        return this.formContactControl;
                                    }}>
                                        <fieldset>
                                            <p className="signup__option-text">Fyll i dina kontaktuppgifter så hör vi av oss!</p>
                                            <p className="signup__mandatory">
                                                * Obligatorisk uppgift
                                            </p>
                                            <div className="signup__form-body">
                                                <div className="signup__form-item">
                                                    <label htmlFor="contact-name">Namn *</label>
                                                    <Input id="contact-name" 
                                                        label="Namn" 
                                                        type="text" 
                                                        name="contactname" 
                                                        required={true} 
                                                        addControl={(control) => this.addContactFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="contact-email">E-post *</label>
                                                    <Input id="contact-email" 
                                                        label="E-post" 
                                                        type="email" 
                                                        name="contactemail" 
                                                        required={true}
                                                        addControl={(control) => this.addContactFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item">
                                                    <label htmlFor="contact-phone">Telefon *</label>
                                                    <Input id="contact-phone" 
                                                        label="Telefon" 
                                                        type="tel" 
                                                        name="contactphone" 
                                                        required={true}
                                                        addControl={(control) => this.addContactFormControl(control)} />
                                                </div>

                                                <div className="signup__form-item signup__form-item--wide">
                                                    <label htmlFor="contaact-text">Meddelande</label>
                                                    <Textarea id="contaact-text" 
                                                        name="contaacttext" 
                                                        label="Meddelande" 
                                                        className="form__textarea"
                                                        addControl={(control) => this.addContactFormControl(control)} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </Form>
                                </Option>
                            </div>
                        </div>    
                    </div>
                </div>
            </section>
        );
    }
};

export default Signup;