import React from 'react';
import classNames from 'classnames';
import HistoryItem from './item';
import Spinner from '../../components/spinner';
import './styles.scss';

const History = (props) => {
    return (
        <div className={classNames({
            'history': true,
            ...props.modifiers
        })}>
            <h3 className="history__heading">{props.heading}</h3>
            <div className="history__content">
                {props.children}
                <Spinner />
            </div>
        </div>
    );
};

export {
    History,
    HistoryItem
};