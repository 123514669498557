import React, { Component } from 'react';
import classNames from 'classnames';
import './filtertooltip.scss';

class FilterTooltip extends Component {
    constructor() {
        super();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false
        };
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    
    componentDidMount() {
        //Outside click
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
          this.setState({ isOpen: false });
        }
      }

    handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <>
                <span ref={this.setWrapperRef} className={classNames({
                    'tooltip': true,
                    'tooltip--is-open': this.state.isOpen,
                })}>
                    <span className="tooltip__icon" onClick={(e) => this.handleClick(e)}></span>
                    <div className="tooltip__popup">
                        <h5 className="tooltip__popup-heading">{this.props.heading}</h5>
                        {this.props.children}
                    </div>
                </span>
            </>
        );
    }
}

export default FilterTooltip;