import React from 'react';
import NewsList from '../../components/newslist';

const News = (props) => {

    return (
        <NewsList heading="Nyheter"
                  useLogo={false} 
                  newsList={props.newsList && props.newsList.map(item => {
                            return { 
                                ...item,
                                linkAttr: item.FromRss ? { target: '_blank', rel: 'noopener noreferrer'} : null,
                                Link: item.Link
                            };
                    })} />
    ); 
};

export default News;
