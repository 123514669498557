import React, { Component } from 'react';
import { withRouter } from './withRouter';
import { WP_API_URL } from '../config';

export default (WrappedComponent) => {
    class Page extends Component {
     
        static async requestInitialData(routes, req, res, cache) {
            let endpoint = '';
            if (routes.wpPageId) {
                endpoint = `${WP_API_URL}pages/${routes.wpPageId}`;
            } else {
                endpoint = `${WP_API_URL}pages?slug=${routes.params.slug}`;
            }
            
            try {
                //let wpData = cache && cache.get(endpoint);
                //if (!wpData) {
                const res = await fetch(endpoint);
                if (!res.ok) {
                    const err500 = new Error(res);
                    err500.status = 500;
                    throw err500;
                }

                const wpData = await res.json();
                if (!wpData || wpData.length === 0) {
                    return null;
                }

                    /*if (cache) {
                        cache.set(endpoint, wpData);
                    }*/
                //}

                //Wordpress can respond with arrays
                if (wpData.length > 0) {
                    return wpData[0];
                }

                return wpData;
                
            } catch (err) {
                throw err;
            } 
            
        }

        render() {
            const { initialData } = this.props;

            return (
                <WrappedComponent {...this.props} initialData={initialData} />
            );
        }
    }

    return withRouter(Page);
};