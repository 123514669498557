import React, { Component } from 'react';
import qs from 'qs';

export default (WrappedComponent) => {
    return class IsActiveSearch extends Component {
      constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
      }

      hasSearchParamInQuery(elm, query) {
        if (elm && elm.props.name) {
          if (elm.props.name in query) {
              return true;
          }
          return false; 
        }
        return false;
      }

      isActive() {
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let active = false;
        if (this.props.children.length) {
          for(let elm of this.props.children) {
              if (this.hasSearchParamInQuery(elm, query)) {
                  active = true;
              }
          }
        } else {
            if (this.hasSearchParamInQuery(this.props.children, query)) {
                active = true;
            }
        }

        return active;
      }

      componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
          const active = this.isActive();
          if (active) {
            this.setState({
                isActive: true
            });
          }

          if (!active && this.state.isActive) {
            this.setState({
              isActive: false
            });
          }
        }
      }

      componentDidMount() {
        this.props.numOfFiltersApplied(this);
        const active = this.isActive();
        if (active) {
            this.setState({
                isActive: true
            });
        }
      }
  
      render() {
        return <WrappedComponent {...this.props} {...this.state} />;
      }
    }
  };