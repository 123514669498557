import React from 'react';
import classNames from 'classnames';
import PartnerExamples from '../partnerexamples';
import './styles.scss';

const MarketersCustomersTagline = (props) => {
    
    return (
        <>        
            <section className={classNames({                
                ...props.modifiers
            })} id={props.blockanchor}>
                <div className="tagline__wrapper">
                    {props.heading &&
                        <h2>{props.heading}</h2>
                    }
                    {props.content &&
                        <div dangerouslySetInnerHTML={{__html: props.content}}></div>
                    }
                </div>
            </section>
            <PartnerExamples {...props} />
        </>
    );
};

export default MarketersCustomersTagline;