import React, { Component } from 'react';
import Content from '../content';
import Newsroom from '../newsroom';

class Generic extends Component {
    static async requestInitialData(routes, req, res, cache) {
        
        if (req.query && req.query.edit && cache) {            
            cache.del(req.originalUrl);    
            cache.del(req._parsedOriginalUrl.pathname);
        }
        
        let page = cache && cache.get(req.originalUrl);
        if (page) {
            return page;
        } 
        
        const contentPage = await Content.requestInitialData(routes, req, res);       
        if (contentPage) {
            cache.set(req.originalUrl,  { contentPage }, 3600);
            return {
                contentPage
            }
        }

        const newsroom = await Newsroom.requestInitialData(routes, req, res);

        if (!newsroom) {
            const err404 = new Error('Not Found 4');
            err404.status = 404;
            throw err404;
        }
        
        cache.set(req.originalUrl,  { ...newsroom }, 3600);

        return {
            ...newsroom
        }
        
    }


    render() {
        if (!this.props.initialData) return null;  //&& !this.props.initialData.content) return null;

        const { newsroom, contentPage, user } = this.props.initialData;

        if (newsroom) {
            return <Newsroom  initialData={{newsroom: newsroom}} user={user} />;
        }

        if (contentPage) {
            return <Content  initialData={contentPage} user={user} cookie={this.props.cookie} />;
        }

        return null;
    }
};

export default Generic;

