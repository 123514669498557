import React from 'react';
import Download from './download';
import Feedback from '../../components/feedback';
import './toolbar.scss';

const Toolbar = ({hideFeedback, pdfUrl, ...product}) => {
    return (
        <div className="toolbar">
            <div className="toolbar__inner">
                <Download {...product} 
                          modifiers={{
                            'download__btn--small': true
                          }} 
                          pdfUrl={pdfUrl} />
                
                {!hideFeedback && <Feedback GTIN={product.GTIN} articleName={product.Artikelkategori} /> }
                <a className="toolbar__logo" href="/"><span>Till startsidan</span></a>
            </div>
        </div>
    );
};

export default Toolbar;