import React, { Component } from 'react';
import { DASHBOARD_URL } from '../../config';
import { isUserInRole } from '../../helpers';
import { Link } from 'react-router-dom';
import Overlay from '../overlay';
import {isSameISOWeek} from 'date-fns'

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }
    
    toggleMenu() {
        this.setState({
            isOpen:!this.state.isOpen
        });
    }

    render() {
        
        const { user } = this.props;

        return (
            <div className="menu">
                <button href="#" 
                        className="menu__trigger" 
                        aria-expanded={this.state.isOpen}
                        aria-controls="menu"
                        onClick={() => this.toggleMenu()} title="Om Dabas">
                    <i className="menu__icon"></i>
                    <span>Om Dabas</span>
                </button>
                <Overlay isOpen={this.state.isOpen} 
                         id="menu"
                         toggleOverlay={() => this.toggleMenu()}
                         heading="Om Dabas">
                    <a className="menu__link" href="/why" title="Varför Dabas?">Varför Dabas?</a>
                    <a className="menu__link" href="/solutions" title="Dabas lösningar">Lösningar</a>
                    <a className="menu__link" href="/onboard" title="Dabas lösningar">Kom igång</a>
                    <a className="menu__link" href="/about" title="Om Dabas">Om oss</a>
                    <a className="menu__link" href="/blog" title="Dabas blogg">Blogg</a>
                    <div className="menu__login-link">
                        {isUserInRole(user.roles, 'User') &&
                            <>
                                <a href={`${DASHBOARD_URL}/MyPage/Index`} className="menu__link menu__link--small" title="Mina sidor" target="_blank">Mina sidor</a> 
                                <a href="/myfeed" className="menu__link menu__link--small" title="Mina bevakningar">Mina bevakningar</a> 
                                <a href="https://kundservice.delfi.se/" className="menu__link menu__link--small" title="Kundservice" target="_blank">Kundservice</a> 
                                <a href={`${DASHBOARD_URL}/Authentication/Logout`} className="menu__link menu__link--small" title="Logga ut">Logga ut</a>
                            </>
                        }

                        {isUserInRole(user.roles, 'LightUser') &&
                            <>
                                <a href="/myfeed" className="menu__link menu__link--small" title="Mina bevakningar">Mina bevakningar</a> 
                                <a href="https://kundservice.delfi.se/" className="menu__link menu__link--small" title="Kundservice" target="_blank">Kundservice</a> 
                                <a href={`${DASHBOARD_URL}/Authentication/Logout`} className="menu__link menu__link--small" title="Logga ut">Logga ut</a>
                            </>
                        }
                        
                        {!user.isAuthenticated &&
                            <a href={`${DASHBOARD_URL}/Login.aspx`} className="menu__link" title="Logga in">Logga in</a>
                        }
                    </div>
                </Overlay>
            </div>
        );
    }
    
}

export default Menu;