import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import ProductCard from './productcard';
import SearchStory from '../story/search-story';
import Paginate from '../paginate';
import viewport from '../../hoc/viewport';
import { DEFAULT_PAGINATION_SIZE } from '../../config';
import './styles.scss';

class ProductCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NewsStories: []
        }
    }

    getPage() {
        const query = qs.parse(this.props.location.search);
        if (!query.page) {
            query.page = 1;
        } 

        return query.page*1 + 1;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search && prevProps.search.NewsStories !== this.props.search.NewsStories && this.props.search.NewsStories.length > 0)  {
            const rand = this.props.search.NewsStories.length * Math.random() | 0;
            let newsStories = [this.props.search.NewsStories[rand]];
            const randNewsStories = this.props.search.NewsStories.filter((item, i) => i !== rand);
            
            if (randNewsStories.length > 0) {
                const rand2 = randNewsStories.length * Math.random() | 0;
                newsStories = [...newsStories, randNewsStories[rand2]];
            }

            this.setState({
                NewsStories: newsStories
            });
        }
    }

    handleLoadMore() {
        const query = qs.parse(this.props.location.search);
        query.page = this.getPage();
        return `${this.props.location.pathname}?${qs.stringify(query)}`;
    }

    getRandomNewsStory(item) {
        return (
            <SearchStory {...item} />
        );
    }
    
    render() {
        const props = this.props;        
        let isGridView = props.isGridView === false ? false : true;
    
        let productCard = [];
        if (props.search) {
            if (props.search.SearchResults.length === 0) {
                for(let i = 0; i < 24; i++) {
                    productCard.push(<ProductCard key={`compare-item${i}`} 
                                                  addProductcardComponent={props.addProductcardComponent} />);
                }
            } else {
                productCard = props.search.SearchResults.map((item, i) => {
                    if (isGridView && i === 6 && this.state.NewsStories && this.state.NewsStories.length > 0) {
                        return [
                            <SearchStory key={`story-item${i}`} {...this.state.NewsStories[0]} />,  
                            <ProductCard key={`compare-item${i}`} {...item} 
                                id={`compare-item${item.Arident}`} 
                                isLoading={props.isLoading}
                                compare={props.compare} 
                                isNew={props.isNew}
                                addProductcardComponent={props.addProductcardComponent} 
                                handleCompareChange={props.handleCompareChange}
                                isGridView={isGridView} />
                        ];
                    }

                    if (isGridView && i === 25 && this.state.NewsStories && this.state.NewsStories.length > 1) {
                        return [
                            <SearchStory key={`story-item${i}`} {...this.state.NewsStories[1]} />, 
                            <ProductCard key={`compare-item${i}`} {...item} 
                                id={`compare-item${item.Arident}`} 
                                isLoading={props.isLoading}
                                compare={props.compare} 
                                isNew={props.isNew}
                                addProductcardComponent={props.addProductcardComponent} 
                                handleCompareChange={props.handleCompareChange}
                                isGridView={isGridView} />
                            ];
                    }

                    return (
                        <ProductCard key={`compare-item${i}`} {...item} 
                                        id={`compare-item${item.Arident}`} 
                                        isLoading={props.isLoading}
                                        compare={props.compare} 
                                        isNew={props.isNew}
                                        addProductcardComponent={props.addProductcardComponent} 
                                        handleCompareChange={props.handleCompareChange}
                                        isGridView={isGridView} />
                    );
                });
            }
        }
    
        return (
            <section className={classNames({
                'product-cards': true,
                ...props.modifiers
            })}>
                <div className="product-cards__outer-wrapper">
                    <div className="product-cards__wrapper">
                        {props.heading &&
                            <h2 className="product-cards__heading">
                                {props.heading}
                                {props.toggleComponent}
                            </h2>
                        }
                        
                        {props.useSlider &&
                            <div className="product-cards__slider">
                                <div className="product-cards__slider-scroll">
                                    {productCard}
                                </div>
                            </div>
                        }
    
                        {!props.useSlider && 
                            <div className={classNames({
                                'product-cards__list': true,
                                'product-cards__list--list-view': !isGridView
                            })}>
                                <div className="product-cards__list-view-headings">
                                    <div className="product-cards__article-category">Artikelkategori</div>
                                    <div className="product-cards__article-name">Produktnamn</div>
                                    <div className="product-cards__manufacturer">Varumärke</div>
                                    <div className="product-cards__art-gtin-wrapper">
                                        <div>Förp.storl.</div>
                                        <div>Art.nr</div>
                                        <div>GTIN</div>
                                    </div>
                                </div>
                                {productCard}
                            </div>
                        }
                        
                        {props.readMore &&
                            <a href={props.readMore} className="product-cards__read-more-link">Fler nya artiklar</a>
                        }

                        {!props.readMore && props.search && (this.getPage()-1)*DEFAULT_PAGINATION_SIZE <= props.search.TotalRecords &&
                            <>
                                <Paginate to={this.handleLoadMore()} isLoading={props.isLoadingPage} />
                            </>
                        }
                    </div>
                </div>
            </section>
        );
    }
};

export default withRouter(ProductCards);