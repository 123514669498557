import React from 'react';
import classNames from 'classnames';
import './edit.scss';

const EditArea = (props) => {
    if (!props.isEditing) return props.children;

    return (
        <div className={classNames({
                'newsroom-edit': true,
                'newsroom-edit--no-content': !props.hasContent,
                ...props.modifiers
            })}>
            {!props.hasContent &&
                <>
                    {props.heading &&
                        <h2 className="newsroom-edit__heading">{props.heading}</h2>
                    }
                    {props.text &&
                        <p>{props.text}</p>
                    }
                </>
            }
            {props.hasContent &&
                <>
                    {props.editAction2 &&
                        <button onClick={() => {
                            window.parent.postMessage({ type: props.editAction2 }, '*');
                        }} 
                        type="button" 
                        className="newsroom-edit__edit-btn"><span className="newsroom-edit__edit-btn-icon newsroom-edit__edit-btn-icon--rss"></span><span className="newsroom-edit__edit-btn-text">{props.editBtnText2}</span></button>
                    }
                    
                    <button onClick={() => {
                                window.parent.postMessage({ type: props.editAction }, '*');
                            }} 
                            type="button" 
                            className={classNames({
                                'newsroom-edit__edit-btn': true,
                                'newsroom-edit__edit-btn--top': props.editAction2
                            })}><span className="newsroom-edit__edit-btn-icon"></span><span className="newsroom-edit__edit-btn-text">{props.editBtnText}</span></button>

                </>
            }
            <div className="newsroom-edit__toolbar">
                {props.editAction2 &&
                    <button type="button" onClick={() => {
                        window.parent.postMessage({ type: props.editAction2 }, '*');
                    }}>{props.addBtnText2}</button>
                }
                <button type="button" onClick={() => {
                    window.parent.postMessage({type: props.editAction }, '*');
                }}>{props.addBtnText}</button>
            </div>
            {props.children}
        </div>
    );
};

export default EditArea;