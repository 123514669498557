import React from 'react';
import { format } from "date-fns";
import { RenderBlock } from '../render';
import Share from '../share';
import imageMissing from '../../images/image-missing.png';
import './story.scss';

const Story = props => {

    const textElipsis = text => {
       
        if (text.length > 300) {
            return `${text.substring(0, 300)}...`;
        }

        return text;
    };
    
    return (
        <section className="story">
            {props.toolbar}
            <div className="story__container">
                <div className="story__container-inner">
                    <div className="story__top">
                        <div className="story__image" style={props.image.src && {
                            backgroundImage:`url(${props.image.src})`
                        }}>
                        {props.share &&
                            <Share {...props} />
                        }
                        </div>
                        <div className="story__text">
                            <h1 className="story__heading">{props.heading}</h1>
                            <div className="story__preamble">
                                {props.preambleBlock && props.preambleBlock.map((block, i) => <RenderBlock key={i} {...block} />)}
                            </div>
                            <p className="story__date">{format(new Date(props.date),'yyyy-MM-dd')}</p>
                            <div className="story__body">
                                {props.postBlocks && props.postBlocks.map((block, i) => <RenderBlock key={i} {...block} />)}
                            </div>
                            <div className="story__author">{props.Name}</div>
                        </div>
                    </div>
                </div>
                {props.Articles &&
                    <div className="story__products">
                        <div className="story__products-container">
                            {props.Articles.map(article => {

                                const heading = article.ArtikelBenanmning && article.ArtikelBenanmning !== '' ?  article.ArtikelBenanmning : article.ArtikelKategori;
                                const subHeading = article.ArtikelBenanmning && article.ArtikelBenanmning !== '' ?  article.ArtikelKategori : '';

                                return (
                                    <div className="story__product" key={`article${article.Arident}`}>
                                        <a href={`/productsheet/${article.GTIN}`}>
                                            {subHeading &&
                                                <h3 className="story__product-subheading  story__product-subheading--center  story__product-subheading--small">{article.ArtikelKategori}</h3>
                                            }
                                            <h2 className="story__product-heading">{heading}</h2>
                                            <h3 className="story__product-subheading story__product-subheading--center">{article.Varumarke}</h3>
                                            <div className="story__product-image">
                                                <img src={article.ProduktbildMedium === '/files/images/image-missing.png' ? imageMissing : article.ProduktbildMedium } 
                                                     alt={article.ArtikelKategori} />
                                            </div>
                                            <h3 className="story__product-subheading story__product-subheading--data"><strong>Art.nr:</strong> <span>{article.TillverkarensNr}</span></h3>
                                            <h3 className="story__product-subheading story__product-subheading--data"><strong>GTIN:</strong> <span>{article.GTIN}</span></h3>
                                            {article.Marknadsbudskap && article.Marknadsbudskap.length > 0 &&
                                                <p>
                                                    {textElipsis(article.Marknadsbudskap[0])}
                                                </p>
                                            }                                
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
            </div>
        
            
        </section>
    );
};

export default Story;