import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import Post from '../../components/post';
import Story from '../../components/story/story';

class NewsItem extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    createContent() {
        if (!this.props.News) return null;

        let cleanText = this.props.News;
        cleanText = cleanText.replace(/<p><br><\/p>/gi, '').replace('/<p><\/p>/gi', '').replace('/<br><br>/gi', '');

        return ({
            innerHTML: cleanText
        }); 
    }

    createPreamble() {
        if (!this.props.Introduction) return null;

        const preamble = <p className="post__preamble">{this.props.Introduction}</p>;

        return ({
            innerHTML: ReactDOMServer.renderToStaticMarkup(preamble)
        });
    }

    render() {
        const query = qs.parse(this.props.location.search);
        const isStory = this.props.Articles && this.props.Articles.length > 0;

        const { Image, ...props } = this.props;

        if (isStory) {
            return (
                <Story {...props} 
                        heading={this.props.Header} 
                        preambleBlock={[this.createPreamble()]} 
                        postBlocks={[this.createContent()]}
                        date={this.props.PublishDate} 
                        image={{
                            src: Image && Image.UrlLarge,
                            alt: ''
                        }}
                        share={true} 
                        toolbar={query.edit === 'true' &&
                            <>
                                <button type="button" 
                                    onClick={() => {
                                        window.parent.postMessage({ type: 'EDIT_NEWS_ITEM', 
                                                                    id: this.props.NewsroomNewsId }, '*');
                                    }} 
                                    className="newsroom-edit__edit-btn newsroom-edit__edit-btn--news-item">
                                        <span className="newsroom-edit__edit-btn-icon"></span>
                                        <span className="newsroom-edit__edit-btn-text">Redigera nyhet</span>
                                </button>
                                <button type="button" 
                                        onClick={() => {
                                            window.parent.postMessage({ type: 'DELETE_NEWS_ITEM', id: this.props.NewsroomNewsId }, '*');
                                        }} 
                                        className="newsroom-edit__edit-btn newsroom-edit__edit-btn--news-item newsroom-edit__edit-btn--delete">
                                            <span className="newsroom-edit__edit-btn-icon newsroom-edit__edit-btn-icon--delete "></span>
                                            <span className="newsroom-edit__edit-btn-text">Radera nyhet</span>
                                </button>
                        </>}
                />
            );
        }

        return (
            <>
                <Post heading={this.props.Header} 
                      preambleBlock={[this.createPreamble()]}
                      postBlocks={[this.createContent()]}
                      image={{
                        src: Image && Image.UrlLarge,
                        alt: this.props.Header
                      }}
                      date={this.props.PublishDate} 
                      share={true} 
                      toolbar={query.edit === 'true' &&
                        <>
                            <button type="button" 
                                  onClick={() => {
                                      window.parent.postMessage({ type: 'EDIT_NEWS_ITEM', id: this.props.NewsroomNewsId }, '*');
                                  }} 
                                  className="newsroom-edit__edit-btn newsroom-edit__edit-btn--news-item">
                                      <span className="newsroom-edit__edit-btn-icon"></span>
                                      <span className="newsroom-edit__edit-btn-text">Redigera nyhet</span>
                            </button>
                            <button type="button" 
                                    onClick={() => {
                                        window.parent.postMessage({ type: 'DELETE_NEWS_ITEM', id: this.props.NewsroomNewsId }, '*');
                                    }} 
                                    className="newsroom-edit__edit-btn newsroom-edit__edit-btn--news-item newsroom-edit__edit-btn--delete">
                                        <span className="newsroom-edit__edit-btn-icon newsroom-edit__edit-btn-icon--delete "></span>
                                        <span className="newsroom-edit__edit-btn-text">Radera nyhet</span>
                            </button>
                      </>
                }/>
            </>
        );
    }
    
};

export default withRouter(NewsItem);

/*<Post />*/