import React, { Component} from 'react';
import { withRouter } from '../../hoc/withRouter';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import qs from 'query-string';
import ProductCards from '../../components/productcards';
import Searchbar from '../../components/searchbar';
import SearchFilter from '../../components/searchfilter';
import Sort from '../../components/sort';
import CompareBar from '../../components/comparebar';
import Categories from '../categories';
import { DEFAULT_PAGINATION_SIZE, DASHBOARD_URL } from '../../config';
import './styles.scss';

require('formdata-polyfill');

class Search extends Component {
    constructor(props) {
        super(props);
        this.filterCategories = [];
        this.productCardComponents = [];
        this.state = {
            isComparable: false,
            isChanging: false,
            isFirstFetch: true,
            isLoading: false,
            isLoadingPage: false,
            currentAppliedFilters: 0,
            currentTracking: null,
            page: 1,
            results: {
                TotalRecords: 0,
                SearchResults: []
            }
        };
        
        this.mainForm = React.createRef();
    }

    static async requestInitialData(routes, req, res, cache) {
        return Categories.requestInitialData(routes, req, res, cache);
    }

    numOfFiltersApplied(filter) {
        this.filterCategories.push(filter);
    }

    async fetchSearchResults(query) {

        const queryString = qs.stringify({
            FromSearch: true,
            FromRange: query.page ? (query.page-1) * DEFAULT_PAGINATION_SIZE : 0,
            ...query 
        });

        const resResult = await fetch(`/api/v1/search${queryString.length > 0 ? '?' + queryString : ''}`, {
            method: 'GET'
            /*headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },*/
        });

        //Emit search results
        return await resResult.json();
    }

    getFormData(form) {
        const data = new FormData(form);

        let query = '';
        data.forEach((value, key) => { 
            if (value) {
                query = query + `&${key}=${encodeURIComponent(value)}`;
            }
        });

        return qs.parse(query);
    }

    async fetchTracking(trackId) {
        const resResult = await fetch(`${DASHBOARD_URL}/MyTrackings/GetTracking/${trackId}`, {
            method: 'GET',
            credentials: 'include'
        });

        try {
            return await resResult.json();
        } catch (err) {
            return {};
        }
    }

    async componentDidMount() {
        
        const query = qs.parse(this.props.location.search);

        const { Facets, ...results } = await this.fetchSearchResults(query);

        this.setState({
            results,
            filters: Facets,
            isFirstFetch: false
        });

        /*if (query.trackid) {
            const tracking = await this.fetchTracking(query.trackid);
            this.setState({
                isFirstFetch: false,
                currentTracking: tracking.Notification
            });
        }*/
    }

    async componentDidUpdate(prevProps, prevState) {
        const prevQuery = qs.parse(prevProps.location.search);
        const currentQuery = qs.parse(this.props.location.search);

        let currentAppliedFilters = 0;
        this.filterCategories.forEach(filter => {
            currentAppliedFilters = currentAppliedFilters + (filter.state.isActive ? 1 : 0);
        });
        
        if (currentAppliedFilters !== this.state.currentAppliedFilters) {
            this.setState({
                currentAppliedFilters,
                isChanging: true
            });

            setTimeout(() => {
                this.setState({
                    isChanging: false
                });
            }, 520);
        }

        if (currentQuery.page && prevQuery.page !== currentQuery.page) {
            
            this.setState({
                isLoadingPage: true
            });
            
            const results = await this.fetchSearchResults(currentQuery);
            this.setState({
                results: {
                    ...this.state.results,
                    SearchResults: [...this.state.results.SearchResults, ...results.SearchResults]
                },
                isLoadingPage: false
            });

            return;
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.setState({
                isLoading: true
            });
           
            const { Facets, ...results } = await this.fetchSearchResults(currentQuery);
            this.setState({
                results,
                filters: results.TotalRecords > 0 ? Facets : prevState.filters,
                isLoading: false
            });
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        const mainFormData = this.getFormData(this.mainForm.current);
        const formDataObj = this.getFormData(e.target);

        //Merge forms
        this.props.navigate(`${this.props.location.pathname}?${qs.stringify({...mainFormData, ...formDataObj})}`);
    }

    changeToGridView() {
        const { location } = this.props;
        const query = qs.parse(location.search);
        delete query.listview;

        return `?${qs.stringify(query)}`;
    }

    changeToListView() {
        const { location } = this.props;
        const query = qs.parse(location.search);
        query.listview = true;

        return `?${qs.stringify(query)}`;
    }

    handleCompareChange() {
        let comparableProducts = 0;
        let isComparable = false
        this.productCardComponents.forEach((product) => {
            if (product.state.isChecked) {
                comparableProducts++;
                
            }
        });

        if (comparableProducts > 1) {
            isComparable = true;
        }

        if (isComparable !== this.state.isComparable) {
            this.setState({
                isComparable
            });
        }
    }

    addProductcardComponent(component) {
        this.productCardComponents.push(component);
    }
    
    render() {
        const { params, location, initialData } = this.props;
        const isGridView = (qs.parse(location.search) && qs.parse(location.search).listview) ? false : true;
        
        const searchParams = qs.parse(location.search);
        const showTotalRecords = (this.state.currentAppliedFilters > 0 || (searchParams.SearchText && searchParams.SearchText !== '')) ? true : false;

        return (
            <>
                <section className="search-and-explore">
                    <div className="search-and-explore__wrapper">
                        <div className="search-and-explore__wrapper-inner">
                            <form ref={this.mainForm} method="get" action="/search">
                                <h1>Sök & utforska</h1>
                                <Searchbar modifiers={{
                                            'search-bar--is-search-page': true,
                                            'search-bar--is-loading': this.state.isLoading || this.state.isFirstFetch
                                           }} 
                                           totalRecordsInIndex={showTotalRecords && !this.state.isLoading ? this.state.results.TotalRecords : 'alla'}
                                           currentAppliedFilters={this.state.currentAppliedFilters} />
                                {!isGridView &&
                                    <input type="hidden" name="listview" value="true" />
                                }
                            </form>
                            <form className="the-search-form" method="get" action="/search" onSubmit={(e) => this.handleSubmit(e)}>
                                <SearchFilter filters={this.state.filters} 
                                              results={this.state.results} 
                                              isLoading={this.state.isLoading || this.state.isFirstFetch}
                                              isChanging={this.state.isChanging}
                                              numOfFiltersApplied={(filter) => this.numOfFiltersApplied(filter)} 
                                              currentAppliedFilters={this.state.currentAppliedFilters}
                                              user={initialData.user}>
                                    {this.props.location.search &&                                                  
                                        <div className="filter-buttons-align-right">
                                            <Link to={`${location.pathname}${this.changeToGridView()}`}
                                                  title="Visa sökresultat som brickvy"
                                                  className={classNames({
                                                        'grid-view-toggle': true,
                                                        'grid-view-toggle--grid-view': true,
                                                        'grid-view-toggle--is-active': isGridView
                                            })}></Link>

                                            <Link to={`${location.pathname}${this.changeToListView()}`}
                                                  title="Visa sökresultat som listvy"
                                                  className={classNames({
                                                     'grid-view-toggle': true,
                                                     'grid-view-toggle--list-view': true,
                                                     'grid-view-toggle--is-active': !isGridView
                                            })}></Link>
                                            <Sort />
                                        </div>
                                    }
                                </SearchFilter>
                            
                            </form>
                        </div>
                    </div>
                </section>
                
                {this.props.location.search &&
                    <form method="get" action="/search/compare">
                        <ProductCards compare={true}
                                    useSlider={false}
                                    search={this.state.results}
                                    isGridView={isGridView} 
                                    isLoading={this.state.isLoading}
                                    isLoadingPage={this.state.isLoadingPage}
                                    addProductcardComponent={(component) => this.addProductcardComponent(component)} 
                                    handleCompareChange={() => this.handleCompareChange()} 
                                    modifiers={{
                                        'product-cards--search-page': true,
                                        'product-cards--gray-background': true,
                                        'product-cards--is-filtering': this.state.isLoading,
                                        'product-cards--is-loading': this.state.isLoadingPage,
                                        'product-cards--is-firstfetch': this.state.isFirstFetch

                                    }} />
                        <CompareBar isComparable={this.state.isComparable} search={this.state.results} />
                    </form>
                }

                {!this.props.location.search &&
                    <Categories initialData={initialData} hideHeader={true} />
                }
            </>
        );
    }
}

export default withRouter(Search);