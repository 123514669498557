import React from 'react';

const Footer = (root) => {
    if (!root.Uppgiftslamnare) return null;

    return(
        <footer className="product-sheet__footer">
            <div className="product-sheet__footer-top">
                <div className="product-sheet__footer-content">
                    <div className="product-sheet__footer-container-heading">
                        <h2 className="product-sheet__footer-heading">{root.Uppgiftslamnare.Foretagsnamn}</h2>
                    </div>

                    {(root.Uppgiftslamnare.Orgnr || root.Uppgiftslamnare.GLN) &&
                        <div className="product-sheet__footer-content-col">
                            {root.Uppgiftslamnare.Orgnr &&
                                <div className="product-sheet__footer-content-box">
                                    <h3 className="product-sheet__footer-subheading">
                                        ORG. Nr.
                                    </h3>
                                    {root.Uppgiftslamnare.Orgnr}
                                </div>
                            }

                            {root.Uppgiftslamnare.GLN &&
                                <div className="product-sheet__footer-content-box">
                                    <h3 className="product-sheet__footer-subheading">
                                        GLN
                                    </h3>
                                    {root.Uppgiftslamnare.GLN}
                                </div>
                            }
                        </div>
                    }

                    {(root.Uppgiftslamnare.Tel || root.Uppgiftslamnare.Fax) &&
                        <div className="product-sheet__footer-content-col">
                            {root.Uppgiftslamnare.Tel &&
                                <div className="product-sheet__footer-content-box">
                                    <h3 className="product-sheet__footer-subheading">
                                        Telefon
                                    </h3>
                                    {root.Uppgiftslamnare.Tel}
                                </div>
                            }

                            {root.Uppgiftslamnare.Fax &&
                                <div className="product-sheet__footer-content-box">
                                    <h3 className="product-sheet__footer-subheading">
                                        Fax
                                    </h3>
                                    {root.Uppgiftslamnare.Fax}
                                </div>
                            }
                        </div>
                    }

                    <div className="product-sheet__footer-content-col product-sheet__footer-content-col--last">
                        {(root.Uppgiftslamnare.PostOrt ||
                        root.Uppgiftslamnare.PostNr || 
                        root.Uppgiftslamnare.PostOrt) &&
                            <div className="product-sheet__footer-content-box">
                                <h3 className="product-sheet__footer-subheading">
                                    Adress
                                </h3>
                                {root.Uppgiftslamnare.PostAdr && `${root.Uppgiftslamnare.PostAdr}, `}
                                {root.Uppgiftslamnare.PostNr && `${root.Uppgiftslamnare.PostNr}, `}
                                {root.Uppgiftslamnare.PostOrt && `${root.Uppgiftslamnare.PostOrt}`}
                            </div>
                        }

                        {root.Uppgiftslamnare.Email &&
                            <div className="product-sheet__footer-content-box">
                                <h3 className="product-sheet__footer-subheading">
                                    E-post
                                </h3>
                                <a href={`mailto:${root.Uppgiftslamnare.Email}`}>{root.Uppgiftslamnare.Email}</a>
                            </div>
                        }
                    </div>
                    
                </div>
                <div className="product-sheet__footer-content product-sheet__footer-content--right">
                    {root.Uppgiftslamnare.LogoLiten &&
                        <div className="product-sheet__footer-logo">
                            <img src={root.Uppgiftslamnare.LogoLiten} alt="" />
                        </div>
                    }
                    {root.Uppgiftslamnare && root.Uppgiftslamnare.WebPage &&
                        <a className="product-sheet__footer-url" href={root.Uppgiftslamnare.WebPage}>{root.Uppgiftslamnare.WebPage}</a>
                    }
                    
                </div>
            </div>

            <div className="product-sheet__footer-bottom">
                {root.Uppgiftslamnare.NewsroomAktiv &&
                    <a className="product-sheet__newsroom-link" href={`/${root.Uppgiftslamnare.MediaPath}`}>Newsroom {root.Uppgiftslamnare.Foretagsnamn}</a>
                }
                <a className="product-sheet__all-products-link" href={`/search?uppgiftslamnare=${encodeURIComponent(root.Uppgiftslamnare.Foretagsnamn)}`}>Alla produkter från {root.Uppgiftslamnare.Foretagsnamn}</a>
            </div>
            
        </footer>
        
    );
}

export default Footer;