import React from 'react';
import classNames from 'classnames';
import './hero.scss';

const HeroContent = props => {
    return (
        <section className={classNames({
            'newsroom-hero': true,
            'newsroom-hero--no-bg': !props.color,
            'newsroom-hero--no-link': !props.link
        })} style={{backgroundColor: props.color}}>
            <div className="newsroom-hero__content">
                <div className="newsroom-hero__background">
                    <h1 className="newsroom-hero__heading">
                        {props.title}
                    </h1>
                    <p className="newsroom-hero__preamble">{props.description}</p>
                    {props.link &&
                        <a href={props.link} className="newsroom-hero__link" {...props.linkAttr}>Läs hela artikeln</a>
                    }
                </div>
            </div>
        </section>
    );
};

const Hero = (props) => {

    if (!props.newsroom.NewsroomActive) {
        return <HeroContent title={props.newsroom.CompanyName} description="Har tyvärr inget Dabas newsroom än ☹️" link={false} />;
    }

    if (props.news && props.news.length > 0) {
        return <HeroContent title={props.news[0].Header} description={props.news[0].Introduction} link={props.news[0].Link} color={props.color} linkAttr={props.news[0].FromRss ? { target: '_blank', rel: 'noopener noreferrer'} : null} />;
    }  
    
    return null;
    
};

export default Hero;