import React, { Component } from 'react';
import { DABAS_API_BASE, API_KEY } from '../../config' 
import MarketersIntro from '../../components/marketersintro';
import BusinessList from '../../components/businesslist';
import marketersImg from '../../images/for-marketers-top.jpg';

class Businesses extends Component {

    //const grouped = groupBy(props.list, company => company.GroupBy);

    static async requestInitialData(routes, req, res, cache) {
        //Get data from DABAS API
        try {
            const endpoint = `${DABAS_API_BASE}participants/json?apikey=${API_KEY}`;
            let data = cache && cache.get(endpoint);

            if (!data) {
            
                const res = await fetch(`${DABAS_API_BASE}participants/json?apikey=${API_KEY}`);
            
                data = await res.json();

                const groupBy = (items, key) => items.reduce(
                    (result, item) => ({
                      ...result,
                      [item[key]]: [
                        ...(result[item[key]] || []),
                        item,
                      ],
                    }), 
                    {},
                );
            
                data = groupBy(data, 'GroupBy');

                if (cache) {
                    cache.set(endpoint, data, 7200);
                }
                
            }
            /*if (res.status > 203) {
                const error = new Error(res.statusText);
                error.status = res.status;
                throw error;
            }*/

            return { list : data };
        } catch (err) {
            throw err
        }
    }
    
    render() {
        const { initialData } = this.props;

        return (
            <>
                <MarketersIntro img={{
                   full: {
                       url: marketersImg
                   } 
                } } 
                                alt="Dabas för marknadsförare" 
                                heading="Deltagande företag" 
                                preamble="Här är våra fantastiska kunder. Sveriges allra främsta livsmedelsföretag, både stora som små. Det är oerhört inspirerande att se fler än 500 företag och 3500 varumärken använda Dabas för att nå ut med sin digitala produktinformation till marknaden">
                     <>
                        <h3>Alla företag A-Ö</h3>
                        <div className="marketing-usages">
                            {Object.keys(initialData.list).sort().map((anchor, i) => <a key={i} href={`#${anchor}`} title={anchor}>{anchor}</a>)}
                        </div>
                    </>
                </MarketersIntro>
    
                <BusinessList list={initialData.list} />
            </>
        );
    }
};

export default Businesses;