import React from 'react';
import { format } from "date-fns";

const ListItem = (props) => {
    return (
        <div className="news-list__item">
            <div className="news-list__item-date">{format(new Date(props.PublishDate),'yyyy-MM-dd')}</div>
            <a href={props.Link} 
               target={props.FromRss ? '_blank' : '_self'} rel="noopener noreferrer"
               title={props.Header}
               {...props.linkAttr}>{props.Header}</a>
            {props.useLogo &&
                <div className="news-list__item-logo">
                    <a href={`/${props.Uppgiftslamnare.MediaPath}`}>
                        {props.logo &&
                            <img src={props.logo} alt={`${props.UppgfitslamnareName ? props.UppgfitslamnareName : ''} logo`} />
                        }
                        {!props.logo && props.UppgfitslamnareName}
                    </a>
                </div>
            }
        </div>
    );
};

export default ListItem;