import React, { Fragment  } from 'react';
import MarkningIkoner from './markning-ikoner';
import Download from './download';

const GeneralInfo = (product) => {

    return (
        <div className="product-sheet__general">
            {product.artNr && product.artNr.length > 0 &&
                <div className="product-sheet__general-data">
                    <h3 className="product-sheet__general-heading">Artikelnr</h3>
                    {product.artNr && product.artNr.map((item, i) => {
                        return <Fragment key={`artnr${i}`}>{item.artNr} <span className="product-sheet__general-heading-muted">{item.artikeltyp}</span><br /></Fragment>;
                    })}
                </div>
            }
            <div className="product-sheet__general-data">
                <h3 className="product-sheet__general-heading">Varumärke</h3>
                <div><strong>{product.Varumarke && product.Varumarke.Varumarke}</strong></div>
            </div>
            <div className="product-sheet__general-data">
                <h3 className="product-sheet__general-heading">Förpackningsstorl.</h3>
                <div><strong>{product.Storlek}</strong></div>
            </div>

            <div className="product-sheet__general-data">
                <h3 className="product-sheet__general-heading">Uppgiftslämnare</h3>
                {product.Uppgiftslamnare && product.Uppgiftslamnare.Foretagsnamn &&
                    <div>
                        {product.Uppgiftslamnare.NewsroomAktiv ? 
                            <strong><a href={`/${product.Uppgiftslamnare.MediaPath}`}>{product.Uppgiftslamnare.Foretagsnamn}</a></strong> : 
                            <strong>{product.Uppgiftslamnare.Foretagsnamn}</strong>
                        }
                    </div>
                }
            </div>

            <MarkningIkoner {...product} />
            <Download {...product} pdfUrl={`/productsheet/${product.GTIN}/pdf`} />
        </div>
    );
};

export default GeneralInfo;