import React, { Component } from 'react';
import qs from 'query-string';
import classNames from 'classnames';
import Overlay from '../overlay';
import SendButton from '../sendbutton';
import { Input, Textarea, Select } from '../forms';
import './styles.scss';

class Feedback extends Component {
    
    state = {
        isOpen: false,
        isValid: true,
        isSending: false,
        isSent: false
    }

    sendButton = null;
    inputElms =  [];
    theForm = React.createRef();
    
    toggleOverlay() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    addButton(component) {
        this.sendButton = component;
    }

    completed() {
        this.inputElms.forEach(elm => {
            elm.clear();
        })
        this.setState({
            isSending: false,            
        });
    }

    async submitForm(cb) {
        const data = new FormData(this.theForm.current);

        
        const res = await fetch('/api/v1/report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: qs.stringify({
                problemType: data.get('problemType'),
                description: data.get('description'),
                gtin: data.get('gtin'),
                email: data.get('email'),
                articleName: data.get('articleName'),
                name: data.get('name')
            })
        });

        if (res.status === 200 && res.ok)  {
            cb();
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if(!this.state.isSending) {

            //Validatte form
            let valid = true;
            
            this.inputElms.forEach(ctrl => {
                if (!ctrl.validate()) {
                    valid = false;
                }
            });
    
            if (valid) {
                this.setState({
                    isValid: true,
                    isSending: true
                });
            
                this.sendButton.animate();

            } else {
                this.setState({
                    isValid: false,
                    isSending: false
                });
            }
    
            setTimeout(() => {
                this.setState({
                    isValid: true
                });
            }, 1000);
        }


    }

    addControl(component) {
        this.inputElms.push(component);
    }

    render() {
        return (
            <>
                <button className="feedback__button" onClick={() => this.toggleOverlay()}>Lämna feedback</button> 
                <Overlay isOpen={this.state.isOpen} 
                         toggleOverlay={() => this.toggleOverlay()} 
                         heading="Lämna feedback" 
                         modifiers={{
                             'feedback__overlay': true
                         }}>
                    <div className="feedback__content">
                        <form ref={this.theForm} className="feedback__form" action="/" onSubmit={(e) => this.handleSubmit(e)}>
                            <input type="hidden" name="gtin" value={this.props.GTIN} />
                            <input type="hidden" name="articleName" value={this.props.articleName} />
                            <Select name="problemType" 
                                    className={classNames({
                                        'feedback__select': true,
                                        'feedback__select--is-loading': this.state.isSending
                                    })}
                                    required={true}
                                    addControl={(control) => this.addControl(control)}
                                    errorMsg="Beskrivvning är obligatoriskt och får inte överstiga 1000 tecken." >
                                <option value="">Välj typ av feedback</option>
                                <option value="Fråga till uppgiftslämnaren">Fråga till uppgiftslämnaren/leverantören avseende aktuell artikel</option>
                                <option value="Fråga till Dabas">Fråga till Dabas-teamet</option>
                                <option value="Vi saknar artiklar/leverantör i Dabas">Vi saknar artiklar/leverantör i Dabas</option>
                                <option value="Övrig feedback">Övrig feedback</option>
                            </Select>
                            
                            <label htmlFor="problemdescription" 
                                   className="feedback__label">Beskrivning av problemet</label>
                            <Textarea 
                                id="problemdescription"
                                name="description"
                                required={true}
                                addControl={(control) => this.addControl(control)}
                                errorMsg="Beskrivvning är obligatoriskt och får inte överstiga 1000 tecken." 
                                maxLength={1000}
                                className={classNames({
                                    'feedback__textarea': true,
                                    'feedback__textarea--is-loading': this.state.isSending
                                   })} />                            
                            
                            <label htmlFor="problemname" className="feedback__label">Ditt namn</label>
                            <Input id="problemname"
                                   className={classNames({
                                    'feedback__input': true,
                                    'feedback__input--is-loading': this.state.isSending
                                   })}
                                   required={true}
                                   type="text"
                                   addControl={(control) => this.addControl(control)}
                                   name="name" 
                                   maxLength={250}
                                   errorMsg="Namn är obligatoriskt och får inte överstiga 250 tecken." />
                            
                            <label htmlFor="problememail" className="feedback__label">Din e-post</label>
                            <Input id="problememail"
                                   className={classNames({
                                    'feedback__input': true,
                                    'feedback__input--is-loading': this.state.isSending
                                   })}
                                   type="email"
                                   addControl={(control) => this.addControl(control)}
                                   name="email"
                                   placeholder="Din e-postadress" />
                            <SendButton init={(ctrl) => this.addButton(ctrl)} 
                                        isSending={this.state.isSending} 
                                        startFetch={(cb) => this.submitForm(cb)} 
                                        completed={() => this.completed()} 
                                        text="Rapportera problem" 
                                        success="Skickat!" 
                                        id="add-recipient"
                                        modifiers={{
                                            'footer__button': true,
                                            'form__submit--is-error': !this.state.isValid
                                        }} />
                        </form>
                    </div>
                </Overlay>
            </>
        );
    }
};

export default Feedback;        