import React from 'react';
import './styles.scss';

const Usp = (props)  => {
    return (
        <section className="usp">
            <div className="usp__outer-wrapper">
                <div className="usp__wrapper">
                    <div className="usp__left">
                        <img src={props.product1_image.full.url} alt={props.product1_image.alt} />
                        <p>
                            {props.product1_text}
                        </p>
                        <a href={props.product1_url} className="usp__read-more-link" title={props.product1_text}>{props.product1_linktext}</a>
                    </div>
                    <div className="usp__right">
                        <img src={props.product2_image.full.url} alt={props.product2_image.alt} />
                        <p>
                            {props.product2_text}
                        </p>
                        <a href={props.product2_url} className="usp__read-more-link" title={props.product2_text}>{props.product2_linktext}</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Usp;