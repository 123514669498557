export const getLargeImage = originalImage => {
    const name = originalImage.substring(0, originalImage.lastIndexOf('.'));

    return `${name}_large.jpg`;
};

export const isValidImage = (path) => {
    if (path.indexOf('.jpg') > -1) {
        return true;
    }

    if (path.indexOf('.jpeg') > -1) {
        return true;
    }

    if (path.indexOf('.png') > -1) {
        return true;
    }

    if (path.indexOf('.gif') > -1) {
        return true;
    }

    return false
}

export const isValidLogo = (path) => {
    if (path.indexOf('.jpg') > -1) {
        return true;
    }

    if (path.indexOf('.jpeg') > -1) {
        return true;
    }

    if (path.indexOf('.png') > -1) {
        return true;
    }

    if (path.indexOf('.gif') > -1) {
        return true;
    }

    if (path.indexOf('.svg') > -1) {
        return true;
    }

    return false
}