import React from 'react';
import classNames from 'classnames';
import './contact.scss';

const Contact = (newsroom) => {
    return (
        <div className={classNames({
            'newsroom-contact__wrapper': true,
            'newsroom-contact__wrapper--no-bg': !newsroom.RssColor,
        })}>
            <h2>Kontakt</h2>
            <p>
                {newsroom.CompanyName}<br />
                {newsroom.Address}<br />
                {newsroom.ZipCode} {newsroom.Locality}<br />
                {newsroom.Phone &&
                    <span>Tel: {newsroom.Phone}</span>
                }
            </p>
            <p>
                {newsroom.HomePage &&
                    <>
                        <a href={newsroom.HomePage} title={`${newsroom.CompanyName} webbplats`}>{newsroom.HomePage}</a><br />
                    </>
                }

                {newsroom.Email &&
                    <a href={`mailto:${newsroom.Email}`} title={`Mejla ${newsroom.CompanyName}`}>{newsroom.Email}</a>
                }
            </p>
            {newsroom.ImageLink &&
                <img src={newsroom.ImageLink} title={newsroom.CompanyName} />
            }
        </div>
    );
};

export default Contact;