import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import { DASHBOARD_URL, URL } from '../../config';
import classNames from 'classnames';
import Watcher from '../watch';
import './menu.scss';

class Menu extends Component {
    state = {
        isOpen: false,
        isWatchDialogOpen: false,
        step: null
    }

    componentDidMount() {
        const { location } = this.props;
        const query = qs.parse(location.search);

        if (query.createnew) {
            this.toggleWatchDialog('new');
        }
    }

    toggleWatchDialog(step) {
        this.setState({
            isWatchDialogOpen: !this.state.isWatchDialogOpen,
            step: step
        });
    }

    toggleStep(step) {
        this.setState({
            step
        });
    }

    ensureAuthenticated() {
        const { user, location } = this.props;
        
        if (user && user.isAuthenticated) {
            this.toggleWatchDialog('new');
        } else {

            let query = location.search;
            if (query) {
                query = query + '&createnew=true';
                
            } else {
                query = '?createnew=true';
            }

            window.location.href = `${DASHBOARD_URL}/Login.aspx?ReturnUrl=${URL}${location.pathname}${encodeURIComponent(query)}`;
        }
    }


    render() {
        const { params, location, newsroom} = this.props;

        return (
            <section className="newsroom-menu fit-content">
                <div className="newsroom-menu__logo">
                    {this.props.children}
                </div>
                <div className="newsroom-menu__navigation">
                    {newsroom.NewsroomActive &&
                        <>
                            <a href={`/${params.slug}${location.search}`} title="Företagsöversikt" className={classNames({
                                'newsroom-menu__link-item': true,
                                'newsroom-menu__link-item--active': `/${params.slug}` === this.props.location.pathname
                            })}>
                                <span>
                                    Företagsöversikt
                                </span>
                            </a>
                            <a href={`/${params.slug}/produkter${location.search}`} title="Produkter" className={classNames({
                                'newsroom-menu__link-item': true,
                                'newsroom-menu__link-item--active': `/${params.slug}/produkter` === this.props.location.pathname
                            })}>
                                <span>
                                    Produkter
                                </span>
                            </a>
                            {newsroom.News && newsroom.News.length > 0 &&
                                <a href={`/${params.slug}/nyhet${location.search}`} title="Nyheter" className={classNames({
                                    'newsroom-menu__link-item': true,
                                    'newsroom-menu__link-item--active': this.props.location.pathname.startsWith(`/${params.slug}/nyhet`)
                                })}>
                                    <span>
                                        Nyheter
                                    </span>
                                </a>
                            }
                        </>
                    }
                </div>
                <div className="newsroom-menu__follow">
                    {newsroom.NewsroomActive &&
                        <button className="newsroom-menu__follow-button"
                                onClick={() => this.ensureAuthenticated()}>Bevaka</button>
                    }
                </div>
    
                <Watcher trackId={null} 
                             isWatchDialogOpen={this.state.isWatchDialogOpen} 
                             step={this.state.step} 
                             toggleWatchDialog={() => this.toggleWatchDialog()} 
                             defaultName={newsroom.CompanyName} 
                             defaultTrackingQuery={`?uppgiftslamnare=${encodeURIComponent(newsroom.CompanyName)}`}
                             toggleStep={(step) => this.toggleStep(step)} />
            </section>
        );
    }
};

export default withRouter(Menu);