import React, { useState } from 'react';
import classNames from 'classnames';
import imageMissing from '../../images/image-missing.png';
import './push.scss'

const PushItem = props => {
    return (
        <div className="story-push__container">
            <div className="story-push__image">
                <a href={props.link} className="story-push__image-link" style={props.img && { backgroundImage: `url(${props.img})`}}></a>
                {props.useLogo &&
                    <div className="news-list__item-logo">
                        <a href={props.newsroomPath}>
                            <img src={props.logo} alt="" />
                        </a>
                    </div>
                }
            </div>
            <div className="story-push__content">
                <h1 className="story-push__heading"><a href={props.link}>{props.heading}</a></h1>
                <div className="story-push__text">
                    <p>
                        <a href={props.link}>{props.text}</a>
                    </p>
                </div>
                <div className="story-push__products">
                    {props.articles.map((item, i) => {
                        if (i > 2) {
                            return null;
                        }
                        
                        const heading = item.ArtikelBenanmning && item.ArtikelBenanmning !== '' ?  item.ArtikelBenanmning : item.ArtikelKategori;
                        const subHeading = item.ArtikelBenanmning && item.ArtikelBenanmning !== '' ?  item.ArtikelKategori : '';

                        return (
                            <div key={`story-articles${item.Arident}`} className="story-push__product">
                                <a href={`/productsheet/${item.GTIN}`}>
                                    {subHeading &&
                                        <h3 className="story-push__product-subheading story-push__product-subheading--small">{subHeading}</h3>
                                    }
                                    <h2 className="story-push__product-heading">{heading}</h2>
                                    <h3 className="story-push__product-subheading">{item.Varumarke}</h3>
                                    <div className="story-push__product-image">
                                        <img src={item.ProduktbildMedium === '/files/images/image-missing.png' ? imageMissing : item.ProduktbildMedium } 
                                             alt={item.ArtikelKategori} />
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const StoryPush = props => {

    const [index, nextPrev] = useState(0);

    if (!props.newsStories) {
        return null;
    }

    if (!props.newsStories.length === 0) {
        return null;
    }

    return (
        <>
            <section className={classNames({
                'story-push': true,
                ...props.modifiers
            })}>
                {props.useTagline &&
                    <div className="story-push__tagline">
                        <h1>Branschnyheter från <strong>Dabas Newsroom</strong></h1>
                    </div>
                }
                <div className="story-push__slider-container">
                    <div className="story-push__slider" style={{
                        transform: `translateX(${(index*100)*-1}%)`
                    }}>
                        {props.newsStories.map((item, i) => {
                            return (
                                <div key={`newsStories${item.NewsroomNewsId}`} 
                                     className={classNames({
                                        'story-push__slider-item': true,
                                        'story-push__slider-item--selected': i === index
                                })}>
                                    <PushItem key={`push-item${i}`} 
                                              heading={item.Header} 
                                              newsroomPath={item.Uppgiftslamnare.MediaPath}
                                              img={item.Image && item.Image.UrlLarge} 
                                              logo={item.Uppgiftslamnare.Image}
                                              articles={item.Articles} 
                                              text={item.Introduction} 
                                              link={item.Link} 
                                              useLogo={props.useLogo} />
                                </div>
                            );
                        })}
                    </div>

                    <ul className="story-push__paginate">
                        {props.newsStories.map((item, i) => {
                            return (
                                <li key={`push-item-paginate${item.NewsroomNewsId}`}><button className={classNames({
                                    'story-push__paginate-item': true,
                                    'story-push__paginate-item--selected': i === index
                                })} type="button" onClick={() => nextPrev(i)}></button></li>
                            );
                        })}
                    </ul>
                </div>
            </section>
            {/*
            <button type="button" onClick={() => nextPrev(index > 0 ? index-1 : 0)}>Föregående</button>
            <button type="button" onClick={() => nextPrev(index < (items.length-1) ? index+1 : items.length-1)}>Nästa</button>
            */}
        </>
    );
};

export default StoryPush;