import React, { Component } from 'react';
import qs from 'query-string';
import { withRouter } from '../../hoc/withRouter';
import Footer from '../../components/footer';
import ScrollToTop from '../../hoc/scrollToTop';
import RenderBlocks from '../../blocks';
import NewsList from '../../components/newslist';
import ProductCards from '../../components/productcards';
import StoryPush from '../../components/story/push';
//import Instagram from '../../components/instagram';
import { WP_API_URL, URL, DABAS_API_BASE, API_KEY } from '../../config';

class Index extends Component {

  state = {
    search: null,
    news: null,
    newsStories: null,
    stats: null
  };

  static async getStats() {
    
    try {
      const res = await fetch(`${DABAS_API_BASE}articlestatistics/json?apikey=${API_KEY}`);

      if (!res.ok) {
        return null;
    }
    
    const result = await res.json();
    return result;

    } catch (err) {
      console.log(err);
      return null;
    }
    
  }

  static async getLatestNews() {
    
    try {
      const res = await fetch(`${URL}/api/v1/news/FetchNews?top=5&skip=0`)

      if (!res.ok) {
        return null;
        //throw new Error(res.statusText);
      }
      
      let result = await res.json();
      return result;

    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getWordPressData(routes, agent) {
    let endpoint = '';

    if (routes.wpPageId) {
        endpoint = `${WP_API_URL}pages/${routes.wpPageId}`;
    } else {
        endpoint = `${WP_API_URL}pages?slug=${routes.params.slug}`;
    }
    
    try {
        const res = await fetch(endpoint, {
            agent: agent
          });
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res.json();
    } catch (err) {
        console.log(err);
        return {};
    } 
  }

  static async getLatestDocuments() {
    try {

      /*console.log(`${URL}/api/v1/search?${qs.stringify({
        FromSearch: true,
        FromRange: 0,
        top: 4,
        produktbild: '/files/images/image-missing.png',
        sort: 'latest'
      })}`);*/

      const res = await fetch(`${URL}/api/v1/search?${qs.stringify({
        FromSearch: true,
        FromRange: 0,
        top: 4,
        produktbild: '/files/images/image-missing.png',
        sort: 'latest'
      })}`, {
        method: 'GET'
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }
      
      return res.json();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async requestInitialData(routes, req, res, cache, agent) {
    //Get data from wordpress
    let wpData = cache && cache.get(`WP_${req.originalUrl}`);
    
    if (!wpData) {
        wpData = await Index.getWordPressData(routes, agent);
        if (cache) {
          cache.set(`WP_${req.originalUrl}`, wpData);
        }
    } 
    
    //Get search
    let latestDocuments = cache && cache.get(`SEARCH_${req.originalUrl}`);
    
    if (!latestDocuments) {
      latestDocuments = await Index.getLatestDocuments();
      if (cache) {
        cache.set(`SEARCH_${req.originalUrl}`, latestDocuments, 7200);
      }
      
    }

     //Get latest news
    let latestNews = cache && cache.get(`NEWS_${req.originalUrl}`);
    if (!latestNews) {
      latestNews = await Index.getLatestNews();

      if (cache) {
        cache.set(`NEWS_${req.originalUrl}`, latestNews, 7200);
      }
      
    }

    let stats = cache && cache.get('STATS');
    if (!stats) {
      stats = await Index.getStats();

      if (cache) {
        cache.set('STATS', stats, 21600);
      }
      
    }

    const { ...rest } = latestDocuments;

    return {
      content: wpData && wpData[0],
      search: rest,
      news: latestNews && latestNews.news,
      newsStories: latestNews && latestNews.newsStories,
      stats: stats
    };    
  }

  /*async componentDidMount() {
    //Get search
    
    let latestDocuments = await Index.getLatestDocuments();
      
     //Get latest news
    let latestNews = await Index.getLatestNews();

    let stats = await Index.getStats();

    const { ...rest } = latestDocuments;
    
    this.setState({
        search: rest,
        news: latestNews && latestNews.news,
        newsStories: latestNews && latestNews.newsStories,
        stats: stats
    });
  }*/
  
  render() {
    
    if (!this.props.initialData && !this.props.initialData.content) return null;

    const { content } = this.props.initialData;
    const { search, news, newsStories, stats } = this.props.initialData;

    const blocksTop = content && content.blocks && 
                      content.blocks.filter(x => x.blockName === 'dabas/block-hero-block' || 
                                                                x.blockName === 'dabas/block-usp-block' ||
                                                                x.blockName === 'dabas/block-news-banner-block');
    const blocksBottom = content && content.blocks && 
                         content.blocks.filter(x => x.blockName !== 'dabas/block-hero-block' && 
                                                              x.blockName !== 'dabas/block-usp-block' &&
                                                              x.blockName !== 'dabas/block-news-banner-block');
  
    return (
      <div className="App">
          <ScrollToTop />
          <RenderBlocks blocks={blocksTop} render={(Block, props) => {
            if (props.blockName === 'dabas/block-hero-block') {
              return <Block {...props} 
                            hasSearch={true} 
                            totalRecordsInIndex={search && search.TotalRecords} 
                            subheading={stats && `${(stats.ArticleCount*1).toLocaleString('sv')} produkter • ${(stats.TrademarkCount*1).toLocaleString('sv')} varumärken`}
                            modifiers={{
                              'hero--is-parallax': true,
                              'hero--big-subheading': true
                            }}  />;
            }

            return <Block {...props} />;  
          }} />
          <div className="app-below-fold">
            
            <StoryPush newsStories={newsStories} tagline={true} useLogo={true} />

            {news &&
              <NewsList heading="Branschnyheter från Dabas Newsroom" 
                        readMore="/news" useLogo={true} 
                        newsList={news} 
                        modifiers={{
                          'news-list-container--white': true
                        }}
                        mapper={(newsItem) => {
                const { Uppgiftslamnare, Link, ...rest } = newsItem;
                const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

                return {
                  Link,
                  logo: Uppgiftslamnare.Image,
                  ...rest,
                  Uppgiftslamnare
                };
              }} />
            }
            {search &&
              <ProductCards heading="Nytt i databasen" 
                {...this.props}
                useSlider={true}
                search={search}
                modifiers={{
                  'product-cards--gray-background': true
                }}
                readMore={'/search?sort=latest'} />
            }
            <RenderBlocks blocks={blocksBottom} />
            <Footer {...this.props} />
          </div>
      </div>
    );
  }
}

export default withRouter(Index);
