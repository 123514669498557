import React, { Component, Fragment } from 'react';
import { withRouter } from '../../hoc/withRouter';
import { format } from "date-fns";
import Product from '../../hoc/product'; 
import Toolbar from '../productsheet/toolbar';
import Footer from '../productsheet/footer';
import { History, HistoryItem } from '../../components/history';
import uhmLogo from '../../images/upphandlingsmyndigheten.png';
import './styles.scss';

class Uhm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [],
            isLoading: true
        };

        this.baseCriterium = [];
        this.advancedCriterium = [];
        this.spearheadCriterium = [];
    }

    orderUHMCriterium(criterias) {
        
        if (criterias) {
            criterias.forEach(criterium => {
                if (criterium && criterium.Beskrivning) {
                    const level = criterium.Beskrivning.substring(criterium.Beskrivning.lastIndexOf(',')+1).trim();
                    switch(level) {
                        case 'Bas':
                            this.baseCriterium.push(criterium);
                            return;
                        case 'Avancerad':
                            this.advancedCriterium.push(criterium);
                            return;
                        case 'Spjutspets':
                            this.spearheadCriterium.push(criterium);
                            return;
                    }
                }
            });
        }
    }

    filterCriterias(criterias, filterBy) {
        return criterias.filter(criterium => criterium.Beskrivning.substring(criterium.Beskrivning.lastIndexOf(',')+1).trim() === filterBy);
    }

    sortCriterias(criterias) {
        return criterias.sort((a, b) => a.Beskrivning.localeCompare(b.Beskrivning));
    }

    async componentDidMount() {

        const { params, product } = this.props;

        this.orderUHMCriterium(product && product.MSRKritierier);
        //Get data from DABAS API
        try {
            const res = await fetch('/api/v1/history/gtin/' + params.slug);
            if (!res.ok) {
                throw new Error(res.statusText);
            }

            if (res.status === 200) {
                const historyUnordered = await res.json();

                const history = historyUnordered.map(item => {
                    return {
                        ...item,
                        baseCriterium: this.filterCriterias(item.MSRKritierier, 'Bas'),
                        advancedCriterium: this.filterCriterias(item.MSRKritierier, 'Avancerad'),
                        spearheadCriterium: this.filterCriterias(item.MSRKritierier, 'Spjutspets'),
                    }
                });
                this.setState({
                    history,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        } catch (err) {
            return {};
        }
    }

    render() {
        const { product, root } = this.props;

        return (
            <div className="product-sheet">
                <div className="product-sheet__content">
                    <header className="product-sheet__header">
                        <img src={uhmLogo} alt="" />
                    </header>
                    
                    <div className="uhm__product-header">
                        <h1 className="uhm__heading">Leverantörsförsäkran</h1>
                        <p>UHM kriterier för livsmedel</p>
                    </div>
                    
                    <section className="product-sheet-box product-sheet-box--uhm">
                        <div className="product-sheet-box__inner">
                            <h2 className="product-sheet-box__heading">Produkt</h2>
                            <div className="product-sheet__general product-sheet__general--wide">
                            <div className="product-sheet__general-data">
                                    <h3 className="product-sheet__general-heading">Artikelkategori</h3> 
                                    {product.Artikelkategori}
                                </div>
                                <div className="product-sheet__general-data">
                                    <h3 className="product-sheet__general-heading">Produktnamn</h3> 
                                    {product.Artikelbenamning}
                                </div>
                                <div className="product-sheet__general-data">
                                    <h3 className="product-sheet__general-heading">GTIN</h3>
                                    {product.GTIN}
                                </div>
                                <div className="product-sheet__general-data">
                                    <h3 className="product-sheet__general-heading">Artikelnummer</h3>
                                    {product.artNr && product.artNr.map((item, i) => {
                                        return <Fragment key={`artnr${i}`}>{item.artNr} <span className="product-sheet__general-heading-muted">{item.artikeltyp}</span><br /></Fragment>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <h2 className="product-sheet-box__heading">Upphandlingsmyndighetens hållbarhetskriterier för produkten</h2>
                    <div className="uhm__table-container">
                        <h3 className="uhm__table-heading">Bas</h3>
                        <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                            <tbody>
                                <tr>
                                    <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                    <th>Bevis</th>
                                    <th>Gäller råvara/or eller ursprungsland</th>
                                    <th>Utfärdandedatum</th>
                                </tr>
                                
                                {this.sortCriterias(this.baseCriterium).map((kriterium, i) => {
                                    return (
                                        <tr key={`kriteriumbas${i}`}>
                                            <td>{kriterium.Beskrivning}</td>
                                            <td>{kriterium.Verifikat} 
                                                {kriterium.Lank &&
                                                    <a className="uhm__file" href={kriterium.Lank}>{kriterium.LankNamn || kriterium.Lank}</a>
                                                }
                                            </td>
                                            <td>{kriterium.Ursprungsland}</td>
                                            <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3 className="uhm__table-heading">Avancerad</h3>
                        <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                            <tbody>
                                <tr>
                                    <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                    <th>Bevis</th>
                                    <th>Gäller råvara/or eller ursprungsland</th>
                                    <th>Utfärdandedatum</th>
                                </tr>
                                
                                {this.sortCriterias(this.advancedCriterium).map((kriterium, i) => {
                                    return (
                                        <tr key={`kriteriumavancerad${i}`}>
                                            <td>{kriterium.Beskrivning}</td>
                                            <td>{kriterium.Verifikat} 
                                                {kriterium.Lank &&
                                                    <a className="uhm__file" href={kriterium.Lank}>{kriterium.LankNamn || kriterium.Lank}</a>
                                                }
                                            </td>
                                            <td>{kriterium.Ursprungsland}</td>
                                            <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3 className="uhm__table-heading">Spjutspets</h3>
                        <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                            <tbody>
                                <tr>
                                    <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                    <th>Bevis</th>
                                    <th>Gäller råvara/or eller ursprungsland</th>
                                    <th>Utfärdandedatum</th>
                                </tr>
                                
                                {this.sortCriterias(this.spearheadCriterium).map((kriterium, i) => {
                                    return (
                                        <tr key={`kriteriumspjutspets${i}`}>
                                            <td>{kriterium.Beskrivning}</td>
                                            <td>{kriterium.Verifikat} 
                                                {kriterium.Lank &&
                                                    <a className="uhm__file" href={kriterium.Lank}>{kriterium.LankNamn || kriterium.Lank}</a>
                                                }
                                            </td>
                                            <td>{kriterium.Ursprungsland}</td>
                                            <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                    <History modifiers={{
                        'history--is-loading': this.state.isLoading
                    }} heading={`Historik för ${product.Artikelkategori}`}>
                        {this.state.history.map((item, i) => {
                            return (
                                <HistoryItem key={`item${i}`} date={item.SenastAndradDatum}>
                                    {item.baseCriterium && item.baseCriterium.length > 0 &&
                                        <div className="uhm__table-container">
                                             <h3 className="uhm__table-heading">Bas</h3>
                                            <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                                                <tbody>
                                                    <tr>
                                                        <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                                        <th>Bevis</th>
                                                        <th>Gäller råvara/or eller ursprungsland</th>
                                                        <th>Utfärdandedatum</th>
                                                    </tr>
                                                    {item.baseCriterium && this.sortCriterias(item.baseCriterium).map((kriterium, i) => {
                                                        return (
                                                            <tr key={`kriteriumBas${i}`}>
                                                                <td>{kriterium.Beskrivning}</td>
                                                                <td>{kriterium.Verifikat} {kriterium.LankNamn || kriterium.Lank}</td>
                                                                <td>{kriterium.Ursprungsland}</td>
                                                                <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                    {item.advancedCriterium && item.advancedCriterium.length > 0 &&
                                        <div className="uhm__table-container">
                                             <h3 className="uhm__table-heading">Avancerad</h3>
                                            <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                                                <tbody>
                                                    <tr>
                                                        <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                                        <th>Bevis</th>
                                                        <th>Gäller råvara/or eller ursprungsland</th>
                                                        <th>Utfärdandedatum</th>
                                                    </tr>
                                                    {item.advancedCriterium && this.sortCriterias(item.advancedCriterium).map((kriterium, i) => {
                                                        return (
                                                            <tr key={`kriteriumAdv${i}`}>
                                                                <td>{kriterium.Beskrivning}</td>
                                                                <td>{kriterium.Verifikat} {kriterium.LankNamn || kriterium.Lank}</td>
                                                                <td>{kriterium.Ursprungsland}</td>
                                                                <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                    {item.spearheadCriterium && item.spearheadCriterium.length > 0 &&
                                        <div className="uhm__table-container">
                                             <h3 className="uhm__table-heading">Spjutspets</h3>
                                            <table className="product-sheet__table product-sheet__table--striped product-sheet__table--widthauto product-sheet__table--uhm">
                                                <tbody>
                                                    <tr>
                                                        <th>Upphandlingsmyndighetens hållbarhetskriterie:</th>
                                                        <th>Bevis</th>
                                                        <th>Gäller råvara/or eller ursprungsland</th>
                                                        <th>Utfärdandedatum</th>
                                                    </tr>
                                                    {item.spearheadCriterium && this.sortCriterias(item.spearheadCriterium).map((kriterium, i) => {
                                                        return (
                                                            <tr key={`kriteriumSpjutspets${i}`}>
                                                                <td>{kriterium.Beskrivning}</td>
                                                                <td>{kriterium.Verifikat} {kriterium.LankNamn || kriterium.Lank}</td>
                                                                <td>{kriterium.Ursprungsland}</td>
                                                                <td>{format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd') !== '0001-01-01' && format(new Date(kriterium.Utfardandedatum),'yyyy-MM-dd')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                    {item.MSRKritierier.length === 0 &&
                                        <p>Det finns inget hållbarhetskriterie för den här versionen.</p>
                                    }
                                </HistoryItem>
                            );
                        })}
                        {this.state.history.length === 0 && !this.state.isLoading &&
                            <p>Det finns ingen historik tillgänglig för den här produkten.</p>
                        }
                    </History>
                    <Footer {...root} />
                </div>
                <Toolbar {...product} hideFeedback={true} pdfUrl={`/productsheet/uhm/${product.GTIN}/pdf`} />
            </div>
        );
    }
};

export default withRouter(Product(Uhm));