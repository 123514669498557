import React from 'react';
import { URL } from '../../config';
import { withRouter } from '../../hoc/withRouter';
import Page404 from './404';
import Page500 from './500';
import dabasLogo from '../../images/dabas-logo.svg';
import './styles.scss';

const ErrorHandler = (props) => {
  let page = null;
  if (props.status > 203) {
    page = <Page404 />;
    
  }
  
  if (props.status > 499) {
    page = <Page500 />;
  }

  return (
    <div className="error-page">
      <img className="error-page__logo" src={dabasLogo} alt="Dabas logotyp" />
      <div className="error-page__content">
        <div className="error-page__inner">
          {page}
          <div className="error-page__buttons">
            <a className="error-page__link-btn" href="." onClick={(e) => {
                e.preventDefault(); 
                props.history.goBack();
              }}>‹ Tillbaka</a>
            <a className="error-page__link-btn" href={URL}>Till Dabas.com</a>
          </div>
        </div>
        <ul className="error-page__contact">
            <li>Behöver du hjälp?</li>
            <li>08-522 243 00</li>
            <li><a href="mailto:info@delfi.se">info@delfi.se</a></li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(ErrorHandler);