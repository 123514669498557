import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import classNames from 'classnames';
import Breadcrumb from '../../components/breadcrumb';
import FilterTooltip from '../../components/searchfilter/filtertooltip';
import { flattenProduct, getArtNr } from '../../helpers/product';
import swipeIcon from '../../images/finger-swipe-icon.svg';
import clearFilterIcon from '../../images/clear-filters-icon.svg';
import imageMissing from '../../images/image-missing.png';
import infoIcon from '../../images/info-icon.svg';
import './styles.scss';

class Compare extends Component {
    constructor(props) {
        super(props);

        const query = qs.parse(props.location.search);

        if (!query.gtin && typeof window !== 'undefined') {
            window.location.href = '/search';
        }

        this.state = {
            isLoading: true,
            compare: this.buildCompareArray(typeof query.gtin === 'string' ? [query.gtin] : query.gtin)
        };
    }

    buildCompareArray(gtins) {
        return gtins.map(gtin => {
            return {
                isLoading: true
            }
        });
    }

    async fetchSearchResults(query) {
        const gtins = typeof query.gtin === 'string' ? [query.gtin] : query.gtin;
        const compare = [];
        for (let index = 0; index < gtins.length; index++) {
            const gtin = gtins[index]
            const resResult = await fetch(`/api/v1/articles/gtin/${gtin}`);
            const data = await resResult.json();
            const flatten = flattenProduct(data, { slug: gtin});

            const base = flatten.base && flatten.base[0] && flatten.base[0];
            const product = base && base.KomplettArtikel;
            product.artNr = getArtNr(flatten);
            const updatedCompare = [...this.state.compare];
            updatedCompare[index] = product;
            this.setState({
                compare: updatedCompare
            });
        }
    }

    getNaringsinfo(naringsinfo, code, key) {
        if (!naringsinfo) return '';
        let naringsItem = naringsinfo.Naringsvarden.filter(naring => {
            return naring.Enhetskod === key && naring.Kod === code;
        });

        return naringsItem && naringsItem[0] ? naringsItem[0] : {};        
    }

    getQuery(item)
    {
        const query = qs.parse(this.props.location.search);
        if (query && query.gtin) {
            let gtins = typeof query.gtin === 'string' ? [query.gtin] : query.gtin;
            const newQuery = gtins.filter(gtin => gtin !== item.GTIN).join('&gtin=');
            return `gtin=${newQuery}`;
        }

        return '';
    }
    async componentDidMount() {
        const query = qs.parse(this.props.location.search);
        if (!query.gtin) {
            return;
        }

        await this.fetchSearchResults(query);
        this.setState({
            isLoading: false
        });
    }

    getImage(images) {
        const imageMedium = images && images.filter(img => img.Informationstyp === 'PRODUCT_IMAGE_MEDIUM');

        if (imageMedium && imageMedium[0] && imageMedium[0].Lank) {
            return imageMedium[0].Lank;
        } else {
            const image = images && images.filter(img => img.Informationstyp === 'PRODUCT_IMAGE');
            return image && image[0] && image[0].Lank;
        }
    }

    getFirstNaringsInfo(naringsinfo) {
        return naringsinfo && naringsinfo[0];
    }

    render() {

        return (
            <>
                <Breadcrumb 
                    modifiers={{
                        'breadcrumb--gray': true
                    }}
                    link_text="Tillbaka"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.go(-1);
                    }}
                    url="/search" />
        
                <section className={classNames({
                    'compare-products': true,
                    'gray-background': true,
                    'compare-products--is-loading': this.state.isLoading
                })}>
                    <div className="compare-products__wrapper">
                        <div className="compare-products__wrapper-inner">
                            <div className="swipe-indicator">
                                <img
                                    src={swipeIcon}
                                    alt="Scrolla i sidled på tabellen för att se allt"/>
                                <div className="swipe-indicator-text">
                                    Scrolla i sidled på tabellen för att se allt.
                                </div>
                            </div>
                            <div className="compare-products__articles-wrapper">
                                <div className="compare-products__row compare-products__row--product-header">
                                    <div className="compare-products__product-field"></div>
                                    {this.state.compare && this.state.compare.map((item, i) => {

                                        const heading = item.Produktnamn && item.Produktnamn !== '' ?  item.Produktnamn : item.Artikelkategori;
                                        const subHeadeing = item.Produktnamn && item.Produktnamn !== '' ?  item.Artikelkategori : '';        

                                        return (
                                            <div key={i} className={classNames({
                                                'compare-products__product': true,
                                                'compare-products__product--is-loading': item.isLoading
                                            })}>
                                                {!item.isLoading &&
                                                    <>
                                                        {subHeadeing &&
                                                            <h3>{subHeadeing}</h3>
                                                        }
                                                        {heading &&
                                                            <h2>
                                                                {heading}
                                                                <a  href={`/search/compare?${this.getQuery(item)}`}
                                                                    className="remove-product"
                                                                    title="Ta bort produkt från jämförelsen"><img
                                                                    src={clearFilterIcon}
                                                                    alt="Ta bort produkt-ikon"/></a>
                                                            </h2>
                                                        }
                                                        
                                                        <div className={classNames({
                                                            'compare-products__product-image': true,
                                                            'compare-products__product-image--is-missing': !this.getImage(item.Bilder),
                                                        })}>
                                                            <img src={this.getImage(item.Bilder) ? this.getImage(item.Bilder) : imageMissing}
                                                                 alt={heading} />   
                                                        </div>
                                                        
                                                    </>
                                                }
                                                    <div className="compare-products__heading-loading"></div>
                                                    <div className="compare-products__product-image-loading">
                                                        <img src={imageMissing} alt="" />  
                                                    </div>
                                                
                                            </div>
                                        );
                                    })}
                                </div>
                                
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Varumärke</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`varumarke${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Varumarke && item.Varumarke.Varumarke ? item.Varumarke.Varumarke : '' }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Tillverkare</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`tillvarkare${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Varumarke && item.Varumarke.Tillverkare ? item.Varumarke.Tillverkare.Namn : '' }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Artikelnummer</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`artikelnummer${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.artNr && item.artNr.map((art, x) => {
                                                        return (
                                                            <React.Fragment key={`artnr${x}`}>
                                                                {art.artNr} <span className="product-sheet__general-heading-muted">{art.artikeltyp}</span><br />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                   
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">GTIN</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`gtin${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.GTIN ? item.GTIN : '' }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Tillverkningsland 
                                        <FilterTooltip heading="Tillverkningsland">
                                            <p>Tillverkningsland i betydelsen det land i vilket produkten genomgick den sista väsentliga bearbetningen (motsvarande lagstiftningens definition för ursprungsland).</p>
                                        </FilterTooltip>
                                    </div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`land${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Tillverkningslander && item.Tillverkningslander.map((item, x) => {
                                                        return (
                                                            <React.Fragment key={`tillverkningslander${x}`}>
                                                                {item.Land}<br />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                                <div className="compare-products__product-loading"></div>    
                                            </div>
                                        );
                                    })}
                                </div>
 
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Förpackningsstorl.</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`size${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Storlek ? item.Storlek : '' }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Ingredienser</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`ingredienser${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Ingrediensforteckning ? item.Ingrediensforteckning : ''}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Produktgrupp</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`produktgrupp${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {!item.isLoading &&
                                                        <>
                                                            {item.Produktkod}
                                                        </>
                                                    }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row compare-products__row--section-header">
                                    <div className="compare-products__product-field">Näringsvärde</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return <div key={`naringsvarde${i}}`} className="compare-products__product"></div>
                                    })}
                                </div>
                
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Tillagningsstatus</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`tillagningsstatus${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getFirstNaringsInfo(item.Naringsinfo) ? this.getFirstNaringsInfo(item.Naringsinfo).Tillagningsstatus : item.isLoading ? '' : 'n/a' }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Basmängd</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`basmangd${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    Per {this.getFirstNaringsInfo(item.Naringsinfo) ? `${this.getFirstNaringsInfo(item.Naringsinfo).Basmangdsdeklaration_Formatted} ${this.getFirstNaringsInfo(item.Naringsinfo).Mattkvalificerarebasmangd}` : ''} 
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Energi</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`energi${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'ENER-', 'KJO').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'ENER-', 'KJO').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>);
                                    })}
                                </div>
                        
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Energi</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`energi2${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'ENER-', 'E14').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'ENER-', 'E14').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                    
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Fett</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`fett${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'FAT', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'FAT', 'GRM').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Varav mättat fett</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`fett${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'FASAT', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'FASAT', 'GRM').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Kolhydrat</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`fett${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'CHOAVL', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'CHOAVL', 'GRM').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Varav sockerarter</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`fett${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SUGAR-', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SUGAR-', 'GRM').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Protein</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`fett${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'PRO-', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'PRO-', 'GRM').Enhet}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row compare-products__row--thick-border">
                                    <div className="compare-products__product-field">Salt (SALTEQ)</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`salteq${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SALTEQ', 'GRM').Mangd_Formatted &&
                                                        <>{this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SALTEQ', 'GRM').Mangd_Formatted} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SALTEQ', 'GRM').Enhet}</>
                                                    }
                                                    {!this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'SALTEQ', 'GRM').Mangd_Formatted && !item.isLoading &&
                                                        <>n/a</>
                                                    }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/*
                                <div className="compare-products__row compare-products__row--thick-border">
                                    <div className="compare-products__product-field">Salt (NACL)</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`saltnacl${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'NACL', 'GRM').Mangd &&
                                                        <>{this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'NACL', 'GRM').Mangd} {this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'NACL', 'GRM').Enhet}</>
                                                    }
                                                    {!this.getNaringsinfo(this.getFirstNaringsInfo(item.Naringsinfo), 'NACL', 'GRM').Mangd && !item.isLoading &&
                                                        <>n/a</>
                                                    }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                                */}
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Allergener</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`allergener${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Allergener && item.Allergener.map((item, i) => <span key={`allergen${i}`}>{item.Allergen} ({item.NivakodText})<br /></span>)}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                 
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Märkning </div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`markning${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Markningar && item.Markningar.map((item, i) => <span key={`markningsub${i}`}>{item.Typ}<br /></span>)}
                                                    {item.Markningar && item.Markningar.length === 0 && !item.isLoading && <>n/a</>}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                          
                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">Ursprung 
                                        <FilterTooltip heading="Ursprung">
                                            <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                        </FilterTooltip>
                                    </div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`ursprung${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.Ingredienser && item.Ingredienser.map((item, x) => {
                                                        return (
                                                            <div key={`ursprungouter${i}}`}>
                                                                {item.Beskrivning} (Ingrediensandel: {item.Andel}%)
                                                                {item.Ursprung && 
                                                                    <ul className="compare-products__content-list">
                                                                        {item.Ursprung.map((ursprung, y) => {
                                                                            return (
                                                                                <li key={`ursprunginnner${y}`}>{ursprung.Ursprungstyp && ursprung.Ursprungstyp.Beskrivning && ursprung.Ursprungstyp.Beskrivning.trim() }: {ursprung.Ursprungsland}</li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                    
                                                    {(!item.Ingredienser || item.Ingredienser.length === 0) && !item.isLoading &&
                                                        <>n/a</>
                                                    }
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="compare-products__row">
                                    <div className="compare-products__product-field">UHM-kriterier</div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div key={`uhm${i}}`} className="compare-products__product">
                                                <div className="compare-products__product-content">
                                                    {item.MSRKritierier && item.MSRKritierier.map((kriterie, x) => {
                                                        return (
                                                            <React.Fragment key={`kriterie${x}`}>
                                                                {kriterie.Beskrivning} <a href={kriterie.Lank}>{kriterie.LankNamn}</a><br />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <div className="compare-products__product-loading"></div>
                                            </div>
                                        );
                                    })}
                                </div>
                                        
                                <div className="compare-products__row compare-products__row--product-sheet">
                                    <div className="compare-products__product-field"></div>
                                    {this.state.compare && this.state.compare.map((item, i) => {
                                        return (
                                            <div  key={`link${i}}`}  className="compare-products__product">
                                                <a href={`/productsheet/${item.GTIN}`} className="button button-large" target="_blank" title="Visa produktblad">Visa produktblad</a>
                                            </div>
                                        );  
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
};

export default withRouter(Compare);