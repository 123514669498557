import React, { Component } from 'react';
import classNames from 'classnames';
import closeBtn from '../../images/close-button.svg';
import { setCookie } from '../../helpers/cookie';
import './styles.scss';

class CookieBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hide: false
        };
    }

    handleClick(e) {
        e.preventDefault();
        setCookie('cookie_consent', 'true');
        this.setState({
            hide: true
        })
    }
    
    render() {
        const { cookie } = this.props;
        if (cookie && cookie.cookie_consent && !this.state.hide) return null;

        return (
            <div className={classNames({
                'cookie-banner': true,
                'cookie-banner--hiding': this.state.hide
            })}>
                <div>
                    Genom att surfa på Dabas godkänner du att vi använder&nbsp;
                    <a href="/" title="Cookie-policy">cookies</a>.
                </div>
                <button type="button" 
                        className="cookie-banner__close-link"
                        onClick={(e) => this.handleClick(e)}><img src={closeBtn} className="close-menu" title="Stäng menyn" /></button>
            </div>
        );
    }
};

export default CookieBanner;