import React from 'react';
import './styles.scss';

const Onboard = (props) => {
    return (
        <section className="onboard">
            <div className="onboard__wrapper">
                <div className="onboard__wrapper-inner">
                    <h1>{props.heading}</h1>
                    <p className="onboard__preamble">
                        {props.preamble}
                    </p>
                    <div className="onboard__benefits-wrapper">
                        {props.benefits.map((item, i) => {
                            return (
                                <div key={i} className="onboard__benefit">
                                    <i className="checkmark-icon"></i>
                                    <div className="onboard__content">
                                        <h3>{item.heading}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>


            </div>
        </section>
    );
};

export default Onboard;