import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Facets = (props) => {

    return (
        <div className="newsroom-facets">
            <div className="newsroom-facets__container">
                <ul className="newsroom-facets__list">
                    <li>Filter:</li>
                    <li><a  
                        className={classNames({
                            'newsroom-facets__list-item': true,
                            'newsroom-facets__list-item--selected': !props.currentFacet,
                        })}
                        href={props.slug}>Alla</a></li>
                    {props.facets && props.facets.map((facet, i) => {
                        return (
                            <li key={`facet${i}`}>
                                <a  href={`${props.slug}/?ulident=${facet.ULIDENT}`}
                                    className={classNames({
                                        'newsroom-facets__list-item': true,
                                        'newsroom-facets__list-item--selected': facet.ULIDENT === props.currentFacet,
                                    })}>{facet.UppgfitslamnareName}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Facets;