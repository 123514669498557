import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import classNames from 'classnames';
import Watcher from '../watch';
import WatchBanner from '../watch/banner';
import FilterBar from './filterbar';
import { DASHBOARD_URL, URL } from '../../config';
import './styles.scss';

class SearchFilter extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
        this.state = {
            isOpen: false,
            isWatchDialogOpen: false,
            step: null
        };
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(e) {        
        if (this.wrapperRef && (!this.wrapperRef.contains(e.target) && e.target.className !== 'ingredients__ingredient button-outline button-small')) {
            if (this.state.isOpen) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }
    
    toggleFilterBar() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {

        document.addEventListener('click', this.handleClickOutside);

        const { location } = this.props;
        const query = qs.parse(location.search);

        if (query.createnew) {
            this.toggleWatchDialog('new');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!window) return;

        if (this.state.isOpen && !prevState.isOpen) {
            window.document.body.style.overflow = 'hidden';
        }

        if (!this.state.isOpen && prevState.isOpen) {
            window.document.body.style.overflow = 'auto';
        }
    }

    toggleStep(step) {
        this.setState({
            step
        });
    }

    toggleWatchDialog(step) {
        this.setState({
            isWatchDialogOpen: !this.state.isWatchDialogOpen,
            step: step
        });
    }

    ensureAuthenticated() {
        const { user, location } = this.props;
        if (user && user.isAuthenticated) {
            this.toggleWatchDialog('new');
        } else {

            let query = location.search;
            if (query) {
                query = query + '&createnew=true';
                
            } else {
                query = '?createnew=true';
            }

            window.location.href = `${DASHBOARD_URL}/Login.aspx?ReturnUrl=${URL}${location.pathname}${encodeURIComponent(query)}`;
        }
    }
    
    render() {

        const { location } = this.props;
        const query = qs.parse(location.search);

        return (
            <div ref={this.setWrapperRef}>
                <FilterBar isOpen={this.state.isOpen} 
                           toggleFilterBar={() => this.toggleFilterBar()} 
                           filters={this.props.filters} 
                           results={this.props.results}
                           isLoading={this.props.isLoading} 
                           numOfFiltersApplied={(filter) => this.props.numOfFiltersApplied(filter)} />
                <div className="filter-buttons">
                    <button className="filter-buttons__filter-trigger button-outline filter-buttons__button-small" 
                            onClick={() => this.toggleFilterBar()} 
                            title="Förfina sökning"
                            type="button">
                        <span className="hide-on-mobile">Filtrera</span>
                        <span className={classNames({
                            'filter-buttons__filter-counter': true,
                            'filter-buttons__filter-counter--has-values': this.props.currentAppliedFilters > 0,
                            'filter-buttons__filter-counter--is-changing': this.props.isChanging && this.props.currentAppliedFilters !== 0
                        })}>
                            <span>{this.props.currentAppliedFilters}</span>
                        </span>
                    </button>
    
                    {location.search &&
                        <button onClick={() => this.ensureAuthenticated()}
                        aria-expanded={this.state.isWatchDialogOpen}
                        type="button"
                        className="filter-buttons__watch-search button-outline filter-buttons__button-small"
                        title="Bevaka denna sökning - vi mailar dig när vi får nya resultat för denna sökning! Helt kostnadsfritt!"><span className="hide-on-mobile">Bevaka</span></button>
                    }
                    
                    {this.props.children}
                </div>
                {query && query.trackid &&
                    <input type="hidden" name="trackid" value={query.trackid} />
                }
                
                <WatchBanner toggleWatchDialog={(step) => this.toggleWatchDialog(step)}  />

                <Watcher trackId={query.trackid} 
                         isWatchDialogOpen={this.state.isWatchDialogOpen} 
                         step={this.state.step} 
                         toggleWatchDialog={() => this.toggleWatchDialog()}
                         toggleStep={(step) => this.toggleStep(step)} />
            </div>
        );   
    }
};

export default withRouter(SearchFilter);