import React from 'react';

const About = (newsroom) => {
    if (!newsroom.CompanyDescription) return null;
    return (
        <div className="newsroom-contact__wrapper">
            <h2>Om {newsroom.CompanyName}</h2>
            <p>
                {newsroom.CompanyDescription}
            </p>
        </div>
    );
};

export default About;