import React, { useState } from 'react';
import classNames from 'classnames';
import Naringsinformation from './naringsinformation';
import Nettoinnehall from './nettoinnehall';

const Komponent = ({Property, ...props}) => {

    const [isOpen, toggleKomponent] = useState(false);

    return (
        <section className={classNames({
            'product-sheet-box': true,
            'product-sheet-box--wide': true,
            'product-sheet-box--collapsed': !isOpen
        })}>
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading"><button onClick={() => toggleKomponent(!isOpen)} type="button" className="product-sheet-box__heading-toggle">Komponent<span className="product-sheet-box__heading-gtin"> • {props.Komponentbeskrivning} • {props.Komponentensidentitet}</span></button></h2>
                <div className="product-sheet-box__content">
                    <div className="product-sheet-box__tablecontent">
                        <h3 className="product-sheet-box__tableheading">Komponent</h3>
                        <table className="product-sheet__table product-sheet__table--striped">
                            <tbody>
                                <Property prop={props.Komponentensidentitet} text="Komponentensidentitet:" />
                                <Property prop={props.AntalAvKomponenter} text="Antal av komponenter:" />
                                <Property prop={props.Sekvensnummer} text="Sekvensnummer:" />
                                <Property prop={props.AntalForpackningarForKomponenten} text="Antal förpackningar för komponenten:" />
                                <Property prop={props.Komponentbeskrivning} text="Komponentbeskrivning:" />
                            </tbody>
                        </table>
                        {props.Produktklass &&
                            <>
                                <h3 className="product-sheet-box__tableheading">GPC PRODUKTKLASSIFICERING</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={props.Produktklass} text="Produktklass:" />
                                    </tbody>
                                </table>                           
                            </>
                        }

                        {props.Ingrediensforteckning &&
                            <>
                                <h3 className="product-sheet-box__tableheading">INGREDIENSFÖRTECKNING</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={props.Ingrediensforteckning} text="Ingrediensförteckning:" />
                                    </tbody>
                                </table>                           
                            </>
                        }

                        {props.IngrediensforteckningForIckeLivsmedel &&
                            <>
                                <h3 className="product-sheet-box__tableheading">INGREDIENSFÖRTECKNING FÖR ICKE LIVSMEDEL</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={props.IngrediensforteckningForIckeLivsmedel} text="Ingrediensförteckning för icke livsmedel:" />
                                    </tbody>
                                </table>                           
                            </>
                        }


                        {(props.Alkoholhalt || props.Argang) &&
                            <>
                                <h3 className="product-sheet-box__tableheading">ALKOHOL</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={props.Alkoholhalt} text="Alkoholhalt (volymprocent):" />
                                        <Property prop={props.Argang} text="Årgång:" />
                                    </tbody>
                                </table>                           
                            </>
                        }

                        {(props.Tillverkningsland || props.Ursprungsomrade || props.Ursprungsdeklaration) &&
                            <>
                                <h3 className="product-sheet-box__tableheading">URSPRUNG</h3>
                                <table className="product-sheet__table product-sheet__table--striped">
                                    <tbody>
                                        <Property prop={props.Tillverkningsland} text="Tillverkningsland:" />
                                        <Property prop={props.Ursprungsomrade} text="Ursprungsområde:" />
                                        <Property prop={props.Ursprungsdeklaration} text="Ursprungsdeklaration:" />
                                    </tbody>
                                </table>                           
                            </>
                        }

                        {props.Naringsinfo &&
                            <Naringsinformation {...props} isBox={false} />
                        }

                        <Nettoinnehall {...props} Property={(props) => Property(props)} />

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Komponent;