import React from 'react';
import './styles.scss';

const Spinner = (props) => {
    return (
        <span className="spinner">
            <span className="bounce1"></span>
            <span className="bounce2"></span>
            <span className="bounce3"></span>
        </span>
    );
};

export default Spinner;