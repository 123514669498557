import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import classNames from 'classnames';
import qs from 'query-string';
import './styles.scss';

class Sort extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            height:'0px',
            value: this.getDefaultSorting()
        };
       
        this.sort = React.createRef();
    }

    getDefaultSorting() {
        if (this.props.location.search === '') return '';

        const query = qs.parse(this.props.location.search);

        if (query.sort) return query.sort;

        return '';
    }

    toggleSort() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleChange(e) {
        this.setState({value: e.target.value});

        setTimeout(() => {
            this.setState({
                isOpen:false
            });
        }, 200);

        e.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isOpen && !prevState.isOpen) {
            this.setState({
                height: `${this.sort.current.scrollHeight}px`
            });
        } 

        if (!this.state.isOpen && prevState.isOpen) {
            this.setState({
                height: '0px'
            });
        }
    }

    getText() {
        switch(this.state.value) {
            case '':
                return 'Relevans';
            case 'latest':
                return 'Nyaste';
            case 'artikelkategori':
                return 'Benämning A-Ö';
            case 'varumarke':
                return 'Varumärken A-Ö';
            case 'artikelnummer':
                return 'Artikelnummer';
            case 'gtin':
                return 'GTIN';
            case 'produktbild':
                return 'Med bild först';
        }
    }
    
    render() {
        return (
            <div className={classNames({
                'sort': true,
                'sort--is-open': this.state.isOpen
            })}>
                <button type="button" onClick={() => this.toggleSort()} className="sort__sorting-button button-outline button-small">
                    <span className="search-sorting-picked-text">{this.getText()}</span>
                </button>
                <div ref={this.sort} className="sort__options" style={{height:this.state.height}}>
                    <div className="sort__option" data-value="Relevans">
                        <input type="radio" id="sort-relevans" name="sort" value="" checked={this.state.value === ''} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-relevans">Relevans</label>
                    </div>
                    <div className="sort__option" data-value="Nyaste">
                        <input type="radio" id="sort-nyaste" name="sort" value="latest" checked={this.state.value === 'latest'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-nyaste">Nyaste</label>
                    </div>
                    <div className="sort__option" data-value="Benämning A-Ö">
                        <input type="radio" id="sort-benamning" name="sort" value="artikelkategori" checked={this.state.value === 'artikelkategori'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-benamning">Benämning A-Ö</label>
                    </div>
                    <div className="sort__option" data-value="Varumärken A-Ö">
                        <input type="radio" id="sort-varumarken" name="sort" value="varumarke" checked={this.state.value === 'varumarke'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-varumarken">Varumärken A-Ö</label>
                    </div>
                    <div className="sort__option" data-value="Artikelnummer">
                        <input type="radio" id="sort-artikelnummer" name="sort" value="artikelnummer" checked={this.state.value === 'artikelnummer'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-artikelnummer">Artikelnummer</label>
                    </div>
                    <div className="sort__option" data-value="GTIN">
                        <input type="radio" id="sort-gtin" name="sort" value="gtin" checked={this.state.value === 'gtin'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-gtin">GTIN</label>
                    </div>
                    <div className="sort__option" data-value="Med bild först">
                        <input type="radio" id="sort-image" name="sort" value="produktbild" checked={this.state.value === 'produktbild'} onChange={(e) => this.handleChange(e)} />
                        <label className="sort__option-label" htmlFor="sort-image">Med bild först</label>
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(Sort);