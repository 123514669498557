import React from "react";

const AnchorLinks = (props) => {
    if (!props.blocks) return null; 
    
    if (props.blocks.filter(block => block.attrs.blockanchor && block.attrs.blockanchor !== '').length === 0) return null;
  
    return (
        <>
            <h3>{props.heading}</h3>
            <div className="marketing-usages">
                {props.blocks.map((block, i) => {
                    if (block.attrs.blockanchor && block.attrs.url) {
                        return <a key={i} href={block.attrs.url} title={block.attrs.heading}>{block.attrs.heading}</a>
                    }

                    if (block.attrs.blockanchor) {
                        return <a key={i} href={`#${block.attrs.blockanchor}`} title={block.attrs.heading}>{block.attrs.heading}</a>
                    }
                    
                    return null;
                })}
            </div>
        </>
    );
};

export default AnchorLinks;