import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import NewsList from '../../components/newslist';
import Paginate from '../../components/paginate';
import { URL } from '../../config';
import Facets from '../../components/facets';
import './styles.scss';
const DEFAULT_PAGINATION_SIZE = 20;

class News extends Component {

    state = {
        isLoading: false,
        latestNews: this.props.initialData && this.props.initialData.latestNews
    }

    static async getLatestNews(ulident, page, useAbsoluteUrl = true) {
        let skip = 0;
        if (page && page > 0) {
            skip = (page-1)*DEFAULT_PAGINATION_SIZE;
        }

        try {  
            const res = await fetch(useAbsoluteUrl ? `${URL}/api/v1/news/FetchNews?top=20&skip=${skip}&ulident=${ulident}` : `/api/v1/news/FetchNews?top=20&skip=${skip}&ulident=${ulident}`);          
          if (!res.ok) {
            throw new Error(res.statusText);
        }
        
        return res.json();

    
        } catch (err) {
          console.log(err);
          return {};
        }
        
    }

    static async requestInitialData(routes, req, res, cache, agent) { 
        //Get latest news
        /*let latestNews = pageCache.get(`ALLNEWS_${req.originalUrl}`);
        if (!latestNews) {
          console.log('cache miss ', `ALLNEWS_${req.originalUrl}`);
          latestNews = await News.getLatestNews();
          pageCache.set(`ALLNEWS_${req.originalUrl}`, latestNews, 7200);
        }*/
        let latestNews = await News.getLatestNews(req.query.ulident, req.query.page);   
        
        return {
          latestNews
        };    
    }

    getPage() {
        const query = qs.parse(this.props.location.search);
        if (!query.page) {
            query.page = 1;
        } 

        return query.page*1 + 1;
    }
    
    handleLoadMore() {
        const query = qs.parse(this.props.location.search);
        query.page = this.getPage();
        return `${this.props.location.pathname}?${qs.stringify(query)}`;
    }

    async componentDidUpdate(prevProps) {
        const prevQuery = qs.parse(prevProps.location.search);
        const currentQuery = qs.parse(this.props.location.search);
        
        if (currentQuery.page && prevQuery.page !== currentQuery.page) {
            
            this.setState({
                isLoading: true
            });
            
            const results = await News.getLatestNews(currentQuery.ulident, currentQuery.page, false);
            this.setState({
                latestNews: {
                    ...this.state.latestNews,
                    news: [...this.state.latestNews.news, ...results.news]
                },
                isLoading: false
            });
        }
    }
    
    render() {
        if (!this.props.initialData) return null;

        const { latestNews } = this.state;
        const query = qs.parse(this.props.location.search);    
        const facets = [...latestNews.facets].sort((objA, objB) => objA.UppgfitslamnareName.localeCompare(objB.UppgfitslamnareName, 'sv'));

        return (
            <div className="news">
                <div className="news__header">
                    <div className="news__header-content">
                        <h1 className="news__heading">Branschnyheter från Dabas Newsroom</h1>
                        <Facets facets={facets} slug="/news" currentFacet={query.ulident} />
                    </div>
                </div>
                
                <NewsList useLogo={true} newsList={latestNews.news} mapper={(newsItem) => {
                    const { Uppgiftslamnare, Link, ...rest } = newsItem;
                    return {
                    Link,
                    logo: Uppgiftslamnare.Image,
                    ...rest,
                    Uppgiftslamnare
                    };
                }} />
                
                {latestNews.news && (this.getPage()-1)*DEFAULT_PAGINATION_SIZE <= latestNews.totalHits &&
                    <div className="news__footer">
                        <div className="news__footer-content">
                            <Paginate to={this.handleLoadMore()} isLoading={this.state.isLoading} />
                        </div>
                    </div>
                    
                }
            </div>
        );
    }
};

export default withRouter(News);