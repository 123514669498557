//WordPress Glue
import MarketersInfo from '../components/marketersinfo';
import MarketersIntro from '../components/marketersintro';
import Quote from '../components/quote';
import MarketersCustomersTagline from '../components/marketers-customers-tagline';
import Hero from '../components/hero'; 
import Onboard from '../components/onboard';
import Pricing from '../components/pricing';
import Usp from '../components/usp';
import Newsbanner from '../components/newsbanner';

const blocks = [];
blocks['dabas/block-content-block'] = {
    component: MarketersInfo
};
blocks['dabas/block-intro-block'] = {
    component: MarketersIntro
}
blocks['dabas/block-quote-block'] = {
    component: Quote
}
blocks['dabas/block-tagline-block'] = {
    component: MarketersCustomersTagline
}
blocks['dabas/block-tagline-block'] = {
    component: MarketersCustomersTagline
}
blocks['dabas/block-hero-block'] = {
    component: Hero
}
blocks['dabas/block-onboard-block'] = {
    component: Onboard
}
blocks['dabas/block-pricing-block'] = {
    component: Pricing
}
blocks['dabas/block-usp-block'] = {
    component: Usp
}
blocks['dabas/block-news-banner-block'] = {
    component: Newsbanner
}

export default blocks;