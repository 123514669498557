import React, { Component } from 'react';
import classNames from 'classnames';
import { URL } from '../../config';
import './styles.scss';

class Categories extends Component {
    static async getLatestDocuments() {
        try {
          const res = await fetch(`${URL}/api/v1/search/categories`, {
            method: 'GET'
          });
    
          return res.json();
        } catch (err) {
            console.log(err);
            const err500 = new Error(err);
            err500.status = 500;
            throw err500;
        }
      }
    
      static async requestInitialData(routes, req, res, cache) {
        //Get search
        let latestDocuments = cache && cache.get(`SEARCH_CATEGORY_TOP`);
        
        if (!latestDocuments) {
          latestDocuments = await Categories.getLatestDocuments();

          if (cache) {
            cache.set(`SEARCH_CATEGORY_TOP`, latestDocuments, 7200);
          }
          
        }
        
        return {
          search: latestDocuments
        };    
    }

    render() {

        if (!this.props.initialData) return null;

        const { search } = this.props.initialData;

        return (
            <section className={classNames({
                'explore-products': true,
                'force-full-height': true,
                'gray-background': true,
                'explore-products--no-header': this.props.hideHeader
            })}>
                <div className="explore-products__fit-content">
                    <div className="explore-products__content-padding">
                        {!this.props.hideHeader &&
                            <>
                                <h1>Utforska produkter</h1>
                                <span className="product-count">{search.TotalRecords.toLocaleString('en-US').replace(',', ' ')} produkter</span>
                            </>
                        }
                        
                        <div className={classNames({
                            'options': true,
                            'options--no-header': this.props.hideHeader
                        })}>
                            {search && search.Facets && search.Facets.map((facet, i) => {
                                return facet.FacetValues && facet.FacetValues.map((val, i) => {                                    
                                    return (
                                        <a  key={`cat${i}`}
                                            href={`/categories${val.FriendlyUrl}/`}
                                            title="Utforska kategorin Djupfryst"
                                            className="option">
                                                <i className={classNames({
                                                    'category-icon': true,
                                                    'small': true,
                                                    'frozen-food': val.Label === 'Djupfryst',
                                                    'fresh-food': val.Label === 'Färskvaror/Kylvaror',
                                                    'colonial-food': val.Label === 'Kolonial/Speceri',
                                                    'non-food': val.Label === 'Non food'
                                                })}></i>
                                                <div className="category-wrapper">
                                                    <div className="category">
                                                        {val.Label}
                                                    </div>
                                                    <div className={classNames({
                                                        'category-product-count': true,
                                                        'frozen-food': val.Label === 'Djupfryst',
                                                        'fresh-food': val.Label === 'Färskvaror/Kylvaror',
                                                        'colonial-food': val.Label === 'Kolonial/Speceri',
                                                        'non-food': val.Label === 'Non food'
                                                    })}>
                                                         {val.Count} produkter
                                                    </div>
                                                </div>
                                            </a>
                                    );
                                })
                            })}                          
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default Categories;