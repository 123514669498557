import {    DABAS_API_BASE,
            API_KEY } from '../../config';
export const DABAS_NEWSROOM_API = (slug) => {
    return `${DABAS_API_BASE}newsroom/uppgiftslamnare/${slug}/JSON?apikey=${API_KEY}`;
}
export const DABAS_NEWSROOM_NEWS_API = (ulident, mediaPath, slug) => {
    return `${DABAS_API_BASE}newsroom/news/${ulident}/${mediaPath}/${slug}/json?apikey=${API_KEY}`;
}

export const filterProductStories = (newsroom) => {
    return newsroom.News.filter(f => f.Articles && f.Articles.length > 0).filter((f, i) => i < 3).map(item => {
        return {
            ...item,
            ImageLink: `${item.ImageLink}`
        }
    });
}