import React from 'react';
import { format } from "date-fns";
import { sv } from 'date-fns/locale'
import './styles.scss';


const Img = (props) => {
    if (props._embedded['wp:featuredmedia']) {
        const img = props._embedded['wp:featuredmedia'][0];
        return (
            <div className="blog-items__item-image--no-image">
                <div className="blog-items__item-image-date">
                    <span className="blog-items__item-image-date-day">{format(new Date(props.date),'dd')}</span>
                    <span className="blog-items__item-image-date-month">{format(new Date(props.date),'MMMM', {locale: sv})}</span>
                </div>
                <img className="blog-items__item-image"  src={img.media_details.sizes.medium_large.source_url} alt={img.alt_text} />
            </div>
        );
    } else {
        return (
            <div className="blog-items__item-image--no-image">
                <div className="blog-items__item-image-date">
                    <span className="blog-items__item-image-date-day">{format(new Date(props.date),'dd')}</span>
                    <span className="blog-items__item-image-date-month">{format(new Date(props.date),'MMMM', {locale: sv})}</span>
                </div>
            </div>
        );
    } 
}

const Post = (props) => {
    return (
        <a href={`/blog/${props.slug}`}
           className="blog-items__item"
           title={props.title.rendered}>
            <Img {...props} />
            <div className="blog-items__item-date">
                {format(new Date(props.date),'yyyy-MM-dd')}
            </div>
            <h2 className="blog-items__item-heading">{props.title.rendered}</h2>
        </a>
    ); 
};

const Posts = (props) => {    
    return (
        <> 
            <div className="blog-items__posts">
                {props.posts.map((post, i) => {
                    return <Post key={i} {...post} />;
                })}
            </div>
        </>
    );
};

export default Posts;