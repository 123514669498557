import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Post from '../../components/post';
import Story from '../../components/story/story';

const PreviewItem = props => {
    
    const [localProps, setLocalProps] = useState(null);

    useEffect(() => {
        window.onmessage = function(e) {
            if (e.data && e.data.type === 'UPDATE') {
                setLocalProps(e.data.props);
            }
        };

        window.top.postMessage({ type: 'READY'}, '*');
    }, []);
    
    if (!localProps) {
        return null;
    }

    const createContent = (content) => {
        if (!content) return null;

        let cleanText = content;
        cleanText = cleanText.replace(/<p><br><\/p>/gi, '').replace('/<p><\/p>/gi', '').replace('/<br><br>/gi', '');

        return ({
            innerHTML: cleanText
        }); 
    }

    const createPreamble = (preambleRaw) => {
        if (!preambleRaw) return null;

        const preamble = <p className="post__preamble">{preambleRaw }</p>;

        return ({
            innerHTML: ReactDOMServer.renderToStaticMarkup(preamble)
        });
    }

    const isValidDate = stringDate => {
        const timestamp = Date.parse(stringDate);
        return isNaN(timestamp) === false;
    };

    const zoom = () => {
        var element = document.documentElement;
        element.classList.toggle('zoom');
    };

    const date = isValidDate(localProps.data.PublishDate) ? localProps.data.PublishDate : Date.now();
    const isStory = localProps.data.NewsType && localProps.data.NewsType === 'NEWSSTORY' ? true : false;

    if (isStory) {
        return (
            <>
                <Story {...localProps.data} 
                    heading={localProps.data.Header} 
                    preambleBlock={[createPreamble(localProps.data.Introduction)]} 
                    postBlocks={[createContent(localProps.data.News)]}
                    date={date} 
                    image={{
                        src: localProps.image ?  URL.createObjectURL(localProps.image[0]) : localProps.data.Image && `${localProps.data.Image.Url}`,
                        alt: localProps.data.Header
                    }} 
                />
                <div className="preview-lock" onClick={() => {
                    zoom();
                }}></div>
            </>
        );
    } else {
        return (
            <>
                <Post heading={localProps.data.Header} 
                  preambleBlock={[createPreamble(localProps.data.Introduction)]} 
                  postBlocks={[createContent(localProps.data.News)]}
                  date={date} 
                  image={{
                    src: localProps.image ?  URL.createObjectURL(localProps.image[0]) : localProps.data.Image && `${localProps.data.Image.UrlLarge}`,
                    alt: localProps.data.Header
                }}  />
                <div className="preview-lock" onClick={() => {
                    zoom();
                }}></div>
            </>
        );
    }
} ;

export default PreviewItem;