import React, { Component } from 'react';
import Page from '../../hoc/page';
import RenderBlocks from '../../blocks';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';

class Content extends Component {
    constructor(props) {
        super(props);
    }

    static async requestInitialData(routes, req, res, cache, agent) {

    }
    
    render() {
        if (!this.props.initialData) return null;

        const { params } = this.props;

        return (
            <> 
                {this.props.initialData.parent  > 0 && params && params.section && <Breadcrumb url={`/${params.section}`} link_text="Tillbaka" /> }
                <RenderBlocks blocks={this.props.initialData.blocks} />
                <Footer {...this.props} />
            </>
        );  
    }
};

export default Page(Content);