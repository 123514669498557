import React from 'react';
import Logo from '../logo';
import Menu from '../menu';
import Login from '../login';

const Header = (props)  => {
    if (props.hiddenHeader) return null;

    const { user } = props.initialData;

    return (
        <header className="header">
            <div className="header__pad">
                <div className="header__left">
                    <a href="/search" className="header__link header__search-link" title="Sök & utforska">
                        <i className="header__search-icon"></i><span>Sök & utforska</span>
                    </a>
                    <a href="/news" className="header__link header__search-link" title="Sök & utforska">
                        <i className="header__newsroom-icon"></i><span>Newsroom</span>
                    </a>
                    <Menu {...props.initialData} />
                </div>
                <Logo />
                <div className="header__right">
                    {!user.isAuthenticated &&
                        <a href="/onboard" className="header__link button button-small" title="Kom igång">Kom igång</a>
                    }
                    <Login modifiers={{ 'header__link': true }} {...props.initialData} />
                </div>
            </div>
        </header>
    ); 
}

export default Header;