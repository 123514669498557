import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import classNames from 'classnames';
import Spinner from '../../components/spinner';
import ProductCards from '../../components/productcards';
import Facets from '../../components/newsroom/facets';

const NoProducts = props => <p className="newsroom-products__no-products">{props.text}</p>
class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGridView: true,
            articles: [],
            filteredArticles: [],
            selectedFilter: null
        };
    }

    changeToGridView() {
        this.setState({
            isGridView: true
        });
    }

    changeToListView() {
        this.setState({
            isGridView: false
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.articles !== prevProps.articles) {
            this.setState({
                articles: this.props.articles,
                filteredArticles: this.props.articles
            });
        }
    }

    filterArticles(filterCriteria) {
        if (filterCriteria) {
            this.setState({
                filteredArticles: this.state.articles.filter(article => article.VGIDENT === filterCriteria),
                selectedFilter: filterCriteria
            });
        } else {
            this.setState({
                filteredArticles: this.state.articles,
                selectedFilter: filterCriteria
            });
        }
    }

    render() {

        const { newsroom } = this.props;

        return (
            <div className={classNames({
                'newsroom-products': true,
                'newsroom-products--is-loading': this.props.isLoading,
            })}>
                <div className="newsroom-products__header">
                    <div className="newsroom-products__header-inner">
                        <h2>Våra produkter</h2>
                        <Spinner />
                        {this.state.filteredArticles &&
                            <div className="newsroom-products__async">
                                <Facets facets={this.props.facets} 
                                        onFilter={(filterCriteria) => this.filterArticles(filterCriteria)} 
                                        selectedFilter={this.state.selectedFilter} />  

                                <div className="newsroom-toggle-products">
                                    <div className="newsroom-toggle-products__container">
                                        <button onClick={() => this.changeToGridView()} 
                                                title="Visa som brickvy"
                                                className={classNames({
                                                    'grid-view-toggle': true,
                                                    'grid-view-toggle--grid-view': true,
                                                    'grid-view-toggle--is-active': this.state.isGridView})}></button>
                                        <button onClick={() => this.changeToListView()} 
                                                title="Visa som listvy"
                                                className={classNames({
                                                    'grid-view-toggle': true,
                                                    'grid-view-toggle--list-view': true,
                                                    'grid-view-toggle--is-active': !this.state.isGridView})}></button>
                                    </div>
                                </div>
                            </div>
                        }
                        {!this.state.filteredArticles && !this.props.isLoading &&
                            <NoProducts text={`${newsroom.CompanyName} har inga publicerade produkter 🧐`}/>
                        }
                    </div>
                </div>
                
                {this.state.filteredArticles &&
                    <ProductCards modifiers={{
                                'product-cards--gray-background': true,
                                'product-cards--newsroom': true
                              }}
                              useSlider={false} 
                              isGridView={this.state.isGridView}
                              search={{
                                SearchResults: this.state.filteredArticles
                              }} />
                }
            </div>
        );
        
    }
};

export default withRouter(Products);