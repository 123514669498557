import React from 'react';
import classNames from 'classnames';
import './facets.scss';

const Facets = (props) => {
    return (
        <div className="newsroom-facets">
            <div className="newsroom-facets__container">
                <ul className="newsroom-facets__list">
                    <li>Filter:</li>
                    <li><button className={classNames({
                        'newsroom-facets__list-item': true,
                        'newsroom-facets__list-item--selected': !props.selectedFilter,
                    })} type="button" onClick={() => props.onFilter(null)}>Alla</button></li>
                    {props.facets && props.facets.map((facet, i) => {
                        if (parseInt(facet.Code) === 0) return null;
                        
                        return (
                            <li key={`facet${i}`}>
                                <button className={classNames({
                                            'newsroom-facets__list-item': true,
                                            'newsroom-facets__list-item--selected': props.selectedFilter === parseInt(facet.Code),
                                        })} type="button" onClick={() => props.onFilter(parseInt(facet.Code))}>{facet.LabelCount}</button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Facets;