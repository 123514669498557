import React from 'react';
import classNames from 'classnames';
import FilterTooltip from '../../components/searchfilter/filtertooltip';
import wave from '../../images/wawe.png';
const images = require.context('../../images/flags', true);

const Ursprung = (product) => {
    let type = null;
    
    const getImgage = (code) => {
        try {
            const img = images(`./${code}.svg`);
            return img;
        } catch (err) {
            return null;
        }
    };

    return (
        <>
        {((product.Tillverkningslander && product.Tillverkningslander.length > 0) || (product.Ingredienser && product.Ingredienser.length > 0) || product.T4202_LokaltUrsprung) && 
            <section className="product-sheet-box origin">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Ursprung</h2>

                    {product.Tillverkningslander && product.Tillverkningslander.length > 0 &&
                        <>
                            {product.Tillverkningslander.map((country, i) => {
                                return (
                                    <div key={`Tillverkningslander${i}`} className="origin__country">
                                        <h3 className="origin__country-heading">
                                            Tillverkningsland:
                                            <FilterTooltip heading="Tillverkningsland">
                                                <p>Tillverkningsland i betydelsen det land i vilket produkten genomgick den sista väsentliga bearbetningen (motsvarande lagstiftningens definition för ursprungsland).</p>
                                            </FilterTooltip>
                                        </h3>
                                        <div>{country.Land}</div>
                                        {getImgage(country.Kod) && 
                                            <div className="origin__country-flag"><img src={getImgage(country.Kod)} alt={country.Land} /></div>
                                        }
                                    </div>
                                );
                            })}
                        </>
                    }

                    {((product.Ingredienser && product.Ingredienser.length > 0) || product.T4202_LokaltUrsprung) &&
                        <div className={classNames({
                            'origin__ingredients': true,
                            'origin__ingredients--has-ingredients': (product.Ingredienser && product.Ingredienser.length > 0),
                        })}>
                            {product.Ingredienser && product.Ingredienser.map((ingr, i) => {
                                return (
                                    <div key={`ingr${i}`}> 
                                        {ingr.Beskrivning &&
                                            <>
                                                <h3 className="origin__ingredients-heading">
                                                    Råvaruursprung:
                                                    <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                        <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                                    </FilterTooltip>
                                                </h3>
                                                <h4 className="origin__ingredients-heading origin__ingredients-heading--thin">
                                                    {ingr.Beskrivning}
                                                </h4>
                                            </>
                                        }
                                        {ingr.Andel > 0 &&
                                            <span className="origin__ingredients-country">Ingrediensandel: {ingr.Andel}%</span>
                                        }
                                        
                                        {ingr.Ursprung &&
                                            <div className={classNames({
                                                'origin__ursprungstyp': true,
                                                'origin__ursprungstyp--align-left': ingr.Ursprung.length === 1
                                            })}>
                                                
                                                {ingr.Ursprung.map((ursprung, i) => {
                                                    let elmClass = classNames({
                                                        'origin__ursprungstyp-country': true,
                                                        'origin__ursprungstyp-country--new-type': ingr.Ursprung[i + 1] &&  ingr.Ursprung[i + 1].Ursprungstyp.Beskrivning !== ursprung.Ursprungstyp.Beskrivning
                                                    });

                                                    type = ursprung.Ursprungstyp.Beskrivning;

                                                    return (
                                                        <div key={`ursprung${i}`} className={elmClass}>
                                                            <div className="origin__ursprungstyp-heading">{ursprung.Ursprungstyp.Beskrivning}</div>
                                                            <div className="origin__ursprungstyp-value">{ursprung.Ursprungsland}</div>
                                                            
                                                            <div className="origin__ursprungstyp-flag">
                                                                {ursprung.UrsprungslandKod && getImgage(ursprung.UrsprungslandKod) && 
                                                                    <img src={getImgage(ursprung.UrsprungslandKod)} alt={ursprung.Ursprungsland} />
                                                                }
                                                                {!ursprung.UrsprungslandKod && 
                                                                ursprung.Ursprungstyp && 
                                                                ursprung.Ursprungstyp.Beskrivning &&
                                                                ursprung.Ursprungstyp.Beskrivning.trim() === 'Fångstzon' &&
                                                                    <img className="origin__fishingarea" src={wave} alt="" />
                                                                }    
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }

                                        
                                    </div>
                                );
                            })}

                            {product.T4202_LokaltUrsprung &&
                                <div className="origin__ursprungstyp-omrade">
                                    <h3 className="origin__ingredients-heading">
                                        Ursprungsområde:
                                        <FilterTooltip heading="Ursprungsområde">
                                            <p>Geografiskt område som produkten kommer ifrån i fritext.</p>
                                        </FilterTooltip>
                                    </h3>
                                    <span>{product.T4202_LokaltUrsprung}</span>
                                </div>
                            }

                            {product.Ursprungsdeklaration &&
                                <div className="origin__ursprungstyp-omrade">
                                    <h3 className="origin__ingredients-heading">
                                    Ursprungsdeklaration:
                                        <FilterTooltip heading="Ursprungsdeklaration">
                                            <p>Fritextfält som anger den fullständiga informationen om en produkts geografiska ursprung enligt tillämpliga definitioner och föreskrifter.</p>
                                        </FilterTooltip>
                                    </h3>
                                    <span>{product.Ursprungsdeklaration}</span>
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>
            }
        </>
    );
};

export default Ursprung;