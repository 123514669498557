import React from 'react';
import './styles.scss';

const Quote = (props) => {

    return (
        <section className="quote">
            <div className="quote__wrapper">
                <div className="quote__wrapper-inner">
                    <div className="quote__fact">
                        <div className="quote__fact-percentage">{props.stats}</div>
                        <div className="quote__fact-text">
                            <p>
                               {props.stats_text}
                            </p>
                        </div>
                    </div>
                    <div className="quote__text">
                        <p className="quote__preamble">{props.quote}</p>
                        <div className="quote__author">
                            {props.img && <img src={props.img.medium ? props.img.medium.url : props.img.full.url} alt={props.img.alt} />}
                            <div className="quote__author-details">
                                <span className="quote__author-name">{props.cited}</span>
                                <span className="quote__author-title">{props.organisation}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Quote;