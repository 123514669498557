import React, { Component } from 'react';

import {
    useLocation,
    useNavigate,
    useParams,
  } from 'react-router-dom';
  
export const withRouter = (WrappedComponent) => {

    const Container = props => {

        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        
        return (
            <WrappedComponent
                {...props}
                location={location}
                navigate={navigate}
                params={params} />
        );
    };

    if (WrappedComponent.requestInitialData) {
        Container.requestInitialData = WrappedComponent.requestInitialData;
    }

    return Container;
};