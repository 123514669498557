import React from 'react';
import './styles.scss';

const Video = (props) => {
    
    const createEmbedLink = url => {
        if (url.indexOf('/user/') > -1) {
            return `https://www.youtube.com/embed?controls=1&modestbranding=1&showinfo=1&rel=0&listType=user_uploads&list=${url.replace('https://www.youtube.com/user/', '')}`;
        } 
        if (url.indexOf('/channel/') > -1) {
            return `https://www.youtube.com/embed/videoseries?list=UU${url.replace('https://www.youtube.com/channel/UC', '')}`;
        }

        if (url.indexOf('playlist') > -1) {
            return `https://www.youtube.com/embed/videoseries?list=${url.replace('https://youtube.com/playlist?list=', '')}`;
        }

        if (url.indexOf('https://youtu.be/') > -1) {
            return `https://www.youtube.com/embed/${url.replace('https://youtu.be/', '')}`;
        }

        if (url.indexOf('/embed/') > -1) {
            return url;
        }
    };

    if (props.videoUrl) {
        return (
            <section className="video">
                <div className="video__content">
                    <div className="video__content-inner">
                        <h2>Senast på YouTube</h2>
                        <div className="video__container">
                            <iframe width="1140" height="641" src={createEmbedLink(props.videoUrl)} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        {props.videoLink &&
                            <a href={props.videoLink} className="video__link">Fler videoklipp</a>
                        }
                    </div>
                </div>
                
            </section>
        );
    }

    return null;
    
}

export default Video;