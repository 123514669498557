export const parseCookie = strCookie => {
    if (strCookie) {
        const values = strCookie.split(';').reduce((res, item) => {
            const data = item.trim().split('=');
            return { ...res, [data[0]]: data[1] };
        }, {});
    
        return values;
    }

    return {};
};

export const setCookie = (cookieKey, cookieValue, expirationDays) => {
    let expiryDate = '';
  
    if (expirationDays) {
      const date = new Date();
  
      date.setTime(`${date.getTime()}${(expirationDays || 30 * 24 * 60 * 60 * 1000)}`);
  
      expiryDate = `; expiryDate=" ${date.toUTCString()}`;
    }
  
    document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
}