import React, { Component } from 'react';
import { DASHBOARD_URL, URL } from '../config';

export default (WrappedComponent, ReturnUrl) => {
    return class Auth extends Component {

        constructor(props) {
            super(props);
        }

        static async requestInitialData(routes, req, res, cache, agent) { 
            return {
                isAuth: true,
                returnUrl: ReturnUrl
            };    
        }

        componentDidMount() {
            const { initialData } = this.props;

            if (initialData.user && !initialData.user.isAuthenticated) {
                if (ReturnUrl) {
                    if (typeof window !== 'undefined') {
                        window.location.replace(`${DASHBOARD_URL}/Login.aspx?ReturnUrl=${ReturnUrl}`);
                    }
                    
                } else {
                    if (typeof window !== 'undefined') {
                        window.location.replace(`${DASHBOARD_URL}/Login.aspx`);
                    }
                }
            }
        }

        render() {
            const { initialData } = this.props;
            if (initialData.user && initialData.user.isAuthenticated) {
                return  <WrappedComponent {...this.props} />
            }

            return null;
            
        }
    }
};