export const URL = process.env.REACT_APP_URL || 'http://next.dabas.com:8080'; 
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL || 'https://dashboardqa.dabas.com'; //'http://dev.dabas.com'; //'http://dashboard.dabas.local';//'http://dashboard.dabas.local';  //'https://dashboardqa.dabas.com'; 
export const WP_API_URL = process.env.REACT_APP_WP_API_URL || 'https://wp-dabas-com.azurewebsites.net/wp-json/wp/v2/';//'http://dabaswp.local/wp-json/wp/v2/';
export const AZURE_STORAGE = process.env.REACT_APP_AZURE_STORAGE || 'https://dabasqa.blob.core.windows.net/media/'
export const EXCLUDE_TAG_ID = process.env.REACT_APP_EXCLUDE_TAG_ID || 50;
export const DABAS_API_BASE = process.env.REACT_APP_DABAS_API_BASE || 'https://apiqa.dabas.com/DABASService/';//'https://apiqa.dabas.com/DABASService/'; //'https://apiqa.dabas.com/DABASService/';
export const DABAS_API_ROOT = process.env.REACT_APP_DABAS_API_ROOT || 'https://apiqa.dabas.com'; //'https://apiqa.dabas.com/DABASService/';
export const DABAS_API_VERSION = 'V2';
export const DABAS_API = process.env.REACT_APP_DABAS_API || 'https://apiqa.dabas.com/DABASService/V2/';
export const API_KEY = process.env.REACT_APP_API_KEY || 'e851a66e-2bd3-4e81-986b-b49a2429a2cb';//'086ce76a-c533-42c8-910b-cd59491b12f5';
export const DEFAULT_PAGINATION_SIZE = 100;
export const DABAS_ARTICLE_API = (gtin) => {
    return `${DABAS_API}completearticlehierarchy/gtin/${gtin}/JSON?apikey=${API_KEY}`;
}
export const DABAS_NEWSROOM_API = (slug) => {
    return `${DABAS_API_BASE}newsroom/uppgiftslamnare/${slug}/JSON?apikey=${API_KEY}`;
}
