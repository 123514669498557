import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import FilterTooltip from './filtertooltip';
import clearFilterSrc from '../../images/clear-filters-icon.svg';
import qs from 'query-string';
import './ingredients.scss';

const Ingredient = (props) => {
    return (
        <div onClick={(e) => props.removeIngredient(e, props.index)} className="ingredients__ingredient button-outline button-small" title="Ta bort ingrediens">
            {props.value} <img src={clearFilterSrc} alt="Ta bort ingrediens" />
            <input type="hidden" defaultValue={props.value} name="ingredient" />
        </div>
    );
};

class Ingredients extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            value: '',
            ingredients: this.getValuesFromQuery()
        }
    }

    getValuesFromQuery() {
        const query = qs.parse(this.props.location.search);
        if (query && typeof query.ingredient === 'string') {
            return [{ value: query.ingredient }];
        }
        return query && query.ingredient ? query.ingredient.map(value => { return { value } }) : [];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setState({
                ingredients: this.getValuesFromQuery()
            });
        }
    }

    appendFilters(form) { 
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles:true  }));
    }

    addIngredient(e) {
        e.preventDefault();
        e.persist();
        if (this.state.value === '') {
            this.ingredientInput.focus();
            return;
        }

        this.setState({
            value: '',
            ingredients: [...this.state.ingredients, {
                value: this.state.value
            }]
        }, () => {
            this.ingredientInput.focus();
            this.appendFilters(e.target.form);
        });

        
    }

    removeIngredient(e, index) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ingredients: this.state.ingredients.filter((item, i) => i !== index)}, () => {
            const form = document.getElementsByClassName('the-search-form')[0];
            this.appendFilters(form);
        });
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    componentDidMount() {
        this.props.addControlledElements(this);
    }

    render() {
        return (
            <>
                <div className="filter-item__add-ingredients">
                    <label htmlFor="ingredients-input-field">Lägg till ingredienser
                        <FilterTooltip heading="Ingredienser">
                            <p>Sök efter artiklar med en viss ingrediens enligt innehållsförteckningen. Det går att lägga till flera ingredienser.</p>
                        </FilterTooltip>
                    </label>
                    <div>
                        <input
                            type="text" 
                            ref={(input) => { this.ingredientInput = input; }} 
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.value}
                            id="ingredients-input-field"
                            className="filter-item__ingredients-input" />
                        <button
                            onClick={(e) => this.addIngredient(e)}
                            type="button"
                            className="button button-large filter-item__add-btn">
                            Lägg till
                        </button>
                    </div>
                </div>
                <div className="ingredients">
                    {this.state.ingredients.map((ingredient, index) => <Ingredient key={index} index={index} value={ingredient.value} removeIngredient={(e, index) => this.removeIngredient(e, index)} />)}
                </div>
            </>
        );
    }
};

export default withRouter(Ingredients);