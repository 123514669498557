import React from 'react';
import ExportExcel from './excelexport';
import './styles.scss';

const CompareBar = (props) => {

    return (
        <section className="compare-bar">
            <button disabled={!props.isComparable} type="submit" className="compare-bar__link button button-small">Jämför artiklar</button>
            <ExportExcel {...props} />
        </section>
    );
};

export default CompareBar;