import React from 'react';
import { isValidImage } from '../../helpers';
import kontaktpersonPlaceholder from '../../images/contact-person-placeholder@2x.png';
import './contact-person.scss';

const ContactPerson = (newsroom) => {
    if (!newsroom.ContactPersons) return null;
    if (newsroom.ContactPersons.length === 0) return null;
    
    return (
        <section className="newsroom-contact-person">
            <div className="newsroom-contact-person__wrapper-outer">
                <h2>Kontaktpersoner</h2>
                <div className="newsroom-contact-person__wrapper">
                    {newsroom.ContactPersons && newsroom.ContactPersons.map((person, i) => {
                        return (
                            <div key={`person${i}`} className="newsroom-contact-person__person">
                                {person.ImageLink &&
                                    <img src={isValidImage(person.ImageLink) ? person.ImageLink : kontaktpersonPlaceholder} alt="Kontaktperson" />
                                }
                                {person.Name &&
                                    <h4 className="newsroom-contact-person__name">{person.Name}</h4>
                                }
                                {person.Title &&
                                    <div className="newsroom-contact-person__title">{person.Title}</div>
                                }
                                {person.Phone &&
                                    <div className="newsroom-contact-person__phone">{person.Phone}</div>
                                }
                                {person.Email &&
                                    <a href={`mailto:${person.Email}`} title={person.Name}>Skicka e-post</a>
                                }
                                {newsroom.isEditing &&
                                    <div>
                                        <button className="newsroom-contact-person__delete" type="button" type="button" onClick={() => {
                                            window.parent.postMessage({ type: 'DELETE_CONTACTPERSON', data: { kpident: person.KPIDENT }  }, '*');
                                        }}>Ta bort</button>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default ContactPerson;