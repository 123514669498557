import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Pricing = (props) => {

    return (
        <section className="pricing">
            <div className="pricing__wrapper">
                {props.heading &&
                    <h2>{props.heading}</h2>
                }
                {props.subheading &&
                    <h3 className="pricing__subheading">{props.subheading}</h3>
                }

                {(props.theader || props.tdata) &&
                    <div className="pricing__header">
                        {props.theader &&
                            <h3 className="pricing__th">{props.theader}</h3>  
                        }
                        {props.tdata &&
                            <h3 className="pricing__td">{props.tdata}</h3>  
                        }
                    </div>
                }
                <div className="pricing__options">
                    {props.pricing.map((item, i) => {
                        return (
                            <div key={i} className={classNames({
                                'pricing__option': true,
                                'pricing__option--is-heading': item.text && !item.price,
                                'pricing__option--air': !item.text && !item.price,
                            })}>
                                <div className="pricing__text">
                                    {item.text && item.price &&
                                        <>{item.text}</>
                                    }
                                    {item.text && !item.price &&
                                        <h3 className="pricing__heading">{item.text}</h3>
                                    }
                                </div>
                                <div className="pricing__price">{item.price}</div>
                            </div>
                        );
                    })}
                </div>

                <div className="pricing__footer" dangerouslySetInnerHTML={{__html: props.info}}></div>
            </div>
        </section>
    );
};

export default Pricing;