import React from 'react';
import classNames from 'classnames';
import ListItem  from './list-item';
import './styles.scss';
import './list-item.scss';

const NewsList = ({ mapper = (newsItem) => newsItem ,...props }) => {
    return (
        <section className={classNames({
            'news-list-container': true,
            ...props.modifiers
        })}>
            <div className="news-list-container__wrapper">
                <div className="news-list-container__wrapper-inner">
                    <h2>{props.heading}</h2>
                    <div className="news-list">
                        <div className="news-list__list">
                            {props.newsList && props.newsList.length > 0 && props.newsList.map((newsItem, i) => {
                                return (
                                    <ListItem key={`newsItem${i}`} useLogo={props.useLogo} {...mapper(newsItem)} linkAttr={newsItem.linkAttr} />
                                );
                            })}
                        </div>
                    </div>
                    {props.readMore &&
                        <a href={props.readMore} className="news-list-container__read-more-link">Alla nyheter</a>
                    }
                </div>
            </div>
        </section>
    );
};

export default NewsList;