import React from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import './banner.scss';

const Banner = props => {
    const query = qs.parse(props.location.search);
    if (!query.trackid) return null;

    return (
        <div className="search-page-watch-notification-banner">
            <div>
                <span>Ändra bevakningen?</span> Justera din sökning och tryck
                sedan spara.
            </div>
            <div className="options">
                <button type="button" onClick={() => props.toggleWatchDialog('update')}>Spara</button><span></span><button onClick={() => props.toggleWatchDialog('settings')}>Inställningar</button>
            </div>
        </div>
    );
};

export default withRouter(Banner);