import React, { Component } from 'react';
import classNames from 'classnames';
import closeBtn from '../../images/close-button.svg';
import './styles.scss';

class Overlay extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (window) {
            const overlays = document.getElementsByClassName('overlay--open');
            if (overlays.length > 0 && window.document.body.style.overflow !== 'hidden') {
                window.document.body.style.overflow = 'hidden';
                window.document.getElementById('root').style.overflow = 'visible';;
                
            } 
            
            if (overlays.length === 0) {
                window.document.body.style.overflow = 'auto';
                window.document.getElementById('root').style.removeProperty('overflow');
            }
        }
    }

    render() {
        const props = this.props;

        return (
            <div id={props.id}
                 aria-hidden={!props.isOpen}
                 className={classNames({
                    'overlay': true,
                    'overlay--open': props.isOpen,
                    ...props.modifiers
                  })}>
                <button className="overlay__close"
                        onClick={() => props.toggleOverlay()}>
                        <img src={closeBtn}
                             className="overlay__close"
                             title="Stäng menyn" />
                </button>
                <div className="overlay__content">
                    
                    <h2 className="overlay__heading">{props.heading}</h2>                    

                    {props.children}
                </div>
            </div>
        );
    }
}

export default Overlay;