import React, { Component } from 'react';
import { URL } from '../../config';
import Post from '../../components/post';
import Breadcrumb from '../../components/breadcrumb';

class BlogItem extends Component {
    constructor(props) {
        super(props);
    }

    static async requestInitialData(routes, req, res, cache) {
        try {
            const endpoint = `${URL}/api/v1/blog/posts?_embed=1&slug=${routes.params.slug}`;
            
            let wpData = cache && cache.get(endpoint);
            
            if (!wpData) {
                const res = await fetch(endpoint);
                if (!res.ok) {
                    const err500 = new Error(res.statusText);
                    err500.status = 500;
                    throw err500;
                }

                wpData = await res.json();
                if (wpData.length === 0) {
                    const err404 = new Error('Not found 2');
                    err404.status = 404;
                    throw err404;
                }
                
                if (cache) {
                    cache.set(endpoint, wpData);
                }
                
            }

            if (wpData.length > 0) {
                return wpData[0];
            }
            
            return wpData;
        } catch (err) {
            throw err;
        }
    }

    getImageSrc(initialData) {
        if (initialData._embedded && initialData._embedded['wp:featuredmedia']) {
            const img = initialData._embedded['wp:featuredmedia'][0];
            return {
                src: img.media_details.sizes.medium_large.source_url,
                alt: img.alt_text
            }
        }

        return null;
    }
    
    render() {
        const initialData = this.props.initialData.length ? this.props.initialData[0] : this.props.initialData;
        const image = this.getImageSrc(initialData);
        const preambleBlock = initialData.blocks.filter(block => block.blockName === 'dabas/block-preamble-block');
        const postBlocks = initialData.blocks.filter(block => block.blockName !== 'dabas/block-preamble-block');
        const heading = initialData.title.rendered;

        return (
            <>
                <Breadcrumb url="/blog" link_text="Alla blogginlägg" />
                <Post image={image} 
                      heading={heading}
                      preambleBlock={preambleBlock} 
                      postBlocks={postBlocks} {...initialData} />
            </>
        );
    } 
}

export default BlogItem;


