import Onboard from './pages/onboard';
import IndexPage from './pages/index';
import Blog from './pages/blog';
import Watches from './pages/watches';
import Changed from './pages/watches/changed';
import Post from './pages/blog-item';
import Search from './pages/search';
import Compare from './pages/compare';
import Productsheet from './pages/productsheet';
import Uhm from './pages/uhm';
import Newsroom from './pages/newsroom';
import PreviewItem from './pages/newsroom/preview-item';
import Preview from './pages/newsroom/preview';
import News from './pages/news';
import Categories from './pages/categories';
import CategoryLevel2 from './pages/categories/level2';
import CategoryLevel3 from './pages/categories/level3';
import CategoryLevel4 from './pages/categories/level4';
import Businesses from './pages/businesses';
import Generic from './pages/generic';

const routes = [
    {
        path: '/404',
        exact: true,
        component: IndexPage
    },
    {
        path: '/',
        exact: true,
        component: IndexPage,
        params: {
            slug: '/start'
        },
        modifiers: {
            'is-parallax': true,
            'is-start': true
        }
    },
    {
        path: '/onboard',
        component: Onboard,
        params: {
            slug: '/onboard'
        },
        title: 'Kom igång'
    },
    {
        path: '/preview',
        component: Preview,
        hiddenHeader: true,
        title: 'Förhandsgranskning',
        modifiers: {
            'is-preview': true
        },
    },
    {
        path: '/preview-item',
        component: PreviewItem,
        hiddenHeader: true,
        title: 'Förhandsgranskning',
        modifiers: {
            'is-preview': true
        },
    },
    {
        path: '/news',
        component: News,
        title: 'Nyheter'
    },
    {
        path: '/categories/:category1/:category2/:category3',
        component: CategoryLevel4
    },
    {
        path: '/categories/:category1/:category2',
        component: CategoryLevel3
    },
    {
        path: '/categories/:category1',
        component: CategoryLevel2
    },
    {
        path: '/categories',
        component: Categories,
        title: 'Utforska produkter'
    },
    {
        path: '/myfeed/:trackid',
        component: Changed,
        title: 'Mina bevakningar'
    },
    {
        path: '/myfeed',
        exact: true,
        component: Watches,
        title: 'Mina bevakningar'
    },
    {
        path: '/productsheet/uhm/:slug',
        hiddenHeader: true,
        component: Uhm
    },
    {
        path: '/productsheet/:slug',
        hiddenHeader: true,
        component: Productsheet
    },
    {
        path: '/productsheet/:slug',
        hiddenHeader: true,
        component: Productsheet
    },
    {
        path: '/search/compare/*',
        component: Compare,
        title: 'Sök & utforska'
    },
    {
        path: '/search',
        exact: true,
        component: Search,
        title: 'Sök & utforska'
    },
    {
        path: '/search?',
        component: Search,
        title: 'Sök & utforska'
    },
    {
        path: '/blog',
        exact: true,
        component: Blog,
        title: 'Dabasbloggen'
    },
    {
        path: '/blog/tag/:tag',
        exact: true,
        component: Blog
    },
    {
        path: '/blog?',
        component: Blog
    },
    {
        path: '/blog/:slug',
        component:Post,
    },
    {
        path: '/businesses',
        component: Businesses,
        modifiers: {
            'is-parallax': true
        },
        title: 'Deltagande företag'
    },
    {
        path: '/:slug/produkter',
        hiddenHeader: true,
        component: Newsroom,
    },
    {
        path: '/:slug/nyhet/:newsItem',
        hiddenHeader: true,
        component: Newsroom,
    },
    {
        path: '/:slug/nyhet',
        hiddenHeader: true,
        component: Newsroom,
    },
    {
        path: '/:section/:slug',
        component: Generic,
        modifiers: {
            'is-parallax': true
        }
    },
    {
        path: '/:slug',
        component: Generic,
        modifiers: {
            'is-parallax': true
        }
    }
];

export default routes;