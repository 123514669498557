import React, { Component } from 'react';
import classNames from 'classnames';
import CookieBanner from '../cookiebanner';
import SendButton from '../sendbutton';
import { Input } from '../forms';
import './styles.scss';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            isSending: false,
            isSent: false
        };

        this.sendButton = null;
        this.inputElm = null;
    }
    
    async startFetch(cb) {
        const res = await fetch('/api/v1/mail/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.inputElm.state.value
            }) 
        });

        if (res.status === 200) {
            cb();
        }
    }

    completed() {
        this.inputElm.clear();
        this.setState({
            isSending: false,            
        });
    }

    addButton(component) {
        this.sendButton = component;
    }

    addControl(component) {
        this.inputElm = component;
    }

    handleSubmit(e) {
        e.preventDefault();

        if(!this.state.isSending) {

            //Validatte form
            let valid = true;
            if (!this.inputElm.validate()) {
                valid = false;
            }
    
            if (valid) {
                this.setState({
                    isValid: true,
                    isSending: true
                });
            
                this.sendButton.animate();
            } else {
                this.setState({
                    isValid: false,
                    isSending: false
                });
            }
    
            setTimeout(() => {
                this.setState({
                    isValid: true
                });
            }, 1000);  
        }
    }

    render() {
        return (
            <footer className="footer">
                <div className="footer__content-padding">
                    <div className="footer__wrapper">
                        <h4 className="footer__headline">Bevaka branschen - <span>prenumerera kostnadsfritt på Dabas Marknadsrapport</span></h4>
    
                        <form className="footer__form" action="/" onSubmit={(e) => this.handleSubmit(e)}>
                            <label htmlFor="footer-email" className="hide">E-postadress</label>
                            <Input id="footer-email"
                                   className={classNames({
                                    'footer__email-input': true,
                                    'footer__email-input--is-loading': this.state.isSending
                                   })}
                                   type="email"
                                   addControl={(control) => this.addControl(control)}
                                   placeholder="Din e-postadress" />
    
                            <label htmlFor="footer-submit" className="hide">Skicka</label>
                            <SendButton init={(ctrl) => this.addButton(ctrl)} isSending={this.state.isSending} 
                                        startFetch={(cb) => this.startFetch(cb)} 
                                        completed={() => this.completed()} 
                                        text="Bevaka branschen" 
                                        success="Sparat" 
                                        id="add-recipient"
                                        modifiers={{
                                            'footer__button': true,
                                            'form__submit--is-error': !this.state.isValid
                                        }} />
                        </form>
    
                        <div className="footer__contact footer-contact">
                            <p>
                                <a href="https://kundservice.delfi.se/" target="_blank">Kundservice</a>
                                <span className="footer__divider">•</span>
                                08-522 243 00
                                <span className="footer__divider">•</span>
                                <a href="mailto:info@delfi.se">info@delfi.se</a>
                                <span className="footer__divider">•</span>
                                Dabas är en tjänst från&nbsp;
                                <a href="https://www.delfi.se/" target="_blank">Delfi Marknadspartner AB</a>
                            </p>
                        </div>
                        <CookieBanner {...this.props} />
                    </div>
                </div>
            </footer>
        );
    }
};

export default Footer;