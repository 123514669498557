import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import qs from 'query-string';
import classNames from 'classnames';
import FilterItem from './filteritem';
import Ingredients from './ingredients';
import SelectBox from './selectbox';
import MultipleSelect from './multipleselect'
import Spinner from '../../components/spinner';
import FilterTooltip from './filtertooltip';
import filterBarCloseBtn from '../../images/close-filter-bar.svg'
import './filterbar.scss';

class FilterBar extends Component {
    constructor(props) {
        super(props);
        
        this.filterItems = [];
        this.controlledElements = [];
        const query = qs.parse(this.props.location.search); 
        this.state = {
            filters: props.filters,
            isLoading: false,
            isTooltip: false,
            huvudgruppbenamningDisabled: true,
            varugruppbenamningDisabled: true,
            varuundergruppbenamningDisabled: true,
            msrundergruppDisabled: false,//query && query['msrgrupp'] === '',
            msrnamnDisabled: false,
            typeOfProduce: query && query['typavravara'] ? query['typavravara'] : null
        };
    }

    async resetControlledElement(name) {
        if (this.controlledElements[name].props.multiple) {
            await this.controlledElements[name].setState({
                value: []
            });
        } else if (this.controlledElements[name].props.name === 'ingredient') {
            await this.controlledElements[name].setState({
                ingredients: []
            });
        } else {
            await this.controlledElements[name].setState({
                value: ''
            });
        }
        
    }

    async isControlsEnabled() {
        
        if (this.controlledElements['varugruppbenamning'].state.value === '') {
            await this.resetControlledElement('varuundergruppbenamning');
            
            this.setState({
                varuundergruppbenamningDisabled: true
            });
        } else {
            this.setState({
                varuundergruppbenamningDisabled: false
            });
        }

        if (this.controlledElements['huvudgruppbenamning'].state.value === '') {
            await this.resetControlledElement('varugruppbenamning');
            await this.resetControlledElement('varuundergruppbenamning');

            this.setState({
                varugruppbenamningDisabled: true,
                varuundergruppbenamningDisabled: true
            });
        } else {
            this.setState({
                varugruppbenamningDisabled: false
            });
        }

        if (this.controlledElements['varuomradebenamning'].state.value === '') {
            await this.resetControlledElement('huvudgruppbenamning');
            await this.resetControlledElement('varugruppbenamning');
            await this.resetControlledElement('varuundergruppbenamning');
            
            this.setState({
                huvudgruppbenamningDisabled: true,
                varugruppbenamningDisabled: true,
                varuundergruppbenamningDisabled: true
            });
        } else {
            this.setState({
                huvudgruppbenamningDisabled: false,
            });
        }
        /*

        if (this.controlledElements['msrundergrupp'].state.value === '') {
            await this.resetControlledElement('msrnamn');
            this.setState({
                msrnamnDisabled: true
            });
        } else {
            this.setState({
                msrnamnDisabled: false
            });
        }

        if (this.controlledElements['msrgrupp'].state.value === '') {
            await this.resetControlledElement('msrundergrupp');
            await this.resetControlledElement('msrnamn');
            this.setState({
                msrundergruppDisabled: true,
                msrnamnDisabled: true
            });
        } else {
            this.setState({
                msrundergruppDisabled: false
            });
        }*/
    }

    async componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.isControlsEnabled();
        }
    }

    async handleChange(e) {
        this.appendFilters(e);
    }

    async handleProduceChange(e) {
        e.persist();
        const typeOfProduce = e.currentTarget.value;

        this.setState({
            typeOfProduce
        }, () => {
            this.appendFilters(e);
        });
    }

    appendFilters(e) {
        e.preventDefault();   
        try {
            e.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }));
        } catch (err) {
            const event = document.createEvent('Event');
            event.initEvent('submit', false, true); 
            e.target.form.dispatchEvent(event);
        } 
    }

    addControlledElements(elm) {
        this.controlledElements[elm.props.name] = elm;
    }

    addFilter(filter) {
        this.filterItems.push(filter);
    }

    closeAllFilters() {
        for (let filter of this.filterItems) {
            filter.setState({
                isOpen:false
            });
        }
    }

    async clearAllControlledElements(e) {
        const query = qs.parse(this.props.location.search);

        if (query.SearchText) {
            this.props.navigate(`${this.props.location.pathname}?SearchText=${query.SearchText}`);    
        } 
        else {
            this.props.navigate(this.props.location.pathname);    
        }
    }

    getFilter(filters, key) {
        return filters.find(f => f.Key === key);
    }

    getCurrentCategory(key) {
        const query = qs.parse(this.props.location.search);
        return query[key];
    }

    getChildFilter(facets, labelValue) {
        const filter = facets.find(f => f.LabelValue === labelValue);

        if (filter) {
            return filter.FacetValues;
        }

        return [];
    }

    render() {
        const { filters, results } = this.props;
    
        const ursprungMeat = filters && this.getChildFilter(this.getFilter(filters, 'ravaruursprung/ravaruursprungfilter').FacetValues, 'Kött och fågelkött');
        const ursprungFish = filters && this.getChildFilter(this.getFilter(filters, 'ravaruursprung/ravaruursprungfilter').FacetValues, 'Fisk och skaldjur');
        const ursprungVeg = filters && this.getChildFilter(this.getFilter(filters, 'ravaruursprung/ravaruursprungfilter').FacetValues, 'Vegetabilisk produkt');
        const ursprungOther = filters && this.getChildFilter(this.getFilter(filters, 'ravaruursprung/ravaruursprungfilter').FacetValues, 'Övrigt');
        const born = ursprungMeat && this.getChildFilter(ursprungMeat, 'Kött och fågelkött|Född');
        const slaughter = ursprungMeat && this.getChildFilter(ursprungMeat, 'Kött och fågelkött|Slaktad');
        const raised = ursprungMeat && this.getChildFilter(ursprungMeat, 'Kött och fågelkött|Uppfödd');
        const produced = ursprungMeat && this.getChildFilter(ursprungMeat, 'Kött och fågelkött|Förädlad');
        const catchArea = ursprungFish && this.getChildFilter(ursprungFish, 'Fisk och skaldjur|Fångstzon');
        const farmedArea = ursprungVeg && this.getChildFilter(ursprungVeg, 'Vegetabilisk produkt|Plats för odling');
        const other = ursprungOther && this.getChildFilter(ursprungOther, 'Övrigt|Ursprungsland');        

        return (
            <section 
                className={classNames({
                    'search-page-filter-bar': true,
                    'search-page-filter-bar--is-open': this.props.isOpen,
                    'search-page-filter-bar--is-loading': this.props.isLoading,
                    'search-page-filter-bar--is-tooltip': this.state.isTooltip
                })}>
                <div className="search-page-filter-bar__scroll">
                    <div className="search-page-filter-bar__header">
                        <h3>Filter</h3>
                        <img onClick={() => this.props.toggleFilterBar()}
                            src={filterBarCloseBtn}
                            className="search-page-filter-bar__close"
                            alt="Dölj filter"
                            title="Dölj filter" />
                    </div>
                    <div className="filter-items">
                        <FilterItem heading="Leverantör/varumärke" 
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={(filter) => this.props.numOfFiltersApplied(filter)}>
                            <label htmlFor="information-provider">Uppgiftslämnare</label>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="uppgiftslamnare" 
                                        className="filter-item__select" 
                                        id="information-provider" 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'uppgiftslamnare')} />
        
                            <label htmlFor="manufacturer">Tillverkare</label>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="tillverkare" 
                                        className="filter-item__select" 
                                        id="manufacturer" 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'tillverkare')} />
        
                            <label htmlFor="brand">Varumärke</label>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="varumarke" 
                                        className="filter-item__select" 
                                        id="brand" 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'varumarke')} />
                        </FilterItem>

                        <FilterItem heading="Ingredienser" 
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={(filter) => this.props.numOfFiltersApplied(filter)}>
                            <Ingredients addControlledElements={(elm) => this.addControlledElements(elm)}
                                        name="ingredient" />
                        </FilterItem>
                        
                        <FilterItem heading="Märkning"
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={(filter) => this.props.numOfFiltersApplied(filter)}>
                            <label htmlFor="label" className="filter-item__sub-label">Välj typ av märkning 
                                <FilterTooltip heading="Märkningar">
                                    <p>Typ av märkning används för att ange en artikels ackrediterad märkning, Allergi- eller dietmärkning, eller märkning för ämnen som artikeln inte innehåller:</p>
                                    <ul>
                                        <li>T4028 Allergi/dietmärkning</li>
                                        <li>T4031 Fritt från-märkning</li>
                                        <li>T3777 Ackrediterad märkning</li>
                                    </ul>
                                </FilterTooltip>
                            </label>
                            <MultipleSelect onChange={(e) => this.handleChange(e)} 
                                            addControlledElements={(elm) => this.addControlledElements(elm)} 
                                            name="markning" 
                                            modifiers={{
                                                'multipleselect--no-scroll': true
                                            }}
                                            filters={filters && this.getFilter(filters, 'markning')} />
                        </FilterItem> 
                        

                        <FilterItem heading="Produktgrupp"
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={(filter) => this.props.numOfFiltersApplied(filter)}>
                            <div className="filter-item__label-info">
                                <label htmlFor="article-area">Varuområde</label>
                                <FilterTooltip heading="Varuområde">
                                    <p>Dabas Produgruppsindelning används för att klassificera produkter på marknaden. Varugrupp används för att beskriva det övergripande varuområdet.</p>
                                </FilterTooltip>
                            </div>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="varuomradebenamning" 
                                        className="filter-item__select" 
                                        id="article-area" 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'varuomradebenamning')} />
                            <div className="filter-item__label-info">
                                <label htmlFor="main-group">Huvudgrupp</label>
                                <FilterTooltip heading="Huvudgrupp">
                                    <p>Huvudgrupper som finns inom det valda varuområdet.</p>
                                </FilterTooltip>
                            </div>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="huvudgruppbenamning" 
                                        className="filter-item__select" 
                                        id="main-group" 
                                        disabled={this.state.huvudgruppbenamningDisabled}
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'huvudgruppbenamning')} />
        
                            <div className="filter-item__label-info">
                                <label htmlFor="article-group">Varugrupp</label>
                                <FilterTooltip heading="Varugrupp">
                                    <p>Varugrupper som finns inom den valda huvudgruppen.</p>
                                </FilterTooltip>
                            </div>
                            
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="varugruppbenamning" 
                                        className="filter-item__select" 
                                        id="article-group" 
                                        disabled={this.state.varugruppbenamningDisabled}
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'varugruppbenamning')} />
                            <div className="filter-item__label-info">
                                <label htmlFor="article-sub-group">Varuundergrupp</label>
                                <FilterTooltip heading="Varuundergrupp">
                                    <p>Varuundergrupper som finns inom den valda varugruppen.</p>
                                </FilterTooltip>
                            </div>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="varuundergruppbenamning" 
                                        className="filter-item__select" 
                                        id="article-sub-group" 
                                        disabled={this.state.varuundergruppbenamningDisabled} 
                                        addControlledElements={(elm) => this.addControlledElements(elm)} 
                                        filters={filters && this.getFilter(filters, 'varuundergruppbenamning')} />
                            <label htmlFor="product-group-code">Produktgruppskod
                                <FilterTooltip heading="Produktgruppskod">
                                    <p>Möjlighet att skriva in Dabas produktgruppskod, som är en aggregerad kod av Varuområde, Huvudgrupp, Varugrupp och Varuundergrupp.</p>
                                </FilterTooltip>
                            </label>
                            <input name="productgroupcode" 
                                onBlur={(e) => this.handleChange(e)} 
                                type="text" 
                                placeholder="1-12 siffror" 
                                id="product-group-code" />
                        </FilterItem> 

                        <FilterItem heading="Råvaruursprung"
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={this.props.numOfFiltersApplied}>

                            <label htmlFor="ravaruursprung-ravaruursprungfilter" className="filter-item__label-info">
                                Typ av råvara
                                <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                    <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                </FilterTooltip>
                            </label>
                            <SelectBox  onChange={(e) => this.handleProduceChange(e)}
                                        name="typavravara" 
                                        className="filter-item__select" 
                                        id="ravaruursprung-ravaruursprungfilter" 
                                        defaultText="Alla"
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'ravaruursprung/ravaruursprungfilter')} />
                                
                                {this.state.typeOfProduce === 'Kött och fågelkött' &&
                                    <>
                                        <label htmlFor="born" className="filter-item__label-info">
                                            Född
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_fodd" 
                                                    className="filter-item__select" 
                                                    id="born" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={born && {
                                                        FacetValues: born
                                                    }} />
                                        
                                        <label htmlFor="slaughter" className="filter-item__label-info">
                                            Slaktad
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_slaktad" 
                                                    className="filter-item__select" 
                                                    id="slaughter" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={slaughter && {
                                                        FacetValues: slaughter
                                                    }} />   

                                        <label htmlFor="raised" className="filter-item__label-info">
                                            Uppfödd
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_uppfodd" 
                                                    className="filter-item__select" 
                                                    id="raised" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={raised && {
                                                        FacetValues: raised
                                                    }} />   
        
                                        <label htmlFor="produced" className="filter-item__label-info">
                                            Förädlad
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_foradlad" 
                                                    className="filter-item__select" 
                                                    id="produced" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={produced && {
                                                        FacetValues: produced
                                                    }} />
                                    </>
                                }

                                {this.state.typeOfProduce === 'Fisk och skaldjur' &&
                                    <>
                                        <label htmlFor="ursprungsland_fangstzon" className="filter-item__label-info">
                                            Fångstzon
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_fangstzon" 
                                                    className="filter-item__select" 
                                                    id="ursprungsland_fangstzon" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={catchArea && {
                                                        FacetValues: catchArea
                                                    }} />
                                    </>
                                }

                                {this.state.typeOfProduce === 'Vegetabilisk produkt' &&
                                    <>
                                        <label htmlFor="ursprungsland_platsforodling" className="filter-item__label-info">
                                            Plats för odling
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_platsforodling" 
                                                    className="filter-item__select" 
                                                    id="ursprungsland_platsforodling" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={farmedArea && {
                                                        FacetValues: farmedArea
                                                    }} />
                                    </>
                                }

                                {this.state.typeOfProduce === 'Övrigt' &&
                                    <>
                                        <label htmlFor="other" className="filter-item__label-info">
                                            Ursprungsland
                                            <FilterTooltip heading="Ingredienser/Råvaruursprung">
                                                <p>Råvarans ursprung i betydelsen var djur fötts, uppfötts och slaktats, var ägg värpts, var mjölk mjölkats och var frukt, grönsaker, spannmål och andra grödor odlats.</p>
                                            </FilterTooltip>
                                        </label>
                                        <SelectBox  onChange={(e) => this.handleChange(e)}
                                                    name="ursprungsland_ursprungsland" 
                                                    className="filter-item__select" 
                                                    id="other" 
                                                    defaultText="Alla"
                                                    addControlledElements={(elm) => this.addControlledElements(elm)}
                                                    filters={other && {
                                                        FacetValues: other
                                                    }} />
                                    </>
                                }
                                
                        </FilterItem>

                        <FilterItem heading="Tillverkningsland"
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={this.props.numOfFiltersApplied}>
                            <label htmlFor="tillverkningsland" className="filter-item__label-info">
                                Tillverkningsland
                                <FilterTooltip heading="Tillverkningsland">
                                    <p>Upphandlingsmyndigheten, UHM, ansvarar för en uppsättning hållbarhetskriterier till stöd för de upphandlande myndigheter och enheter som vill ta hållbarhetshänsyn i sin upphandling. Dabas är informationsnav och plattform för UHM-kriterierna. Här kan du som användare söka fram vilka livsmedel på marknaden som uppfyller hållbarhetskriterierna.</p>
                                </FilterTooltip>
                            </label>
                            <SelectBox  onChange={(e) => this.handleChange(e)}
                                        name="tillverkningsland" 
                                        className="filter-item__select" 
                                        id="tillverkningsland" 
                                        defaultText="Alla"
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'tillverkningsland')} />
                        </FilterItem>
                        
                        <FilterItem heading="UHM"
                                    addFilter={(filter) => this.addFilter(filter)}
                                    closeAllFilters={() => this.closeAllFilters()}
                                    numOfFiltersApplied={this.props.numOfFiltersApplied}>

                            <div className="filter-item__label-info">
                                <label htmlFor="uhm-product-group">UHM-produktgrupp</label>
                                <FilterTooltip heading="Upphandlingsmyndighetens hållbarhetskriter">
                                    <p>Upphandlingsmyndigheten, UHM, ansvarar för en uppsättning hållbarhetskriterier till stöd för de upphandlande myndigheter och enheter som vill ta hållbarhetshänsyn i sin upphandling. Dabas är informationsnav och plattform för UHM-kriterierna. Här kan du som användare söka fram vilka livsmedel på marknaden som uppfyller hållbarhetskriterierna.</p>
                                </FilterTooltip>
                            </div>
                            <SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="msrgrupp" 
                                        className="filter-item__select" 
                                        id="uhm-product-group" 
                                        defaultText="Alla"
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'msrgrupp')} />
        
                            <label htmlFor="uhm-child-group" className="filter-item__label-info">UHM-undergrupp</label>
                            <SelectBox  onChange={(e) => this.handleChange(e)}
                                        name="msrundergrupp" 
                                        className="filter-item__select" 
                                        id="uhm-child-group" 
                                        defaultText="Alla"
                                        disabled={this.state.msrundergruppDisabled} 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && this.getFilter(filters, 'msrundergrupp')} />    
                            <label className="filter-item__label-info">UHM-kriterier</label>
                            <MultipleSelect onChange={(e) => this.handleChange(e)} 
                                            addControlledElements={(elm) => this.addControlledElements(elm)} 
                                            disabled={this.state.msrnamnDisabled}
                                            name="msrnamn" 
                                            filters={filters && this.getFilter(filters, 'msrnamn')} />
                            { /*<SelectBox  onChange={(e) => this.handleChange(e)} 
                                        name="msrnamn" 
                                        className="filter-item__select filter-item__select--multiple" 
                                        id="uhm-criterias" 
                                        disabled={this.state.msrnamnDisabled}
                                        multiple={true} 
                                        addControlledElements={(elm) => this.addControlledElements(elm)}
                                        filters={filters && filters.msrnamn} />*/}
                        </FilterItem>
                        <div className="search-page-filter-bar__footer">
                            <button type="submit" onClick={(e) => {
                                    this.props.toggleFilterBar();
                                }} className="button button-large search-page-filter-bar__apply-filters">
                                <span className="search-page-filter-bar__apply-filters-text">Visa {results && results.TotalRecords ? results.TotalRecords : '0' } artiklar</span>
                                <Spinner />
                            </button>
                            <button type="reset" onClick={(e) => this.clearAllControlledElements(e)} className="filter-items__clear-filters">Rensa filter</button>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
};

export default withRouter(FilterBar);