import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import { isValidLogo } from '../../helpers';
import StoryPush from '../../components/story/push';
import { Header, 
    Menu, 
    Hero, 
    Usp, 
    ContactPerson,
    Contact,
    Recipes,
    About } from '../../components/newsroom';
import NewsList from '../../components/newslist';
import Video from '../../components/video';
import ProductCards from '../../components/productcards';
import { mapArticleToSearchResult } from './mapper';
import { DABAS_NEWSROOM_API,
    DABAS_NEWSROOM_NEWS_API,
    filterProductStories } from './helpers';

const Preview = props => {
    
    const [localProps, setLocalProps] = useState(null);

    useEffect(() => {
        window.onmessage = function(e) {
            if (e.data && e.data.type === 'UPDATE') {
                setLocalProps(e.data.props);
            }
            if (e.data && e.data.type === 'SCROLL' && e.data.scrollToElm) {
                const elm = document.getElementById(e.data.scrollToElm);
                if (elm) {
                    elm.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        };

        window.top.postMessage({ type: 'READY'}, '*');



            /*const newsroom = await resNewsroom.json();

            setData({
                ...newsroom
            });
            
            setNewDesign({
                ...newDesignObj,
                FileType: newsroom.ImageLink && fileType(newsroom.ImageLink),
                FileName: newsroom.ImageLink && newsroom.CompanyName,
                ImageLink: newsroom.ImageLink,
                RssColor: newsroom.RssColor
            });
            setYoutubeLink(newsroom.YouTubeLink ? newsroom.YouTubeLink : '');
            setLoading(false);         */
    }, []);
    
    if (!localProps) {
        return null;
    }

    const createContent = (content) => {
        if (!content) return null;

        let cleanText = content;
        cleanText = cleanText.replace(/<p><br><\/p>/gi, '').replace('/<p><\/p>/gi', '').replace('/<br><br>/gi', '');

        return ({
            innerHTML: cleanText
        }); 
    }

    const zoom = () => {
        var element = document.documentElement;
        element.classList.toggle('zoom');
    };

    const newsroom = localProps.data;
    
    const productStories = filterProductStories(newsroom);
    const articles = mapArticleToSearchResult(newsroom.Articles, newsroom.ImageLink);

    

    return (
        <div className={classNames({
            'news-room-container': true
        })}>
            <div id="company-header">
                <Menu newsroom={newsroom} isEditing={false}>
                    {(newsroom.NewsroomActive && newsroom.ImageLink && isValidLogo(newsroom.ImageLink)) ?
                        <a href={`/${newsroom.NewsroomLink}`}>
                            <img src={newsroom.ImageLink} alt={newsroom.CompanyName} /> 
                        </a>: newsroom.CompanyName    
                    }
                </Menu>
            </div>
            {productStories && productStories.length > 0 ?  
                <StoryPush newsStories={productStories} modifiers={{ 'story-push--no-margin-top': true}} />
                    : 
                <Hero color={newsroom.RssColor} news={newsroom.News} newsroom={newsroom} isEditing={false} />
            }


            {articles &&
                <ProductCards 
                    heading="Senaste produkterna" 
                    useSlider={true}
                    search={{
                        SearchResults: articles && articles.slice(0,4)
                    }}
                    readMore={`/${newsroom.NewsroomLink}/produkter`} />
            }

            {newsroom.News && newsroom.News.length > 1 &&
                <NewsList heading="Nyheter"
                        useLogo={false} 
                        readMore={`/${newsroom.NewsroomLink}/nyhet`} 
                        newsList={newsroom.News && newsroom.News.slice(0, 5).map(item => {
                            return { 
                                ...item,
                                linkAttr: item.FromRss ? { target: '_blank', rel: 'noopener noreferrer'} : null,
                                Link: item.Link
                            };
                        })} />
            } 

            <div id="company-recipes">
                <Recipes {...newsroom} />
            </div>

            <div id="company-video">
                <Video videoUrl={newsroom.YouTubeLink} videoLink={newsroom.YouTubeLink} />
            </div>

            <Usp {...newsroom} />
            {newsroom.NewsroomActive &&
                <>
                    <div id="contact-person">
                        <ContactPerson {...newsroom} />
                    </div>
                    <section className={classNames({
                                'newsroom-contact': true,
                                'newsroom-contact--no-desc': !newsroom.CompanyDescription,
                                'newsroom-contact--no-color': !newsroom.RssColor
                            })}>
                            <div id="company-info">
                                <div className="newsroom-contact__bg-color" style={{backgroundColor: newsroom.RssColor}}></div>
                                <div className="newsroom-contact__wrapper-outer">
                                    <div className="newsroom-contact__business-info">
                                        <About {...newsroom} />
                                    </div>
                                    <div className="newsroom-contact__business-contact" style={{backgroundColor: newsroom.RssColor}}>
                                        <Contact {...newsroom} />
                                    </div>
                                </div>
                            </div>
                    </section>
                </>
            }

            <div className="preview-lock" onClick={() => {
                zoom();
            }}></div>
            
        </div>        
    );
};

export default Preview;