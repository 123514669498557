import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Image from '../image';
import './styles.scss';

const MarketersInfo = (props) => {

    return (
        <section className={classNames({
            'marketers-info': true,
            'marketers-info--img-dotted': props.modifiers && props.modifiers.length !== 0,
            ...props.modifiers
        })} id={props.blockanchor}>
            <div className="marketers-info__wrapper">
                <div className="marketers-info__wrapper-inner">
                    <div className="marketers-info__info-text">
                        <h2>{props.heading}</h2>
                        <div dangerouslySetInnerHTML={{__html: props.content}}></div>
                        {props.url &&
                            <a href={props.url} className="marketers-info__read-more-link" title={props.linktext}>{props.linktext}</a>
                        }
                        
                    </div>
                    <Image src={props.img.full.url} alt={props.img.alt} />
                </div>
            </div>
        </section>
    );
};

export default MarketersInfo;