import React from 'react';

const Pastaende = product => {
    if (!product) return null;

    const createModules = (modules) => {
        const moduleKeys = [];
        
        modules.forEach(module => {
            module.Elements.forEach(element => {

                if (element.ValueSets && element.ValueSets.length > 0) {
                    moduleKeys[`${module.ModuleId}/${element.ElementId}`] = {
                        ...element
                    }
                } 
            });
        });

        return moduleKeys;
    }

    const modules = createModules(product.Modules);
    
    if (modules['Marks/ProductInformationDetail/ClaimDetail'] && modules['Marks/ProductInformationDetail/ClaimDetail'].ValueSets.length > 0) {
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Påstående</h2>
                    <table className="product-sheet__table product-sheet__table--full-width">
                        <thead>
                            <tr>
                                <th>
                                    Ämne
                                </th>
                                <th>
                                    Nivå
                                </th>
                                <th>
                                    Märkt på förpackningen
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {modules['Marks/ProductInformationDetail/ClaimDetail'].ValueSets.map((claimDetailValueSet, i) => {
                                return (
                                    <tr key={`claimDetailValueSetTop${i}`}>
                                        {claimDetailValueSet.map((claimDetail, x) => {
                                            return (
                                                <td key={`claimDetailTop${x}`}>{claimDetail.Value}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }
    
    return null;
    
};

export default Pastaende;