import React, { Component } from 'react';
import classNames  from 'classnames';
import Spinner from '../../components/spinner';
import './recipes.scss';

class Recipes extends Component {

    state = {
        isLoading: true,
        recipes: [],
        isOpen: false
    }

    async componentDidMount() {
        const gln = this.props.Uppgiftslamnare && this.props.Uppgiftslamnare.GLN;
        try {
            const resOgResult = await fetch(`/api/v1/productsheet/recipes/dabas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    recipes: this.props.Receptlinks
                })
            });

            let recipesOG = [];
            if (resOgResult.ok) {
                recipesOG = await resOgResult.json();
            }

            const resMashieResult = await fetch(`/api/v1/productsheet/recipes/mashie?gtin=${this.props.GTIN}&gln=${gln || ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            let recipesMashie = [];
            if (resMashieResult.ok) {
                recipesMashie = await resMashieResult.json();
            }
            

            this.setState({
                isLoading: false,
                recipes: [...recipesOG, ...recipesMashie]
            });

        } catch (err) {
            this.setState({
                isLoading: false
            });
        }
    }

    handleClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() { 
        if (!this.state.isLoading && (this.state.recipes && this.state.recipes.length === 0)) {
            return null;
        }

        return (
            <section className={classNames({
                'product-sheet-box': true,
                'product-sheet-box--recipes': true,
                'product-sheet-box--recipes-is-loading': this.state.isLoading,
            })}>
                <div className="recipes__container">
                    {!this.state.isLoading && (this.state.recipes && this.state.recipes.length > 0) &&
                        <div className="product-sheet-box__inner">
                            <h2 className="product-sheet-box__heading">Receptinspiration</h2>
                            <span className="recipes__preamble">Ta del av passande recept från tillverkaren av produkten du tittar på just nu.</span>
                            <div className={classNames({
                                'recipes': true,
                                'recipes--show-all': this.state.isOpen
                            })}>
                                {this.state.recipes.map((recipe) => {
                                    return (
                                        <a href={recipe.Url} className="recipes__recipe" key={recipe.Id} target="_blank">
                                            <div className="recipes__recipe-image-container">
                                                <div className="recipes__recipe-image" style={{
                                                    backgroundImage: `url('${recipe.RecipeImageLink}')`
                                                }}>
                                                </div>
                                            </div>
                                            <h3 className="recipes__recipe-heading">{recipe.Name}</h3>
                                            <span className="recipes__recipe-origin">{recipe.Origin}</span>
                                        </a>
                                    );
                                })}
                            </div>
                            {this.state.recipes && this.state.recipes.length > 4 &&
                                <button className="recipes__toggle" type="button" onClick={() => this.handleClick()}>{this.state.isOpen ? 'Visa färre' : 'Visa fler'}</button>
                            }
                        </div>
                    }
                </div>

                <div className="recipes__loading">
                    <Spinner />
                </div>
            </section>
        );
    }
};

export default Recipes;