import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import { Route, Routes } from 'react-router-dom';
import { Header, 
         Menu, 
         Hero, 
         Usp, 
         ContactPerson,
         Contact,
         Footer,
         About,
         Recipes,
         EditArea } from '../../components/newsroom';
import { mapArticleToSearchResult, getFacets } from './mapper';
import qs from 'query-string';
import classNames from 'classnames';
import Products from './products';
import News from './news';
import NewsItem from './newsitem';
import { isValidLogo } from '../../helpers';
import NewsList from '../../components/newslist';
import ProductCards from '../../components/productcards';
import Video from '../../components/video';
import StoryPush from '../../components/story/push';
import { DABAS_NEWSROOM_API,
         DABAS_NEWSROOM_NEWS_API,
         filterProductStories } from './helpers';
import { AZURE_STORAGE } from '../../config';
import './styles.scss';


const getNewsItem = async (ulident, mediapath, slug) => {
    try {
        const res = await fetch(DABAS_NEWSROOM_NEWS_API(ulident, mediapath, slug));
        if (res.status === 204) {
            return null;
        }

        return res.json();
      } catch (err) {
        console.log(err);
        return {};
    }
};
class NewsRoom extends Component {
    constructor(props) {
        super(props);

        if (typeof window !== 'undefined') {
            window.addEventListener('message', e => {
                var action = e.data;
                if (action.reload && e.origin.indexOf('dabas.') > -1) {
                    const { location } = props;                    
                    const paths = location.pathname.split('/');
                    const reloadUrl = `/${paths[1]}/nyhet/${action.returnUrl}?edit=true`;
                    window.location.href = reloadUrl;
                }
            }); 
        }

        const query = qs.parse(this.props.location.search);
        this.state = {
            isEditing: query.edit === 'true',
            articles: [],
            isLoading: true
        };
    }
    
    static async getNewsRom(slug) {
        try {
            const res = await fetch(DABAS_NEWSROOM_API(slug));
            if (res.status === 404) {
                return null;
            }
            
            if (res.status === 204) {
                return null;
            }

            return res.json();
        } catch (err) {
            console.log(err);
            return {};
        }
    }

    

    async getLatestArticles(ulident) {
        try {
            const res = await fetch('/api/v1/articles/ulident/' + ulident);

            if (res.status === 204) {
                return null;
            }

            return res.json();
        } catch (err) {
            console.log(err);
            return {};
        }
    }


    async componentDidMount() {
        const { newsroom } = this.props.initialData;
        const articleFetch = await this.getLatestArticles(newsroom.ULIDEN);
        const articles = articleFetch ? mapArticleToSearchResult(articleFetch.Articles, newsroom.ImageLink) : null;
        const facets = articleFetch ? getFacets(articleFetch.ArticleFacet) : null;
        this.setState({
            articles,
            facets,
            isLoading: false
        });
    }

    static async requestInitialData(routes, req) {
        const { params } = routes;
        let slug = req.url.substring(1);
        slug = slug.indexOf('/') > -1 ? slug.substring(0, slug.indexOf('/')) : slug;
        slug = slug.indexOf('?') > -1 ? slug.substring(0, slug.indexOf('?')) : slug;
       
        const newsroom = await NewsRoom.getNewsRom(slug);

        if (!newsroom) {
            return null;
        }

        if (params.newsItem) {
            const newsItem = await getNewsItem(newsroom.ULIDEN, newsroom.NewsroomLink, params.newsItem);
            if (!newsItem) {
                const err404 = new Error('Not found 5');
                err404.status = 404;
                throw err404;
            }
            
            return {
                newsroom,
                newsItem
            };
        }

        return {
            newsroom
        };    
    }
   
    render() {
        if (!this.props.initialData && !this.props.initialData.content) return null;

        const { newsroom, newsItem } = this.props.initialData;

        const { user, params, location } = this.props;      
        const news = newsroom.News;
        const isStory = newsItem && newsItem.Articles && newsItem.Articles.length > 0;
        const productStories = filterProductStories(newsroom);
        
        return (
            <div className={classNames({
                'news-room-container': true
            })}>
                <Header {...this.state} />
                <Menu newsroom={newsroom} user={user} isEditing={this.state.isEditing}>
                    <EditArea {...this.state} 
                              hasContent={newsroom.ImageLink && isValidLogo(newsroom.ImageLink)}
                              modifiers={{
                                'newsroom-edit--logo': true
                              }}
                              addBtnText="+ Lägg till logotyp"
                              editBtnText="Ändra logotyp"
                              editAction="ADD_LOGO">
                        {(newsroom.NewsroomActive && newsroom.ImageLink && isValidLogo(newsroom.ImageLink)) ?
                            <a href={`/${newsroom.NewsroomLink}`}>
                                <img src={newsroom.ImageLink} alt={newsroom.CompanyName} /> 
                            </a>: newsroom.CompanyName
                            
                        }
                    </EditArea>
                </Menu>

                {location.pathname === `/${params.slug}` &&
                    <>
                        <EditArea {...this.state} 
                            hasContent={newsroom.News && newsroom.News.length > 0}
                            modifiers={{
                                'newsroom-edit--news': true,
                                'newsroom-edit--no-bgcolor': !newsroom.RssColor,
                            }}
                            heading="Företagsnyheter"
                            text="Skapa och publicera företagsnyheter direkt på dabas."
                            addBtnText="+ Lägg till nyhet"
                            editBtnText="Skapa nyhet"
                            editAction="ADD_NEWS_ITEM"
                            addBtnText2="+ Lägg till nyhetsflöde"
                            editBtnText2="Redigera RSS-flöde"
                            editAction2="ADD_RSS_FEED">
                            <>
                                {productStories && productStories.length > 0 ?  
                                    <StoryPush newsStories={productStories} modifiers={{ 'story-push--no-margin-top': true}} />
                                : 
                                    <Hero {...this.state} color={newsroom.RssColor} news={news} newsroom={newsroom} isEditing={this.state.isEditing} />
                                }
                            </>
                        </EditArea>
                        {newsroom.NewsroomActive &&
                            <>
                                {this.state.articles && !this.state.isLoading &&
                                    <ProductCards heading="Senaste produkterna" 
                                                useSlider={true}
                                                search={{
                                                    SearchResults: this.state.articles && this.state.articles.slice(0,4)
                                                }}
                                                readMore={`/${params.slug}/produkter${this.state.isEditing ? '?edit=true' : ''}`} />
                                }

                                {newsroom.News && newsroom.News.length > 1 &&
                                    <EditArea {...this.state} 
                                            hasContent={true}
                                            addBtnText="+ Lägg till nyhet"
                                            editBtnText="Skapa nyhet"
                                            editAction="ADD_NEWS_ITEM"
                                            addBtnText2="+ Lägg till nyhetsflöde"
                                            editBtnText2="Redigera RSS-flöde"
                                            editAction2="ADD_RSS_FEED">
                                        <NewsList heading="Nyheter"
                                                useLogo={false} 
                                                readMore={`/${params.slug}/nyhet${this.state.isEditing ? '?edit=true' : ''}`} 
                                                newsList={news && news.slice(0, 5).map(item => {
                                                    return { 
                                                        ...item,
                                                        linkAttr: item.FromRss ? { target: '_blank', rel: 'noopener noreferrer'} : null,
                                                        Link: item.Link
                                                    };
                                                })} />
                                    </EditArea>
                                } 

                                <Recipes {...newsroom} />
                            
                                <EditArea {...this.state} 
                                        hasContent={newsroom.YouTubeLink}
                                        modifiers={{
                                            'newsroom-edit--video': true
                                        }}
                                        heading="Senast på YouTube"
                                        text="Har ditt företag ett Youtube-konto kan du peka ut länken dit och de senaste filmerna kommer då visas här."
                                        addBtnText="+ Lägg till Youtube-flöde"
                                        editBtnText="Redigera Youtube-flöde"
                                        editAction="ADD_VIDEO">
                                    <Video  {...this.state} videoUrl={newsroom.YouTubeLink} videoLink={newsroom.YouTubeLink} />
                                </EditArea>

                                <EditArea {...this.state} 
                                        hasContent={newsroom.SustainablilityDescription || newsroom.QualityDescription || newsroom.SocialResponsiblityDescription}
                                        modifiers={{
                                            'newsroom-edit--globe': true
                                        }}
                                        heading="Hållbarhet, kvalitet och socialt ansvar"
                                        text="Svara på tre snabba frågor och berätta kortfattat hur ni arbetar med hållbarhet, kvalitet och socialt ansvar."
                                        addBtnText="+ Svara på frågorna nu"
                                        editBtnText="Redigera"
                                        editAction="ADD_SUSTAINABILITY">
                                    <Usp {...newsroom} />
                                </EditArea>
                            </>
                        }
                    </>
                }

                {location.pathname === `/${params.slug}/produkter` && 
                    <>
                        {newsroom.NewsroomActive && <Products articles={this.state.articles} facets={this.state.facets} isLoading={this.state.isLoading} newsroom={newsroom} />}
                    </>
                }

                {location.pathname === `/${params.slug}/nyhet` && 
                    <>
                        {newsroom.NewsroomActive && <News newsList={news} isEditing={this.state.isEditing} />}
                    </>
                }

                {location.pathname === `/${params.slug}/nyhet/${params.newsItem}` && 
                    <>
                        {newsroom.NewsroomActive && <NewsItem {...newsItem} />}
                    </>
                }
                
                {newsroom.NewsroomActive &&
                    <>
                        {!isStory &&
                            <EditArea {...this.state} 
                                    hasContent={newsroom.ContactPersons && newsroom.ContactPersons.length > 0}
                                    modifiers={{
                                        'newsroom-edit--contact': true
                                    }}
                                    heading="Kontaktpersoner"
                                    text="Lägg till kontaktpersoner så besökarna enkelt kan kontakta er för ytterligare information."
                                    addBtnText="+ Lägg till kontaktperson"
                                    editBtnText="Redigera"
                                    editAction="ADD_CONTACTPERSON">
                                <ContactPerson {...this.state} {...newsroom} />
                            </EditArea>
                        }

                        <section className={classNames({
                            'newsroom-contact': true,
                            'newsroom-contact--no-desc': !newsroom.CompanyDescription && !this.state.isEditing,
                            'newsroom-contact--no-color': !newsroom.RssColor
                        })}>
                            <div className="newsroom-contact__bg-color" style={{backgroundColor: newsroom.RssColor}}></div>
                            <div className="newsroom-contact__wrapper-outer">
                                <div className="newsroom-contact__business-info">
                                    <EditArea {...this.state} 
                                            hasContent={newsroom.CompanyDescription}
                                            heading={`Om ${newsroom.CompanyName}`}
                                            modifiers={{
                                                'newsroom-edit--info': true
                                            }}
                                            text="Lägg till en kort beskrivning av företaget."
                                            addBtnText="+ Lägg till beskrivning"
                                            editBtnText="Redigera beskrivning"
                                            editAction="ADD_ABOUT">
                                        <About {...newsroom} />
                                    </EditArea>
                                </div>
                                <div className="newsroom-contact__business-contact" style={{backgroundColor: newsroom.RssColor}}>
                                    <Contact {...newsroom} />
                                </div>
                            </div>
                        </section>
                    </>
                }
                <Footer />
            </div>
        );
    }
};
export default withRouter(NewsRoom);