import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Breadcrumb = (props) => {
    return (
        <section className={classNames({
            'breadcrumb': true,
            ...props.modifiers
        })}>
            {props.children}

            {!props.children && <a href={props.url} onClick={props.onClick} className="breadcrumb__item" title={props.link_text}>{props.link_text}</a>}
        </section>
    );
};

export default Breadcrumb;