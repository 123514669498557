import React, { Component } from 'react';
import classNames from 'classnames';
import isActiveSearch from '../../hoc/isActiveSearch';
import { withRouter } from '../../hoc/withRouter';
import './filteritem.scss';

class FilterItem extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            isOpen: false,
            height:0
        };   
    }

    componentDidMount() {
        this.props.addFilter(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isOpen && !prevState.isOpen) {
            this.setState({
                height: `${this.refs.filterChild.scrollHeight}px`
            });

            clearTimeout(this.timer); 
            this.timer = setTimeout(() => {
                this.setState({
                    height:'auto'
                });
            }, 200);
        } 

        if (!this.state.isOpen && prevState.isOpen) {
            this.setState({
                height: `${this.refs.filterChild.scrollHeight}px`
            });
            clearTimeout(this.timer); 
            this.timer = setTimeout(() => {
                this.setState({
                    height: '0px'
                });
            }, 10);
        }
    }

    toggleFilterItem() {
        this.props.closeAllFilters();
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    
    render() {

        return (
            <div className={classNames({
                'filter-item': true,
                'filter-item--is-open': this.state.isOpen,
                'filter-item--is-active': this.props.isActive
            })}>
                <h4 onClick={() => this.toggleFilterItem()} className="filter-item-header">{this.props.heading}</h4>
                <div ref="filterChild" 
                     className="filter-item__child"
                     style={{height: this.state.height}}>
                    <div className="filter-item__child-inner">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(isActiveSearch(FilterItem));