import React, { Component } from 'react';
import queryString from 'query-string';
//import { autoComplete } from '../actions/search';
//import { search } from '../Reducers/search';

export default (WrappedComponent, 
                autoCompletePath = '/api/v1/search/autocomplete',
                currentIndexItem = (item, currentValue) => item,
                defaultIndex = -1) => {
  return class Container extends Component {
    constructor(props) {
      super(props);

      this.timer = null;
      const query = queryString.parse(this.props.location.search);

      //Set initial state
      this.state = {
        isLoading: false,
        isVisible: false,
        index: defaultIndex,
        hits: [],
        value: query.SearchText ? query.SearchText : ''
      };
    }

    dispatch(action) {
        this.setState(action);
    }

    getState() {
      return this.state;
    }

    hasHits(hits) {
      return this.state.hits && this.state.length > 0;
    }

    handleKeyboard(e) {

      if (!this.state.hits || this.state.hits.length === 0) return;
      
      switch (e.keyCode) {
        case 13:
          //if (this.state.index < 0) return true;
          this.dispatch({
            isVisible: false
          });
          return true;
        case 38:
          e.preventDefault();

          if (this.state.index < 0) {

            this.dispatch({
              index: this.state.hits.length-1,
              value: this.state.hits[this.state.hits.length-1].value
            });
            return false;
          }

          const indexMinus = --this.state.index
          this.dispatch({
            index: indexMinus,
            value: this.state.index < 0 ? this.state.defaultValue : this.state.hits[indexMinus].value
          });

          return false;

        case 40:
          e.preventDefault();

          if (this.state.index >= this.state.hits.length-1) {
            this.dispatch({
              index: -1,
              value: this.state.defaultValue
            });

            return false;
          }

          const indexPlus = ++this.state.index;
          this.dispatch({
            index: indexPlus,
            value: this.state.hits[indexPlus].value
          });

          return false;
        case 27:
          this.dispatch({
            isVisible: false
          });
      }
    }

    handleChange(e) {
      e.persist();
      const value = e.target.value;

      if (value !== this.state.value && value.length > 1) {
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            this.dispatch({
                ...this.getState(),
                isLoading: true
            });

            const query = `${autoCompletePath}?term=${encodeURI(value)}`;

            await fetch(query, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then(res => {
                
                const qs = queryString.parse(res.url.substring(res.url.indexOf('?')));
      
                if (qs.term == this.getState().value) {
                    return res.json();
                }
                
                return { aborted: true, valueReq: qs.term, valueState: this.getState().value }; 
            }).then(payload => {
                //Only dispatch response from the last request
                //TODO: When better browsersupport for fetch: abort request instead
                if (payload && payload.aborted) return;

                return this.dispatch({
                    key: query,
                    defaultValue: value,
                    hits: payload ? payload : [],
                    isVisible: payload !== null,
                    isLoading: false
                });
            }); 
        }, 300);
      }

      this.dispatch({
        value: value,
        hits: value && value.length > 1 ? this.state.hits : [],
        index: defaultIndex,
        isVisible: value && value.length > 1
      });
    }

    handleKeyDown(e) {
      //Handle keycodes
      this.handleKeyboard(e);
    }

    handleClickOutside(e) {
      if (this.refs.wrappedComponent && !this.refs.wrappedComponent.contains(e.target)) {
        this.dispatch({
          isVisible: false
        });
      }
    }

    handleOnSelect(item) {
      this.dispatch({
        isVisible:false,
        value: item.value
      });
    }

    handleFocus(e) {
      const value = e.target.value;
      if(value) {
        /*autoComplete(`${autoCompletePath}?q=${e.target.value}`)((action) => {
          this.dispatch({
            ...action,
            currentIndexObject: this.hasHits(action.payload) ? summaryHits(action.payload)[defaultIndex] : null,
          });
        }, this.getState.bind(this));*/

        this.clear(value);
      }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside.bind(this));
        document.addEventListener('focus', this.handleClickOutside.bind(this), true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside.bind(this));
        document.removeEventListener('focus', this.handleClickOutside.bind(this));
    }

    render() {
      return (
        <div ref="wrappedComponent">
          <WrappedComponent {...this.props}
            handleChange={this.handleChange.bind(this)}
            handleKeyDown={this.handleKeyDown.bind(this)}
            handleOnSelect={this.handleOnSelect.bind(this)}
            handleFocus={this.handleFocus.bind(this)}
            suggestions={this.state}  />
        </div>
      );
    }
  }
};
