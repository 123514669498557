import React from 'react';
import classNames from 'classnames';
import imageMissing from '../../images/image-missing.png';
import { getLargeImage } from '../../helpers/image';
import './search-story.scss';

const SearchStory = props => {

    const textElipsis = (heading = '', text) => {
        if (!props.ImageUrl) {
            if (text.length > 200) {
                return `${text.substring(0, 200)}...`;
            }
    
            return text;    
        }

        let maxTextLength = Math.round(150 - (heading.length * 1.8));

        if (maxTextLength < 25) return null;

        if (text.length > maxTextLength) { 

            return `${text.substring(0, maxTextLength)}...`;
        }

        return text;
    };

    const headerElipsis = (heading = '') => {
        if (heading.length > 80) {
            return `${heading.substring(0, 80)}...`;
        }

        return heading;
    };

    const shuffle = (array) => {
        return array.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    }

    return (
        <div className={classNames({
            'search-story': true,
            'search-story--no-image': !props.ImageUrl
        })}>
            {props.CompanyImageUrl &&
                <div className="news-list__item-logo">
                    <a href="/orkla-foods-sverige">
                        <img src={props.CompanyImageUrl} alt="Orkla Foods Sverige AB logo" />
                    </a>
                </div>
            }
            {props.ImageUrl &&
                <a href={props.Link} className="search-story__image" style={{
                    backgroundImage: `url(${getLargeImage(props.ImageUrl)})`
                }}></a>
            }
            <div className="search-story__content">
                <h1 className="search-story__heading"><a href={props.Link}>{headerElipsis(props.Header)}</a></h1>
                <div className="search-story__text">
                    {textElipsis(props.Header, props.Introduction) &&
                        <p><a href={props.Link}>{textElipsis(props.Header, props.Introduction)}</a></p>
                    }
                </div>
                <div className="search-story__products">
                    {shuffle(props.Articles).map((item, i) => {
                        if (i > 2) {
                            return null; 
                        }

                        const heading = item.ArtikelBenanmning && item.ArtikelBenanmning !== '' ?  item.ArtikelBenanmning : item.ArtikelKategori;
                        const subHeading = item.ArtikelBenanmning && item.ArtikelBenanmning !== '' ?  item.ArtikelKategori : '';

                        return (
                            <div key={`article${item.Arident}`} className="search-story__product">
                                <a href={`/productsheet/${item.GTIN}`} target="_blank">
                                    {subHeading &&
                                        <h3 className="story-push__product-subheading story-push__product-subheading--small">{subHeading}</h3>
                                    }
                                    <h2 className="search-story__product-heading">{heading}</h2>
                                    <h3 className="search-story__product-subheading">{item.Varumarke}</h3>
                                    <div className="search-story__product-image">
                                        <img src={item.ProduktbildThumb === '/files/images/image-missing.png' ? imageMissing : item.ProduktbildThumb } 
                                             alt={item.ArtikelKategori} />
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div> 
            </div>     
        </div>
    );
};

export default SearchStory;