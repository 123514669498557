import React from 'react';
import hallbarhetImg from '../../images/hallbarhet.png';
import kvalitetImg from '../../images/kvalitet.png';
import socialImg from '../../images/socialt-ansvar.png';
import './usp.scss';

const Usp = (newsroom) =>  {

    return (
        <section className="newsroom-usp">
            <div className="newsroom-usp__container">
                <div className="newsroom-usp__container-inner">
                    <div id="company-sustainablility">
                        {newsroom.SustainablilityDescription &&
                            <div className="newsroom-usp__item">
                                <div className="newsroom-usp__image">
                                    <img src={hallbarhetImg} alt="Hållbarhet" />
                                </div>
                                <div className="newsroom-usp__text">
                                    <h2>Hållbarhet</h2>
                                    <p>{newsroom.SustainablilityDescription} {newsroom.SustainablilityLink && <a href={newsroom.SustainablilityLink}>Visa mer</a>}</p>
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div id="company-quality">
                        {newsroom.QualityDescription &&
                            <div className="newsroom-usp__item">
                                <div className="newsroom-usp__image">
                                    <img src={kvalitetImg} alt="Kvalitet" />
                                </div>
                                <div className="newsroom-usp__text">
                                    <h2>Kvalitet</h2>
                                    <p>{newsroom.QualityDescription} {newsroom.QualityLink && <a href={newsroom.QualityLink}>Visa mer</a>}</p>
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div id="company-responsiblity">
                        {newsroom.SocialResponsiblityDescription &&
                            <div className="newsroom-usp__item">
                                <div className="newsroom-usp__image">
                                    <img src={socialImg} alt="Socialt ansvar" />
                                </div>
                                <div className="newsroom-usp__text">
                                    <h2>Socialt ansvar</h2>
                                    <p>
                                        {newsroom.SocialResponsiblityDescription} {newsroom.SocialResponsiblityLink && <a href={newsroom.SocialResponsiblityLink}>Visa mer</a>}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Usp;